import React from "react";
import styled from "styled-components";
import Avatar from "../styles/Avatar";
import { timeSince } from "../utils";
import { Grid, Row, Col } from 'react-flexbox-grid'

const Wrapper = styled.div`
  .thumb {
    width: 100%;
    height: 80px;
    object-fit: cover;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  .channel-avatar img {
    position: relative;
    top: 5px;
  }

  .video-info span {
    font-size: 0.9rem;
    padding-right: 0.1rem;
  }

  @media screen and (max-width: 992px) {
    .thumb {
        height: 80px;
        width: 30%;
      }
  }

  @media screen and (max-width: 860px) {
    .thumb {
        height: 80px;
        width: 30%;
      }
  }

  @media screen and (max-width: 600px) {
    .thumb {
      height: 80px;
      width: 30%;
    }
  }

  @media screen and (max-width: 420px) {
    .thumb {
      height: 80px;
      width: 30%;
    }
  }
`;

const BitcoinVideoCard = ({ nousername, hideavatar, video }) => {
  return (
    <Wrapper>
        <Grid>
                <Row top="xs">
                    <Col xs={12} md={2}>
                    <img className="thumb" src={video.thumbnail} alt="thumbnail" />
                    </Col>
                    <Col xs={12} md={2}>
                        <h4>
                        {video.title.length > 40
                        ? video.title.substring(0, 40) + "..."
                        : video.title}
                        </h4>
                    </Col>
                    <Col xs={12} md={1}>
                    <h4>{video.plan}</h4> ($0.20)
                    {/* <h4>{video.vbreed.id}</h4>  */}
                    </Col>
                    <Col xs={12} md={2}>
                    <span>{video.views || 0} views</span>
                    </Col>
                    <Col xs={12} md={2}>
                    <h4>{video.income}</h4>Earned: $ {0.20*(video.views || 0)}
                    </Col>
                    <Col xs={12} md={3}>
                    <span>Uploaded {timeSince(video.createdAt)} ago</span>
                    </Col>
                </Row>
        </Grid>
    </Wrapper>
    
  );
};

export default BitcoinVideoCard;
