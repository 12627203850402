import React from 'react'
import styled from 'styled-components'
// #E74C3C
const CustomH1 = styled.h3`
    text-transform: uppercase;
    color: #939494;
    position: relative;
    padding-bottom: 5px;
    padding-top: 0px;
`

const Subtitle = ({ children }) => {
    return (
        <CustomH1><h3>{children}</h3></CustomH1>
    )
}

export default Subtitle