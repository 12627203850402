import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Grid, Row, Col } from 'react-flexbox-grid'
import { BitcoinIcon, Bitcoin2Icon } from "../components/Icons";
import Auth from "../components/Auth";
import Title from "./Title";
import Subtitle from "./Subtitle";
import Description from "./Description";
import { TextLeft } from "../components/TextLeft";

const Wrapper = styled.div`
    margin-top: 140px;
    margin-bottom: 50px;
    padding: 16px 0;
    text-align: center;
    position: relative;
    overflow: hidden;
`

const IconPad = styled.div`
    margin-bottom: 30px;
`


function Hero() {
  return (
    <Wrapper >
        <Grid>
                <Row top="xs">
                    <Col xs={12} md={4} mdOffset={2}>
                        <TextLeft>
                            <Subtitle>Upload funny pet videos</Subtitle>
                            <Title>Earn real bitcoin</Title>
                            <Description>Maintain ownership of your premium content 
                                </Description>
                        </TextLeft>
                        <IconPad>
                            <Bitcoin2Icon fill1="#ef853b" fill2="#fff" fill3="#ef853b" height="50" />
                        </IconPad>
                    </Col>
                    <Col xs={12} md={4} mdOffset={1}>
                        <Auth />
                    </Col>
                </Row>
        </Grid>
    </Wrapper>
  );
}

export default Hero;