import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paws } from "../components/Icons";
import { TextLeft } from "../components/TextLeft";
import Title from "./Title";
import Subtitle from "./Subtitle";
import Description from "./Description";

const Wrapper = styled.div`
    padding: 8% 0;
    text-align: center;
    position: relative;
    overflow: hidden;
`
const ImgWrapper = styled.div`
  // height: 200px;
  // width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  position: relative;
  overflow: hidden;
`;

function Charity() {
  return (
    <Wrapper >
        <Grid>
                <Row>
                    <Col xs={12} md={4} mdOffset={2}>
                      <TextLeft>
                        <Subtitle>How it works</Subtitle>
                        <Title>Donation to charity</Title>
                        <Description>You can view images in your feed for free, and view videos literally for pennies.  For every video watched, we will donate bitcoin to the charity www.PawLivesMatter.org
                        </Description>
                      </TextLeft>
                    </Col>
                    <Col xs={12} md={4} mdOffset={1}>
                      <ImgWrapper><Paws height="150" /></ImgWrapper>
                    </Col>
                </Row>
        </Grid>
    </Wrapper>
  );
}

export default Charity;