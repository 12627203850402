import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Grid, Row, Col } from 'react-flexbox-grid'
import Title from "./TitleDark";
import Subtitle from "./SubtitleDark";
import Description from "./DescriptionDark";
import { TextLeft } from "../components/TextLeft";
import { Nodes } from "../components/Icons";
import img from '../assets/metanet.png';

const Wrapper = styled.div`
    padding: 5% 0;
    text-align: center;
    background: ${(props) => props.theme.primaryColor};
    position: relative;
    overflow: hidden;
`
const ImgWrapper = styled.div`
  height: 200px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
`;

const CustomH4 = styled.h4`
    color: ${(props) => props.theme.darkGrey};
    position: relative;
    padding-bottom: 5px;
    padding-top: 5px;

`

function Metanet() {
  return (
    <Wrapper >
        <Grid>
                <Row>
                    <Col xs={12} md={3} mdOffset={2}>
                        <ImgWrapper><Nodes height="200" fill1="#ef853b" fill2="#4b4c4d" /></ImgWrapper>
                    </Col>
                    <Col xs={12} md={5} mdOffset={1}>
                        <TextLeft>
                        <Subtitle>Bitcoin & The Metanet</Subtitle>
                        <Title>You're in control</Title>
                        <Description>The Metanet is the first working technical solution that enables us to adopt micropayments on the Internet.  It also provides truly private data storage for digital ownership, allowing users to create a digital identity and be able to own and control their own data. This results in much more clarity and empowerment for the user when compared to the current set up wherein the data you produce is in the hands of large commercial companies.</Description>
                        </TextLeft>
                    </Col>
                </Row>
        </Grid>
    </Wrapper>
  );
}

export default Metanet;