import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Grid, Row, Col } from 'react-flexbox-grid'
import { TwitterIcon, FacebookIcon, Icon } from "../components/Icons";
import ReactTooltip from 'react-tooltip';

const FooterWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 16px 0;
    text-align: center;
    background: ${(props) => props.theme.grey};
    p {
        color: ${(props) => props.theme.lightGrey};
        font-size: 0.9em;
        margin-bottom: 20px;
    }
    span {
        color: ${(props) => props.theme.lightGrey};
        font-size: 0.9em;
    }
    position: relative;
    overflow: hidden;
    .totop {
        position: fixed;
        bottom: 56px;
        right: 16px;
        z-index: 99999;
        span {
            color: #fff;
        }
    }
`

function Footer() {
  return (
    <FooterWrapper>
        <Grid>
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                <p>
                    <a data-tip data-for='terms'>terms 
                    </a>
                    <ReactTooltip id='terms' type='error' type='info'>
                    <h3>This app is currently in private BETA.
                    </h3>
                    <h3>The bitcoin earned amounts listed are for testing purposes only. 
                    </h3>
                    <h3>If you happen to stumble upon this website, please be aware that you will 
                    </h3>
                    <h3>NOT earn real bitcoin until we officially release the LIVE version of this app.  
                    </h3>
                    <h3>Thanks for stopping by and enjoy testing it with us.</h3>
                    </ReactTooltip>
                    | privacy policy |
                    <a data-tip data-for='contact'> contact us </a>
                    <ReactTooltip id='contact' type='error' type='info'>
                    <h3>email: brad [at] funniestpets [dot] com</h3>
                    </ReactTooltip>
                 </p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                <Icon fill1="#ef853b" height="32" /><br />
                <Link to="#"><TwitterIcon /></Link><Link to="#"><FacebookIcon /></Link>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                        <span>&#169; 2021 funniest pets. all rights reserved.</span>
                </Col>
            </Row>
        </Grid>
        </FooterWrapper>
  );
}

export default Footer;