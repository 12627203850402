import React, { Component } from 'react'
import styled from 'styled-components'
// #E74C3C
const CustomH2 = styled.h3`
    color: ${(props) => props.theme.darkGrey};
    position: relative;
    padding-bottom: 5px;
    padding-top: 5px;

`

const Description = ({ children }) => {
    return (
        <CustomH2><h3>{children}</h3></CustomH2>
    )
}

export default Description