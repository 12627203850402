import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import BitcoinVideoCard from "./BitcoinVideoCard";
import { Grid, Row, Col } from 'react-flexbox-grid'

const Wrapper = styled.div`
  .videos {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }

  @media screen and (max-width: 830px) {
    .videos {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 540px) {
    .videos {
      grid-template-columns: 1fr;
    }
  }
`;

const BitcoinVideo = () => {
  const { videos } = useSelector((state) => state.profile.data);

  if (!videos?.length) {
    return <div><h3>Total Earned: $0.00 USD</h3><br /><p>Upload some funny pet videos now to start earning real bitcoin!</p></div>;
  }

  return (
    <Wrapper>
      <div className="videos">
        <div><h3>Total Earned: $124.45 USD (not real, for testing)</h3></div>
      
        {videos?.map((video) => (
          <Link to={`/watch/${video.id}`} key={video.id}>
            <BitcoinVideoCard nousername={true} hideavatar={true} video={video} />
          </Link>
        ))}
      </div>
    </Wrapper>
  );
};

export default BitcoinVideo;
