export const darkTheme = {
  bg: "#413D45",
  // bg: "#181818",
  primaryColor: "#FFF",
  textOnWhite: "rgb(75, 76, 77)",
  secondaryColor: "#AAAAAA",
  grey: "rgba(44,41,47,0.8)",
  // grey: "#202020",
  darkGrey: "#383838",
  black: "#121212",
  red: "#CC0000",
  blue: "#3EA6FF",
  white: "#FFF",
  pink: "#F86782",
  purple: "#282A36",
  font: "Fira Sans",
  darkblue: "#3170f1",
  darkblue_hover: "#2053f6",
  orange: "#ef853b",
  // lightGrey: "#bfbec0",
  // headspace lightGrey: "#f4f2ee"
  lightGrey: "#cecece",
};
export const blueTheme = {
  bg: "#3984c5",
  primaryColor: "#FFF",
  secondaryColor: "#AAAAAA",
  grey: "#202020",
  darkGrey: "#383838",
  black: "#121212",
  red: "#CC0000",
  blue: "#3EA6FF",
  white: "#FFF",
  pink: "#F86782",
  purple: "#282A36",
  font: "Fira Sans",
};
