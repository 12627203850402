import React from 'react';
import styled from "styled-components";
import { Grid, Row, Col } from 'react-flexbox-grid'
import { TextLeft } from "../components/TextLeft";
import Title from "./TitleCenter";
import Subtitle from "./Subtitle";
import Description from "./Description";
import { Pill } from "../components/Icons";
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
    padding: 10% 0;
    text-align: center;
    position: relative;
    overflow: hidden;
`

function Split() {
  return (
    <Wrapper >
        <Grid>
                <Row center="xs">
                    <Col xs={12} md={6} >
                        <Subtitle>The Internet's been split in half</Subtitle>
                        <Title>Which paw path will you choose?</Title>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} mdOffset={2}>
                        <a data-tip data-for='blue'> <Pill height="20" fill1="white" fill2="#4192ed" /> </a>
                        <ReactTooltip id='blue' type='error' type='info' effect='solid'>
                        <h3>that's a blue pill</h3>
                        </ReactTooltip>
                        
                        <TextLeft>
                        <Description>I am complacent being censured by Big Tech.  I am ok with google making money off of my content. 
                        I'm ok with them owning & monetizing my data.  Demonetization & deplatforming is their right as a private company.<br /></Description>
                        </TextLeft>
                    </Col>
                    <Col xs={12} md={4} mdOffset={1}>
                        <a data-tip data-for='red'> <Pill height="20" fill1="white" fill2="#e33122" /> </a>
                        <ReactTooltip id='red' type='error' type='info' effect='solid'>
                        <h3>that's a red pill</h3>
                        </ReactTooltip>
                        <TextLeft>
                        <Description>I cherish free speech & privacy, expect to have complete control of my content, am concerned about the deplatorming & demonetization of users, and want to earn real bitcoin from my funny pet videos.</Description>
                        </TextLeft>
                    </Col>
                </Row>
                {/* <Row center="xs">
                    <Col xs={12} >
                        <Icon fill1="#ef853b" height="50" />
                    </Col>
                </Row> */}
        </Grid>
    </Wrapper>
  );
}

export default Split;