import React from 'react'
import styled from 'styled-components'

const CustomH1 = styled.h1`
    color: ${(props) => props.theme.textOnWhite};
    position: relative;
    padding-bottom: 5px;
    padding-top: 5px;
    h1 {
        
        font-size: 4.25rem;
        line-height: 4.875rem;
    }
    span {
        content: '';
        display: block;
        width: 50px;
        border-bottom: 4px solid ${(props) => props.theme.orange};
        margin-top: 30px;
        margin-bottom: 30px;
    }
`

const Title = ({ children }) => {
    return (
        <CustomH1>
            <h1>{children}</h1>
            <span />
        </CustomH1>
    )
}

export default Title