import React from "react";
import Auth from "./components/Auth";
import {
  BrowserRouter as Router
} from "react-router-dom";
import styled from "styled-components";

// components
import ScrollToTop from "./components/ScrollToTop";
import Header from "./homepage/Header";
import Hero from "./homepage/Hero";
import NoAds from "./homepage/NoAds";
import Charity from "./homepage/Charity";
import Metanet from "./homepage/Metanet";
import Split from "./homepage/Split";
import Footer from "./homepage/Footer";

const Homepage = () => (
  <Router>
    <ScrollToTop />
    <Header />
    {/* <Container>
      <Auth />
    </Container> */}
    <Hero />
    <NoAds />
    <Charity />
    <Metanet />
    <Split />
    <Footer />
  </Router>
);

export default Homepage;
