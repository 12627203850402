import React from "react";

export const Logo = (props) => (
  <svg
    {...props}
    viewBox="0 0 287.3 70.8"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="30"
    // width="22"
    fill="#ffffff"
  >
    <g>
    <path d="M16,8.8c0.2-1,0.4-1.7,0.4-2.2c0-0.2,0-0.5-0.1-0.8c-0.1-0.4-0.3-0.6-0.6-0.8c-0.3-0.1-0.7-0.2-1.1-0.2 c-0.8,0-1.3,0.5-1.6,1.5c-0.4,1.2-0.6,3.4-0.6,6.3v4.1c1.5,0,2.6,0.1,3.3,0.3c0.7,0.2,1.1,0.7,1.1,1.3c0,0.7-0.4,1.2-1.2,1.4
	c-0.8,0.2-1.9,0.3-3.4,0.3C12,27.7,12,33.6,12,37.9c0,2.4,0,4.3,0.1,5.7v3.3c0,2.4-1.4,3.6-4.2,3.6c-0.8,0-1.6-0.3-2.4-1
	c-0.8-0.7-1.3-1.9-1.3-3.7l0.1-6.4c0.1-5.5,0.1-11.9,0.1-19.2c-1.7,0-2.9-0.1-3.5-0.3C0.3,19.7,0,19.3,0,18.6c0-0.9,0.3-1.5,1-1.7
	c0.6-0.2,1.8-0.3,3.4-0.3c0-1.6,0-3.3,0.1-5c0.1-1.8,0.4-3.6,0.9-5.3C6.6,2.1,9.1,0,12.8,0c1.1,0,2.2,0.1,3.3,0.4
	c1.2,0.3,2.1,1,2.9,2.2s1.2,3.3,1.2,6.2c0,0.9-0.6,1.4-1.9,1.4c-0.5,0-1-0.1-1.5-0.3c-0.5-0.2-0.8-0.5-0.8-1V8.8z M40.9,19.4
	c-0.6-0.5-1.3-0.9-2.2-1.2c-0.8-0.3-1.5-0.4-2.1-0.4c-1.3,0-2.1,0.6-2.3,1.7L34.2,22c-0.1,3.4-0.4,6.1-0.7,8.1
	c-1.6,9.5-3.3,14.2-5.1,14.2c-0.5,0-1.1-0.4-1.7-1.1c-1.3-1.7-2-3.5-2.1-5.3c-0.1-1.8-0.1-3-0.1-3.7c0-2.4,0.2-5.1,0.6-8.2l0.2-1.4
	c0.2-1.4,0.4-2.7,0.4-4c0-1.8-0.6-2.7-1.8-2.8C21.6,18,20,19.6,19,22.6c-1,3-1.5,6.4-1.5,10.2c0,7.6,1.7,12.8,5,15.6
	c1.6,1.3,3,2,4.2,2c0.8,0,1.6-0.2,2.4-0.6c2-1.1,3.6-4.3,4.8-9.6l-0.1,1.4l-0.1,2.7c0,0.9,0,1.7,0.1,2.5c0.2,2.4,1.2,3.7,3,3.7
	c1.1,0,2.6-0.5,4.4-1.4c0.1-1.9,0.1-5.7,0.1-11.3c0.1-9.6,0.3-15.2,0.4-16.6l0.1-0.4C41.8,20.4,41.5,19.9,40.9,19.4z M71.5,21.7
	c-1-0.5-2-0.8-2.9-0.8c-0.6,0-1.3,0.1-2,0.3c-1.8,0.4-3.6,1.8-5.3,4.1C60,27,58.9,28.9,57.9,31c-0.8,1.6-2,3.7-3.6,6.5L54.2,31
	c0-3.7,0.1-6.5,0.2-8.1c0-1-0.4-2.1-1.1-3.2c-0.7-1.1-1.4-1.9-2-2.3c-0.6-0.4-1.1-0.6-1.5-0.6c-1.6,0-2.4,2-2.4,6
	c0,4-0.1,7.9-0.3,11.7c-0.1,2.7-0.2,5.2-0.2,7.4c0,2.9,0.1,5.3,0.4,7.3c0.6,0.4,1.8,0.7,3.5,1h0.3c1.4,0,2.5-0.7,3.1-2
	c1.8-4.1,3.5-7.5,5-10.2c1.6-2.6,3-4.9,4.4-6.8c1.3-1.9,2.5-3.9,3.6-5.8c-0.1,5.5-0.4,10.2-0.9,14.1c-0.1,1.8-0.2,3.2-0.2,4.2
	c0,2.7,0.3,4.5,0.8,5.3c0.5,0.9,1.2,1.3,2.1,1.3h0.4c1.2,0,2.2-0.5,2.8-1.6c0.6-1.1,0.9-2.8,0.9-5.3v-1.2c-0.1-2.4-0.1-8.6-0.1-18.7
	C72.9,22.9,72.4,22.2,71.5,21.7z M102.7,21.7c-1-0.5-2-0.8-2.9-0.8c-0.6,0-1.3,0.1-2,0.3c-1.8,0.4-3.6,1.8-5.3,4.1
	c-1.2,1.7-2.3,3.6-3.3,5.8c-0.8,1.6-2,3.7-3.6,6.5L85.5,31c0-3.7,0.1-6.5,0.2-8.1c0-1-0.4-2.1-1.1-3.2c-0.7-1.1-1.4-1.9-2-2.3
	c-0.6-0.4-1.1-0.6-1.5-0.6c-1.6,0-2.4,2-2.4,6c0,4-0.1,7.9-0.3,11.7c-0.1,2.7-0.2,5.2-0.2,7.4c0,2.9,0.1,5.3,0.4,7.3
	c0.6,0.4,1.8,0.7,3.5,1h0.3c1.4,0,2.5-0.7,3.1-2c1.8-4.1,3.5-7.5,5-10.2c1.6-2.6,3-4.9,4.4-6.8c1.3-1.9,2.5-3.9,3.6-5.8
	c-0.1,5.5-0.4,10.2-0.9,14.1c-0.1,1.8-0.2,3.2-0.2,4.2c0,2.7,0.3,4.5,0.8,5.3c0.5,0.9,1.2,1.3,2.1,1.3h0.4c1.2,0,2.2-0.5,2.8-1.6
	c0.6-1.1,0.9-2.8,0.9-5.3v-1.2c-0.1-2.4-0.1-8.6-0.1-18.7C104.2,22.9,103.7,22.2,102.7,21.7z M114.5,16.1c-0.6-0.1-1.1-0.2-1.5-0.2
	c-0.6,0-1.2,0.1-1.7,0.3c-0.9,0.3-1.4,0.9-1.6,1.8c-0.1,5.2-0.1,14.5-0.1,27.9c1.1,2.9,2,4.3,3,4.3c0.1,0,0.3,0,0.4-0.1
	c1-0.4,1.6-1.8,1.9-4.1c0.6-6.7,0.9-12,1.1-16.1c0.2-4,0.3-8.1,0.3-12.3C115.7,16.8,115.2,16.3,114.5,16.1z M115.2,11.1
	c-0.6-0.4-1.3-0.6-2.2-0.6c-0.9,0-1.6,0.2-2.2,0.6c-0.6,0.4-0.9,0.9-0.9,1.5c0,0.6,0.3,1.1,0.9,1.5c0.6,0.4,1.3,0.6,2.2,0.6
	c0.9,0,1.6-0.2,2.2-0.6c0.6-0.4,0.9-0.9,0.9-1.5C116.1,12,115.8,11.5,115.2,11.1z M141.3,39c0,1.2-0.3,2.7-1,4.5s-1.8,3.3-3.5,4.7
	c-1.7,1.4-3.8,2.1-6.3,2.1c-3.7,0-6.4-1.3-7.9-4c-1.5-2.6-2.3-5.9-2.3-9.6c0-3.2,0.4-6.3,1.2-9.3c0.8-3,1.8-5,2.9-6.2
	c0.5-0.7,1-1.2,1.7-1.7c1.9-1.4,3.8-2.1,5.5-2.1c0.8,0,1.9,0.3,3.5,0.9c1.6,0.6,2.8,2.2,3.6,4.9c0.2,0.8,0.4,1.6,0.4,2.4
	c0,2-0.7,4.1-2.1,6.3c-1.2,1.6-2.7,2.8-4.5,3.7c-1.8,0.9-3.8,1.7-5.9,2.2c0,2.3,0.3,4.2,1,5.7c0.6,1.5,1.6,2.2,2.9,2.2h0.4
	c3.7-0.6,6-2.4,6.9-5.4c0.3-1.2,0.6-2.3,0.6-3.3c0.1-1,0.3-1.5,0.5-1.5C140.5,35.4,141.3,36.6,141.3,39z M126.6,36.4
	c1.2-0.6,2.4-1.7,3.6-3.2c1.2-1.5,2.3-3.1,3.1-4.7c0.8-1.6,1.3-3,1.3-4.1v-0.1c-0.2-1.7-0.7-2.6-1.5-2.6c-1.4,0-3,1.8-4.8,5.3
	C127.3,29.4,126.7,32.6,126.6,36.4z M163.5,18.9c-1.3-1.6-2.7-2.5-4.2-2.7c-1.5-0.2-2.6-0.3-3.2-0.3c-1.9,0-3.8,0.5-5.5,1.4
	c-2.3,1.2-3.8,3.3-4.5,6.3c-0.1,0.8-0.1,1.6-0.1,2.3c0,1.8,0.3,3.2,0.9,4.2c0.8,1.4,2.2,2.9,4.2,4.4c4.8,3.6,7.2,6.8,7.2,9.5v0.4
	c-0.2,2.2-1.6,3.2-4.1,3.2c-2.1-0.1-3.4-3.1-3.7-9.1c-0.1-0.7-0.5-1-1.1-1.1c-0.6-0.1-1-0.1-1.2-0.1c-0.8,0-1.6,0.2-2.4,0.6
	c-1,0.5-1.5,1.3-1.6,2.4c0.2,4,1.5,6.9,3.7,8.5c2.2,1.6,4.5,2.4,7,2.4l1-0.1c2.8-0.2,4.9-1.1,6.2-2.7c1.3-1.6,1.9-3.1,1.9-4.7
	c0-1.2-0.3-2.6-0.8-4.1c-1.4-2.9-3.6-5.5-6.3-7.7l-2.6-2.5c-1.1-1.2-1.6-2.3-1.6-3.5c0-0.4,0.1-0.9,0.2-1.3c0.5-1.6,1.4-2.8,2.6-3.6
	c1.2-0.8,2.2-1.2,3.1-1.2c0.5,0,1,0.1,1.4,0.3c1.3,0.5,2.1,2.2,2.3,5c0,0.3,0.1,0.8,0.3,1.5c0.2,0.7,0.7,1.1,1.5,1.1
	c1.2,0,1.8-0.4,1.8-1.1c0.1-0.7,0.1-1.2,0.1-1.5c0-0.9-0.2-1.8-0.6-2.9C164.9,20.7,164.3,19.6,163.5,18.9z M188.7,6.7
	c-0.9-0.7-4.3-1.1-10.2-1.2l0.1-1c0-2.7-1.3-4.2-4-4.2l-1.3-0.1c-1,0-1.7,0.2-2.2,0.6c-0.8,0.5-1.3,2.1-1.5,4.6
	c-0.5,0-1.1,0.1-1.7,0.1l-2.2-0.1l-2.8-0.1c-2.2,0-3.5,0.2-4.1,0.7c-0.6,0.5-0.8,1-0.8,1.5c0,0.4,0.1,0.9,0.4,1.5
	c1.2,0.3,2.7,0.5,4.3,0.5l3.3-0.1l3.5,0.1v1.8c0.3,11,0.4,19.9,0.4,26.9l-0.1,12c1.1,0.1,1.9,0.1,2.7,0.1c0.5,0,1,0,1.4-0.1
	c0.8-0.1,1.4-0.6,1.7-1.4c0.9-7.1,1.3-13.9,1.4-20.5c0-6.6,0.5-12.8,1.3-18.6c3.7,0.1,6.8,0.2,9.1,0.3c1.1-0.9,1.6-1.5,1.7-1.9
	c0.1-0.4,0.1-0.6,0.1-0.8C189.1,7.1,189,6.8,188.7,6.7z M224.3,22.2c1.6,2.6,2.4,5.5,2.4,8.5c0,5.4-0.7,9.5-2.1,12.4
	c-2.4,4.9-5.8,7.3-10.2,7.3c-0.4,0-1.2-0.1-2.4-0.2s-2.6-0.7-4-1.7l0.1,8.7c0,2.4-0.1,4.9-0.2,7.3c-0.1,2.4-0.5,4-1.1,4.9
	c-0.6,0.9-1.5,1.3-2.8,1.3c-1.1,0-1.8-0.4-2.2-1.2c-0.4-0.8-0.6-2.4-0.6-4.8c0-3.2,0.2-9.6,0.6-19.3l0.1-2.7l-0.1-6.2
	c0-5,0.4-11.3,1.1-18.7l1.8-0.1c1.1,0,2,0.1,2.7,0.3c0.7,0.2,1.2,0.6,1.3,1.2c0.2,0.6,0.3,1.4,0.3,2.3v1.5c3.2-3.5,6-5.3,8.6-5.3
	c0.1,0,0.9,0.1,2.3,0.2C221.2,18.2,222.7,19.6,224.3,22.2z M220.8,26.4c0-1.4-0.5-2.8-1.4-4c-0.4-0.6-0.9-0.9-1.5-0.9
	c-0.7,0-1.4,0.4-2.3,1.1c-3,2.6-5.3,6.4-7.1,11.4c-0.1,1-0.1,1.9-0.1,2.8c0,2.4,0.4,4.3,1.3,5.8c0.8,1.5,1.9,2.3,3.2,2.3
	c0.4,0,1.1-0.2,2.3-0.6c1.1-0.4,2.5-2.6,4.1-6.8c1.1-3.1,1.6-6,1.6-8.7V26.4z M251.3,39c0,1.2-0.3,2.7-1,4.5
	c-0.6,1.8-1.8,3.3-3.5,4.7c-1.7,1.4-3.8,2.1-6.3,2.1c-3.7,0-6.4-1.3-7.9-4c-1.5-2.6-2.3-5.9-2.3-9.6c0-3.2,0.4-6.3,1.2-9.3
	c0.8-3,1.8-5,2.9-6.2c0.5-0.7,1-1.2,1.7-1.7c1.9-1.4,3.8-2.1,5.5-2.1c0.8,0,1.9,0.3,3.5,0.9c1.6,0.6,2.8,2.2,3.6,4.9
	c0.2,0.8,0.4,1.6,0.4,2.4c0,2-0.7,4.1-2.1,6.3c-1.2,1.6-2.7,2.8-4.5,3.7c-1.8,0.9-3.8,1.7-5.9,2.2c0,2.3,0.3,4.2,1,5.7
	c0.6,1.5,1.6,2.2,2.9,2.2h0.4c3.7-0.6,6-2.4,6.9-5.4c0.3-1.2,0.6-2.3,0.6-3.3c0.1-1,0.3-1.5,0.5-1.5C250.6,35.4,251.3,36.6,251.3,39
	z M236.7,36.4c1.2-0.6,2.4-1.7,3.6-3.2c1.2-1.5,2.3-3.1,3.1-4.7c0.8-1.6,1.3-3,1.3-4.1v-0.1c-0.2-1.7-0.7-2.6-1.5-2.6
	c-1.4,0-3,1.8-4.8,5.3C237.4,29.4,236.8,32.6,236.7,36.4z M274.2,8.2c0.1-0.4,0.1-0.6,0.1-0.8c0-0.3-0.1-0.6-0.4-0.7
	c-0.9-0.7-4.3-1.1-10.2-1.2l0.1-1c0-2.7-1.3-4.2-4-4.2l-1.3-0.1c-1,0-1.7,0.2-2.2,0.6c-0.8,0.5-1.3,2.1-1.5,4.6
	c-0.5,0-1.1,0.1-1.7,0.1l-2.2-0.1l-2.8-0.1c-2.2,0-3.5,0.2-4.1,0.7c-0.6,0.5-0.8,1-0.8,1.5c0,0.4,0.1,0.9,0.4,1.5
	c1.2,0.3,2.7,0.5,4.3,0.5l3.3-0.1l3.5,0.1v1.8c0.3,11,0.4,19.9,0.4,26.9l-0.1,12c1.1,0.1,1.9,0.1,2.7,0.1c0.5,0,1,0,1.4-0.1
	c0.8-0.1,1.4-0.6,1.7-1.4c0.9-7.1,1.3-13.9,1.4-20.5c0-6.6,0.5-12.8,1.3-18.6c3.7,0.1,6.8,0.2,9.1,0.3
	C273.6,9.2,274.1,8.5,274.2,8.2z M286.7,22.1c-0.5-1.4-1.2-2.5-1.9-3.2c-1.3-1.6-2.7-2.5-4.2-2.7c-1.5-0.2-2.6-0.3-3.2-0.3
	c-1.9,0-3.8,0.5-5.5,1.4c-2.3,1.2-3.8,3.3-4.5,6.3c-0.1,0.8-0.1,1.6-0.1,2.3c0,1.8,0.3,3.2,0.9,4.2c0.8,1.4,2.2,2.9,4.2,4.4
	c4.8,3.6,7.2,6.8,7.2,9.5v0.4c-0.2,2.2-1.6,3.2-4.1,3.2c-2.1-0.1-3.4-3.1-3.7-9.1c-0.1-0.7-0.5-1-1.1-1.1c-0.6-0.1-1-0.1-1.2-0.1
	c-0.8,0-1.6,0.2-2.4,0.6c-1,0.5-1.5,1.3-1.6,2.4c0.2,4,1.5,6.9,3.7,8.5c2.2,1.6,4.5,2.4,7,2.4l1-0.1c2.8-0.2,4.9-1.1,6.2-2.7
	c1.3-1.6,1.9-3.1,1.9-4.7c0-1.2-0.3-2.6-0.8-4.1c-1.4-2.9-3.6-5.5-6.3-7.7l-2.6-2.5c-1.1-1.2-1.6-2.3-1.6-3.5c0-0.4,0.1-0.9,0.2-1.3
	c0.5-1.6,1.4-2.8,2.6-3.6c1.2-0.8,2.2-1.2,3.1-1.2c0.5,0,1,0.1,1.4,0.3c1.3,0.5,2.1,2.2,2.3,5c0,0.3,0.1,0.8,0.3,1.5
	c0.2,0.7,0.7,1.1,1.5,1.1c1.2,0,1.8-0.4,1.8-1.1c0.1-0.7,0.1-1.2,0.1-1.5C287.3,24.1,287.1,23.2,286.7,22.1z"/>
    </g>
  </svg>
);

export const Icon = ({ fill1, height }) => (
  <svg
    viewBox="0 0 326.4 329.7" 
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height={height}
  >
    <g>
    <path class="st0" fill={fill1} d="M80.9,79.4c-2.3-8.9-3.6-18.4-3.6-27.9c0-11.1,1.6-19.7,3.6-26.2c3.9-12.5,9.7-17.1,9.7-17.1
	c4.2-6.7,24.2-14.2,36.7-0.8c12.5,13.3,31.7,42.5,30.8,69.1c-0.8,26.7-22.5,63.3-44.2,51.6C98.6,119.9,86.6,101.2,80.9,79.4z
	 M203.9,134.8c18.2,5.1,36-17.4,46.8-40.3c5-10.7,8.5-21.4,9.8-29.6c2.3-14.3-0.2-35.6-9.8-47.8c-7.8-9.9-20.2-13.8-38.5-3
	c0,0-30,19.2-34.2,48.3C173.9,91.5,177.3,127.3,203.9,134.8z M278.1,124.8c0,0-16.2,11.6-27.3,28.4c-5.2,7.9-9.4,17-10.2,26.6
	c-1.6,19,1.5,34,10.2,42.4c5,4.9,11.8,7.5,20.7,7.5c24.2,0,55-35,55-67.5C326.4,129.8,308.9,104.9,278.1,124.8z M80.9,192.8
	c3.9-7.6,4.6-18.6,2.2-34.6c-0.5-3.4-1.3-6.6-2.2-9.8C72.5,120.7,49,103.2,49,103.2C0.7,81.5-6,139,4,164c10,25,29.2,47.5,52.5,43.3
	C68.6,205.2,76.6,201,80.9,192.8z M250.7,255.9c-16.3-33.9-44.3-65.8-56.8-76.9c-15-13.3-41.7-18.3-54.1-3.3
	c0,0-33.3,24.5-58.9,55.8c-9.7,11.9-18.3,24.7-23.6,37.5c-16.4,40,2.6,59.1,23.6,60.7c3.5,0.3,7.1,0,10.5-0.7
	c24.2-5,61.6-29.2,101.6-13.3c22.8,9,45,8.3,57.7-1.6c9.5-7.4,13.5-20,8.2-37.6C256.8,269.6,254,262.7,250.7,255.9z"/>
    </g>
  </svg>
);

export const HomeIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#AAAAAA"
  >
    <g>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8"></path>
    </g>
  </svg>
);

export const TrendingIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#AAAAAA"
  >
    <g>
      <path d="M17.53 11.2c-.23-.3-.5-.56-.76-.82-.65-.6-1.4-1.03-2.03-1.66-1.46-1.46-1.78-3.87-.85-5.72-.9.23-1.75.75-2.45 1.32C8.9 6.4 7.9 10.07 9.1 13.22c.04.1.08.2.08.33 0 .22-.15.42-.35.5-.22.1-.46.04-.64-.12-.06-.05-.1-.1-.15-.17-1.1-1.43-1.28-3.48-.53-5.12C5.87 10 5 12.3 5.12 14.47c.04.5.1 1 .27 1.5.14.6.4 1.2.72 1.73 1.04 1.73 2.87 2.97 4.84 3.22 2.1.27 4.35-.12 5.96-1.6 1.8-1.66 2.45-4.3 1.5-6.6l-.13-.26c-.2-.45-.47-.87-.78-1.25zm-3.1 6.3c-.28.24-.73.5-1.08.6-1.1.38-2.2-.16-2.88-.82 1.2-.28 1.9-1.16 2.1-2.05.17-.8-.14-1.46-.27-2.23-.12-.74-.1-1.37.2-2.06.15.38.35.76.58 1.06.76 1 1.95 1.44 2.2 2.8.04.14.06.28.06.43.03.82-.32 1.72-.92 2.26z"></path>
    </g>
  </svg>
);

export const SubIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#AAAAAA"
  >
    <g>
      <path d="M18.7 8.7H5.3V7h13.4v1.7zm-1.7-5H7v1.6h10V3.7zm3.3 8.3v6.7c0 1-.7 1.6-1.6 1.6H5.3c-1 0-1.6-.7-1.6-1.6V12c0-1 .7-1.7 1.6-1.7h13.4c1 0 1.6.8 1.6 1.7zm-5 3.3l-5-2.7V18l5-2.7z"></path>
    </g>
  </svg>
);

export const LibIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#AAAAAA"
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z"></path>
    </g>
  </svg>
);

export const HistoryIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#AAAAAA"
  >
    <g>
      <path d="M11.9 3.75c-4.55 0-8.23 3.7-8.23 8.25H.92l3.57 3.57.04.13 3.7-3.7H5.5c0-3.54 2.87-6.42 6.42-6.42 3.54 0 6.4 2.88 6.4 6.42s-2.86 6.42-6.4 6.42c-1.78 0-3.38-.73-4.54-1.9l-1.3 1.3c1.5 1.5 3.55 2.43 5.83 2.43 4.58 0 8.28-3.7 8.28-8.25 0-4.56-3.7-8.25-8.26-8.25zM11 8.33v4.6l3.92 2.3.66-1.1-3.2-1.9v-3.9H11z"></path>
    </g>
  </svg>
);

export const VidIcon = (props) => (
  <svg
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#AAAAAA"
  >
    <g>
      <path d="M18.4 5.6v12.8H5.6V5.6h12.8zm0-1.8H5.6a1.8 1.8 0 0 0-1.8 1.8v12.8a1.8 1.8 0 0 0 1.8 1.9h12.8a1.8 1.8 0 0 0 1.9-1.9V5.6a1.8 1.8 0 0 0-1.9-1.8z"></path>
      <path d="M10.2 9v6.5l5-3.2-5-3.2z"></path>
    </g>
  </svg>
);

export const WatchIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#AAAAAA"
  >
    <g>
      <path d="M12 3.67c-4.58 0-8.33 3.75-8.33 8.33s3.75 8.33 8.33 8.33 8.33-3.75 8.33-8.33S16.58 3.67 12 3.67zm3.5 11.83l-4.33-2.67v-5h1.25v4.34l3.75 2.25-.67 1.08z"></path>
    </g>
  </svg>
);

export const BitcoinDollar = (props) => (
  <svg
    {...props}
    viewBox="0 0 1107.1 1532.9"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="22"
    height="22"
    fill="#AAAAAA"
  >
    <g>
    <path d="M902.3,709.4c82.1-41.6,133.4-115.1,121.4-237.6l0,0c-16.1-167.4-160.6-223.5-343.2-239.5V0H539.2v226.1
	c-37.1,0-75.1,0.7-112.8,1.5V0H285.1L285,232.1c-30.6,0.6-60.7,1.2-90,1.2v-0.7L0.1,232.5v151c0,0,104.4-2,102.6-0.1
	c57.3,0,75.9,33.2,81.3,61.9v264.6c4,0,9.1,0.2,14.9,1H184l-0.1,370.7c-2.5,18-13.1,46.7-53.1,46.8c1.8,1.6-102.7,0-102.7,0
	L0,1297.2h184c34.2,0,67.9,0.6,100.9,0.8l0.1,234.9h141.2v-232.4c38.7,0.8,76.2,1.1,112.9,1.1l-0.1,231.3h141.3v-234.4
	c237.6-13.6,404.1-73.5,424.7-296.7C1121.7,822.1,1037.2,741.8,902.3,709.4z M429.5,393.8c1.1,0,2.3,0,3.5,0c0.1,0,0.2,0,0.2,0
	c1.1,0,2.3,0,3.5,0c0.2,0,0.3,0,0.5,0c1.2,0,2.3,0,3.5-0.1c0.2,0,0.4,0,0.6,0c1.2,0,2.4,0,3.6-0.1c0.3,0,0.6,0,0.9,0
	c1.2,0,2.3-0.1,3.5-0.1c0.4,0,0.8,0,1.3,0c1,0,2.1-0.1,3.1-0.1c12.8-0.3,27.6-0.8,43.6-0.9c0,0,0,0,0.1,0c1.9,0,3.9,0,5.9,0
	c0,0,0.1,0,0.1,0c2,0,4,0,6,0c0,0,0,0,0,0c104.8-0.1,253,14.5,252.9,142.5c0,130.1-166.6,141.8-271.4,141.7c0,0,0,0,0,0
	c-16.3,0-31.2-0.3-43.9-0.5c-0.2,0-0.4,0-0.6,0c-0.5,0-1,0-1.5,0c-0.7,0-1.4,0-2.1,0c-0.3,0-0.5,0-0.8,0c-0.9,0-1.9,0-2.8,0
	c-0.3,0-0.5,0-0.8,0c-0.9,0-1.7,0-2.6,0c-0.1,0-0.2,0-0.2,0c-0.9,0-1.8,0-2.7,0c-0.2,0-0.4,0-0.6,0c-0.9,0-1.8,0-2.6,0V393.8z
	 M429.5,1129.6V818.3c95.9,0.2,396.4-27.3,396.4,155.7C825.9,1149.5,525.4,1129.6,429.5,1129.6z"/>
    </g>
  </svg>
);

export const MoreIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#AAAAAA"
  >
    <g>
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
    </g>
  </svg>
);

export const HamburgerIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="22"
    width="22"
    fill="#FFF"
  >
    <g>
      <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
    </g>
  </svg>
);

export const UploadIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    height="27"
    width="27"
    fill="#FFF"
    focusable="false"
  >
    <g>
      <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4zM14 13h-3v3H9v-3H6v-2h3V8h2v3h3v2z"></path>
    </g>
  </svg>
);

export const NotificationIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="27"
    fill="#FFF"
    width="27"
  >
    <g>
      <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"></path>
    </g>
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
  >
    <g>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </g>
  </svg>
);

export const SignoutIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    fill=""
  >
    <path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5a2 2 0 00-2 2v4h2V5h14v14H5v-4H3v4a2 2 0 002 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
  </svg>
);

export const LikeIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    fill="#AAAAAA"
    width="22"
    height="22"
  >
    <g>
      <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 10z"></path>
    </g>
  </svg>
);

export const DislikeIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="22"
    height="22"
  >
    <g>
      <path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01L1 14c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z"></path>
    </g>
  </svg>
);

export const TwitterIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 32 32"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="64"
    height="64"
    fill="#ffffff"
  >
    <g>
    <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" ></path>
    </g>
  </svg>
);

export const FacebookIcon = (props) => (
  <svg
    {...props}
    viewBox="0 0 32 32"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    width="64"
    height="64"
    fill="#ffffff"
  >
    <g>
    <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" ></path>
    </g>
  </svg>
);

export const LogoVertical = (props) => (
  <svg
    {...props}
    viewBox="0 0 535.9 587.5"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="120"
    // width="22"
    fill="#ffffff"
  >
    <g>
    <path d="M22.2,143.2c-2.2,0-4.5-0.9-6.8-2.9c-2.4-1.9-3.6-5.4-3.6-10.6l0.2-18.2c0.3-15.6,0.4-33.8,0.4-54.5c-4.9,0-8.2-0.2-9.9-0.7
	C0.8,55.9,0,54.7,0,52.8c0-2.6,0.9-4.1,2.8-4.7c1.8-0.5,5.1-0.8,9.7-0.8c0-4.5,0.1-9.2,0.4-14.3C13.1,28,14,23,15.5,18
	C18.8,6,25.7,0,36.3,0c3,0,6.1,0.4,9.4,1.1C49,1.9,51.7,4,54,7.4c2.2,3.5,3.4,9.3,3.4,17.4c0,2.6-1.8,3.9-5.5,3.9
	c-1.4,0-2.8-0.2-4.3-0.7c-1.5-0.5-2.2-1.4-2.2-2.8v-0.4c0.7-2.7,1-4.8,1-6.3c0-0.5-0.1-1.3-0.4-2.3c-0.3-1-0.9-1.7-1.8-2.1
	c-1-0.4-2-0.6-3.1-0.6c-2.2,0-3.7,1.4-4.5,4.3c-1.1,3.5-1.6,9.5-1.6,18v11.6c4.2,0,7.4,0.3,9.5,0.9c2.1,0.6,3.2,1.9,3.2,3.8
	c0,2-1.1,3.4-3.3,4c-2.2,0.6-5.4,0.9-9.6,0.9c-0.5,21.5-0.8,38.4-0.8,50.6c0,6.9,0.1,12.3,0.2,16.1v9.4
	C34.1,139.8,30.1,143.2,22.2,143.2z M104.4,143.2c-5.2,0-8-3.5-8.6-10.4c-0.1-2.3-0.2-4.7-0.2-7.1l0.2-7.5l0.2-4.1
	c-3.3,15-7.8,24-13.5,27.1c-2.2,1.2-4.4,1.8-6.7,1.8c-3.4,0-7.3-1.9-11.8-5.7c-9.5-8-14.3-22.8-14.3-44.3c0-10.7,1.4-20.4,4.2-29
	c2.8-8.6,7.2-13.1,13.4-13.5c3.4,0.3,5.1,2.9,5.1,8c0,3.5-0.3,7.3-1,11.2l-0.6,3.9c-1.1,8.7-1.6,16.5-1.6,23.3c0,2,0.1,5.6,0.4,10.6
	c0.3,5,2.2,10,5.9,14.9c1.8,2,3.4,3.1,4.9,3.1c5.2,0,10-13.4,14.5-40.2c1-5.6,1.6-13.2,2-22.8l0.4-7.3c0.5-3.1,2.7-4.7,6.5-4.7
	c1.6,0,3.6,0.4,5.9,1.1c2.3,0.7,4.4,1.9,6.1,3.4c1.8,1.5,2.7,2.9,2.7,4.3l-0.2,1c-0.3,3.9-0.6,19.6-1,46.9c0,15.9-0.1,26.6-0.4,32
	C111.7,141.9,107.6,143.2,104.4,143.2z M196.7,143h-1.2c-2.4,0-4.4-1.2-5.8-3.7c-1.4-2.4-2.1-7.5-2.1-15.1c0-2.9,0.2-6.8,0.6-11.8
	c1.2-11,2-24.3,2.4-40c-3,5.6-6.4,11.1-10.2,16.5c-3.8,5.4-8,11.9-12.4,19.4c-4.5,7.5-9.2,17.1-14.3,28.8c-1.8,3.8-4.7,5.7-8.8,5.7
	H144c-4.9-0.8-8.2-1.8-10-2.9c-0.7-5.7-1-12.6-1-20.8c0-6.3,0.2-13.3,0.6-21c0.5-10.7,0.8-21.8,0.8-33.3s2.2-17.1,6.7-17.1
	c1,0,2.3,0.5,4.2,1.6c1.8,1.1,3.7,3.2,5.7,6.4c2,3.2,3,6.2,3.2,9.1c-0.4,4.8-0.6,12.4-0.6,23.1l0.2,18.4c4.5-7.8,7.9-13.9,10.2-18.4
	c3-6,6.1-11.4,9.4-16.3c4.9-6.5,9.9-10.4,15.1-11.6c2-0.5,3.9-0.8,5.7-0.8c2.7,0,5.5,0.7,8.3,2.2c2.8,1.5,4.2,3.4,4.2,5.7
	c0,28.7,0.1,46.4,0.4,53v3.3c0,7.1-0.9,12.1-2.6,15.1C202.8,141.5,200.2,143,196.7,143z M285.2,143H284c-2.4,0-4.4-1.2-5.8-3.7
	c-1.4-2.4-2.1-7.5-2.1-15.1c0-2.9,0.2-6.8,0.6-11.8c1.2-11,2-24.3,2.4-40c-3,5.6-6.4,11.1-10.2,16.5c-3.8,5.4-8,11.9-12.4,19.4
	c-4.5,7.5-9.2,17.1-14.3,28.8c-1.8,3.8-4.7,5.7-8.8,5.7h-0.8c-4.9-0.8-8.2-1.8-10-2.9c-0.7-5.7-1-12.6-1-20.8c0-6.3,0.2-13.3,0.6-21
	c0.5-10.7,0.8-21.8,0.8-33.3s2.2-17.1,6.7-17.1c1,0,2.3,0.5,4.2,1.6c1.8,1.1,3.7,3.2,5.7,6.4c2,3.2,3,6.2,3.2,9.1
	c-0.4,4.8-0.6,12.4-0.6,23.1l0.2,18.4c4.5-7.8,7.9-13.9,10.2-18.4c3-6,6.1-11.4,9.4-16.3c4.9-6.5,9.9-10.4,15.1-11.6
	c2-0.5,3.9-0.8,5.7-0.8c2.7,0,5.5,0.7,8.3,2.2c2.8,1.5,4.2,3.4,4.2,5.7c0,28.7,0.1,46.4,0.4,53v3.3c0,7.1-0.9,12.1-2.6,15.1
	C291.3,141.5,288.7,143,285.2,143z M318.8,142.4c-2.6,0-5.4-4.1-8.4-12.2c0-37.9,0.1-64.3,0.4-79.2c0.4-2.4,1.9-4.1,4.5-5.1
	c1.5-0.5,3.1-0.8,4.9-0.8c1,0,2.3,0.2,4.2,0.6c1.8,0.4,3.4,1.9,4.6,4.5c0,11.8-0.2,23.5-0.7,34.9c-0.5,11.4-1.5,26.6-3.2,45.5
	c-0.7,6.7-2.4,10.5-5.3,11.6C319.6,142.3,319.3,142.4,318.8,142.4z M320.3,41.6c-2.4,0-4.5-0.6-6.2-1.7c-1.7-1.2-2.5-2.5-2.5-4.2
	c0-1.8,0.8-3.2,2.5-4.4c1.7-1.2,3.8-3.7,6.2-3.7s4.5,2.6,6.2,3.7c1.7,1.2,2.6,2.6,2.6,4.4c0,1.6-0.9,3-2.6,4.2
	C324.8,41,322.7,41.6,320.3,41.6z M393.5,100.2c-0.7,0-1.2,1.5-1.4,4.4c-0.3,2.9-0.9,6.1-1.8,9.5c-2.6,8.6-9.1,13.7-19.6,15.3h-1
	c-3.7,0-6.4-2.1-8.3-6.3c-1.8-4.2-2.8-9.6-2.8-16.1c5.8-1.6,11.4-3.7,16.6-6.3c5.2-2.6,9.5-6.1,12.8-10.6c3.9-6.3,5.9-12.2,5.9-17.7
	c0-2.3-0.3-4.6-1-6.7c-2.3-7.5-5.7-12.1-10.2-13.8c-4.5-1.7-7.8-2.5-10-2.5c-5,0-10.3,2-15.7,5.9c-1.8,1.4-3.3,3-4.7,4.9
	c-3.1,3.3-5.8,9.1-8.2,17.5c-2.3,8.4-3.5,17.2-3.5,26.3c0,10.7,2.2,19.9,6.5,27.3c4.4,7.5,11.8,11.2,22.4,11.2c7.1,0,13-2,17.8-5.9
	c4.8-3.9,8.2-8.4,10-13.4c1.8-5,2.8-9.2,2.8-12.8C400.2,103.6,398,100.2,393.5,100.2z M363.5,76.5c4.9-10.1,9.4-15.1,13.5-15.1
	c2.3,0,3.7,2.4,4.3,7.3v0.4c0,3.1-1.2,7-3.6,11.5c-2.4,4.6-5.3,9-8.9,13.3c-3.5,4.3-6.9,7.3-10.2,9.1
	C359,92.3,360.7,83.4,363.5,76.5z M438.6,144.8c-6.9,0-13.5-2.3-19.8-6.9c-6.3-4.6-9.7-12.6-10.4-24.1c0.1-3.3,1.6-5.6,4.5-6.9
	c2.3-1.1,4.6-1.6,6.9-1.6c0.5,0,1.6,0.1,3.3,0.3c1.6,0.2,2.7,1.3,3.1,3.2c1.1,17,4.6,25.6,10.6,25.9c7.1,0,10.9-3.1,11.6-9.2v-1.2
	c0-7.8-6.8-16.7-20.4-26.9c-5.7-4.4-9.7-8.5-12-12.4c-1.8-2.7-2.7-6.7-2.7-11.8c0-2,0.1-4.2,0.4-6.5c2-8.4,6.3-14.3,12.9-17.7
	c5-2.6,10.3-3.9,15.7-3.9c1.6,0,4.6,0.3,9,0.9c4.4,0.6,8.4,3.2,12,7.7c2.2,2.2,4,5.2,5.5,9.2c1.1,3,1.6,5.7,1.6,8.2
	c0,0.8-0.1,2.2-0.3,4.2c-0.2,2-1.9,3-5.2,3.2c-2.3,0-3.8-1-4.4-3.1c-0.6-2-0.9-3.5-0.9-4.3c-0.5-7.9-2.7-12.6-6.5-14.1
	c-1.4-0.5-2.7-0.8-4.1-0.8c-2.6,0-5.5,1.1-8.9,3.3c-3.3,2.2-5.7,5.6-7.2,10.2c-0.4,1.2-0.6,2.4-0.6,3.7c0,3.4,1.5,6.7,4.5,10
	l7.3,7.1c7.9,6.3,13.9,13.5,18,21.8c1.5,4.2,2.2,8.1,2.2,11.6c0,4.5-1.8,8.9-5.4,13.4c-3.6,4.4-9.4,7-17.4,7.7L438.6,144.8z
	 M488.8,142.8c-2,0-4.6-0.1-7.5-0.4l0.2-34.1c0-19.6-0.4-44.9-1.2-76.1v-5.1l-10-0.2l-9.4,0.2c-4.6,0-8.7-0.5-12.2-1.4
	c-0.7-1.6-1-3.1-1-4.3c0-1.6,0.8-3.1,2.3-4.4c1.6-1.3,5.4-1.9,11.5-1.9l8,0.2l6.1,0.2c1.8,0,3.4-0.1,4.9-0.2
	c0.5-7.2,2-11.6,4.3-13.1c1.5-1.1,3.6-1.6,6.3-1.6l3.7,0.2c7.5,0.3,11.2,4.3,11.2,12l-0.2,2.9c16.7,0.3,26.4,1.4,29,3.3
	c0.8,0.4,1.2,1.1,1.2,2c0,0.4-0.1,1.1-0.4,2.1c-0.3,1-1.9,2.8-4.9,5.4c-6.5-0.1-15.1-0.4-25.7-0.8c-2.3,16.5-3.5,34-3.7,52.6
	c-0.1,18.6-1.4,38-3.9,58.1c-0.8,2.3-2.4,3.7-4.7,4.1C491.6,142.7,490.3,142.8,488.8,142.8z M149.7,297
	c-9.6-15.6-18.6-23.8-27.1-24.5c-8.5-0.7-13.1-1.1-14-1.1c-15.2,0-32.2,10.5-51.2,31.4v-9c0-5.7-0.5-10.4-1.5-14
	c-1-3.6-3.7-5.9-8-6.9c-4.3-1-9.7-1.5-16.3-1.5l-10.8,0.4c-4.3,44.4-6.4,81.7-6.4,111.8l0.9,37l-0.4,15.9
	c-2.6,57.6-3.9,96-3.9,115.2c0,14.6,1.3,24.2,3.9,28.8c2.6,4.6,7,6.9,13.3,6.9c7.7,0,13.4-2.7,17-8c3.6-5.3,5.8-15.1,6.7-29.5
	c0.9-14.3,1.3-28.8,1.3-43.4l-0.9-52c8.6,5.7,16.6,9,23.9,9.9c7.3,0.9,12.1,1.3,14.4,1.3c26.7,0,47-14.6,61.1-43.9
	c8.3-17.2,12.5-41.9,12.5-74C164.1,329.6,159.3,312.6,149.7,297z M128.9,335.9c0,16.1-3.2,33.4-9.5,52c-9.7,24.7-18,38.1-24.7,40.4
	c-6.7,2.3-11.3,3.4-13.5,3.4c-7.7,0-14.1-4.5-19.1-13.5c-5-9-7.5-20.6-7.5-34.6c0-5.2,0.3-10.8,0.9-16.8
	c10.3-29.8,24.4-52.5,42.1-67.9c5.2-4.3,9.7-6.5,13.8-6.5c3.4,0,6.4,1.7,9,5.2c5.7,7.5,8.6,15.5,8.6,24.1V335.9z M297,375.5
	c-1.4,0-2.4,3.1-3,9.2c-0.6,6.2-1.9,12.8-3.9,20c-5.4,18.1-19.2,28.8-41.3,32.2h-2.1c-7.7,0-13.5-4.4-17.4-13.3
	c-3.9-8.9-5.8-20.2-5.8-34c12.3-3.4,24-7.9,35-13.3c11-5.4,20-12.9,26.9-22.4c8.3-13.2,12.5-25.7,12.5-37.4c0-4.9-0.7-9.6-2.2-14.2
	c-4.9-15.8-12-25.4-21.5-29c-9.5-3.6-16.5-5.4-21.1-5.4c-10.6,0-21.6,4.2-33.1,12.5c-3.7,2.9-7,6.3-9.9,10.3
	c-6.6,6.9-12.3,19.2-17.2,37c-4.9,17.8-7.3,36.3-7.3,55.5c0,22.6,4.6,41.9,13.8,57.6c9.2,15.8,24.9,23.7,47.3,23.7
	c14.9,0,27.4-4.2,37.6-12.5c10.2-8.3,17.2-17.7,21.1-28.2c3.9-10.5,5.8-19.4,5.8-26.9C311.2,382.6,306.4,375.5,297,375.5z
	 M233.8,325.6c10.3-21.2,19.8-31.8,28.4-31.8c4.9,0,7.9,5.2,9,15.5v0.9c0,6.6-2.5,14.7-7.5,24.3c-5,9.6-11.3,18.9-18.7,27.9
	c-7.5,9-14.6,15.4-21.5,19.1C224.3,358.9,227.8,340.2,233.8,325.6z M349.4,465.3c-4.3,0-9.6-0.3-15.9-0.9l0.4-71.8
	c0-41.3-0.9-94.7-2.6-160.4v-10.8l-21.1-0.4l-19.8,0.4c-9.7,0-18.3-1-25.8-3c-1.4-3.4-2.2-6.4-2.2-9c0-3.4,1.7-6.5,4.9-9.2
	c3.3-2.7,11.4-4.1,24.3-4.1l16.8,0.4l12.9,0.4c3.7,0,7.2-0.1,10.3-0.4c1.1-15.2,4.2-24.4,9-27.5c3.2-2.3,7.6-3.4,13.3-3.4l7.7,0.4
	c15.8,0.6,23.7,9,23.7,25.4l-0.4,6c35.3,0.6,55.6,2.9,61.1,6.9c1.7,0.9,2.6,2.3,2.6,4.3c0,0.9-0.3,2.4-0.9,4.5
	c-0.6,2.2-4,6-10.3,11.4c-13.8-0.3-31.8-0.9-54.2-1.7c-4.9,34.7-7.5,71.7-7.7,110.9c-0.3,39.3-3,80.1-8.2,122.5
	c-1.7,4.9-5,7.7-9.9,8.6C355.3,465.2,352.6,465.3,349.4,465.3z M458.7,469.6c-14.6,0-28.5-4.9-41.7-14.6
	c-13.2-9.7-20.5-26.7-21.9-50.7c0.3-6.9,3.4-11.8,9.5-14.6c4.9-2.3,9.7-3.4,14.6-3.4c1.1,0,3.4,0.2,6.9,0.6c3.4,0.4,5.6,2.7,6.5,6.7
	c2.3,35.8,9.7,54,22.4,54.6c14.9,0,23.1-6.5,24.5-19.4v-2.6c0-16.3-14.3-35.3-43-56.8c-12-9.2-20.5-17.9-25.4-26.2
	c-3.7-5.7-5.6-14-5.6-24.9c0-4.3,0.3-8.9,0.9-13.8c4.3-17.8,13.3-30.2,27.1-37.4c10.6-5.4,21.6-8.2,33.1-8.2c3.4,0,9.7,0.6,18.9,1.9
	c9.2,1.3,17.6,6.7,25.4,16.1c4.6,4.6,8.5,11,11.6,19.4c2.3,6.3,3.4,12,3.4,17.2c0,1.7-0.2,4.7-0.6,8.8c-0.4,4.2-4.1,6.4-11,6.7
	c-4.9,0-8-2.2-9.2-6.5s-1.9-7.3-1.9-9c-1.1-16.6-5.7-26.5-13.8-29.7c-2.9-1.1-5.7-1.7-8.6-1.7c-5.4,0-11.7,2.3-18.7,6.9
	c-7,4.6-12.1,11.8-15.3,21.5c-0.9,2.6-1.3,5.2-1.3,7.7c0,7.2,3.2,14.2,9.5,21.1l15.5,15c16.6,13.2,29.2,28.5,37.8,46
	c3.2,8.9,4.7,17.1,4.7,24.5c0,9.5-3.8,18.9-11.4,28.2c-7.6,9.3-19.9,14.7-36.8,16.1L458.7,469.6z"/>
    </g>
  </svg>
);

export const LogoNew = ({ fill1 }) => (
  <svg
    viewBox="0 0 325.1 95.8"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height="48"
    // width="22"
    fill="#ffffff"
  >
    <g>
    <path class="st0" fill={fill1} d="M126.7,18.3c0,0.4-0.2,0.8-0.7,1.1c-0.4,0.3-0.9,0.5-1.5,0.6c-0.5,0.1-1.1,0.2-1.6,0.3c-0.5,0.1-0.8,0.1-1,0.1
	c-1.3,0-2.7-0.4-4-1.2c-1.3-0.8-2.5-2-3.6-3.6c-0.1-0.2-0.3-0.4-0.6-0.8c-0.3-0.3-0.4-0.5-0.4-0.7c0-0.3,0.2-0.6,0.7-0.9
	c0.5-0.3,1-0.5,1.6-0.8c0.6-0.3,1.2-0.4,1.7-0.6c0.5-0.1,0.9-0.2,1.1-0.2c1.3,0,2.5,0.3,3.8,1s2.3,1.8,3,3.6c0.1,0.2,0.4,0.5,0.7,1
	C126.5,17.8,126.7,18.1,126.7,18.3z M182.5,54.8c0,1.5-0.5,2.7-1.5,3.6c-1,0.8-2.2,1.4-3.5,1.8c-1.3,0.4-2.6,0.6-3.9,0.7
	c-1.3,0.1-2.3,0.1-2.8,0.1c-0.9,0-2-0.1-3.3-0.3c-1.3-0.2-2.6-0.5-3.8-0.9c-1.2-0.4-2.3-1-3.2-1.8c-0.9-0.8-1.3-1.7-1.3-2.9
	c0-0.7,0.3-1.2,0.8-1.5c0.5-0.3,1.1-0.4,1.6-0.4c0.7,0,1.4,0.3,2.1,0.8c0.7,0.5,1.4,1.1,2.1,1.6c0.7,0.6,1.6,1.1,2.4,1.6
	c0.9,0.5,1.9,0.8,3.1,0.8c0.5,0,1.2-0.2,2.1-0.5c0.9-0.4,1.3-1,1.3-1.9c0-0.9-0.4-1.8-1.1-2.6c-0.7-0.8-1.7-1.7-2.8-2.6
	c-1.1-0.9-2.3-1.8-3.6-2.8c-1.3-1-2.5-2-3.6-3.1c-1.1-1.1-2-2.4-2.8-3.7c-0.7-1.3-1.1-2.8-1.1-4.4c0-2.2,0.7-4.1,2.2-5.7
	s3.1-2.8,5.1-3.6c2-0.9,4-1.5,6-1.9c2-0.4,3.6-0.6,4.8-0.6c1.1,0,2.2,0.2,3.3,0.7c1.1,0.4,1.6,1.3,1.6,2.6c0,0.4-0.1,0.9-0.2,1.7
	c-0.2,0.7-0.4,1.5-0.8,2.2c-0.4,0.7-0.8,1.3-1.4,1.9c-0.6,0.5-1.3,0.8-2.2,0.8c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.2-0.5-0.5-0.9-0.8
	c-0.3-0.3-0.7-0.5-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-2.2,0-3.8,0.7-4.7,2c-0.9,1.3-1.3,2.7-1.3,4.2c0,1.3,0.8,2.6,2.3,4
	c1.5,1.4,3.2,2.8,5,4.2c1.8,1.4,3.5,2.8,5,4.2C181.7,52.2,182.5,53.5,182.5,54.8z M184.6,41.4c0.1-3.3,0.2-6.6,0.3-10
	c0.1-3.4,0.3-6.5,0.4-9.4c0-0.5-0.5-0.9-1.5-1.1c-1-0.2-2.2-0.4-3.5-0.6c-1.3-0.2-2.4-0.5-3.5-0.9c-1-0.4-1.5-1.1-1.5-2.1
	c0-0.8,0.8-1.3,2.5-1.4c1.7-0.1,4.1-0.2,7.4-0.2c0.3,0,0.4-0.4,0.5-1.2c0.1-0.8,0.2-1.7,0.2-2.9c0-1.2,0.1-2.4,0.1-3.8
	c0-1.3,0.1-2.6,0.1-3.8c0.1-1.2,0.2-2.2,0.3-2.9c0.2-0.8,0.4-1.2,0.6-1.2c0.3,0,0.9,0.1,1.7,0.2c0.8,0.1,1.7,0.3,2.6,0.5
	c0.9,0.2,1.7,0.5,2.4,0.8c0.7,0.3,1,0.7,1,1.1l-1.1,12.5c0,0.4,0.3,0.7,1,0.8c0.7,0.2,1.5,0.3,2.6,0.3c1.1,0,2.2,0,3.4,0
	c1.2,0,2.3,0.1,3.4,0.3c1.1,0.2,1.9,0.5,2.6,0.9c0.7,0.4,1,1,1,1.9c0,0.1-0.1,0.3-0.2,0.6c-0.1,0.3-0.3,0.4-0.5,0.4
	c-0.1,0-0.3-0.1-0.5-0.3c-0.2-0.2-0.8-0.3-1.8-0.3c-0.3,0-1,0-2.1,0.1c-1.2,0.1-2.4,0.2-3.6,0.3c-1.3,0.1-2.4,0.3-3.5,0.4
	c-1.1,0.2-1.6,0.4-1.8,0.6c-0.1,0.1-0.1,0.9-0.2,2.3c-0.1,1.4-0.2,3.2-0.3,5.4c-0.1,2.2-0.2,4.8-0.2,7.7c-0.1,2.9-0.1,6-0.1,9.2
	c0,1.2,0.1,2.4,0.2,3.6c0.1,1.2,0.3,2.2,0.6,3.1c0.3,0.9,0.6,1.7,1,2.3c0.4,0.6,0.9,0.9,1.5,0.9c0.8,0,1.6-0.4,2.3-1.1
	c0.7-0.7,1.3-1.7,1.9-2.9c0.6-1.2,1.1-2.6,1.5-4.1c0.4-1.5,0.8-3.1,1.2-4.7c0.2-1,0.4-1.6,0.7-2.1c0.3-0.4,0.6-0.6,1.2-0.6
	c0.4,0,0.8,0.1,1.2,0.4c0.4,0.3,0.5,0.9,0.5,2c0,0.6,0,1.1-0.1,1.2c-0.2,1.2-0.6,2.7-1.1,4.8c-0.5,2-1.3,4-2.2,6
	c-0.9,2-2.1,3.7-3.4,5.1c-1.3,1.5-2.9,2.2-4.7,2.2c-1.7,0-3.2-0.5-4.5-1.5c-1.3-1-2.4-2.4-3.2-4.1c-0.9-1.8-1.5-3.8-2-6.2
	c-0.4-2.4-0.7-5-0.7-7.7V41.4z M160.5,42.7c-0.4,0-0.8,0.2-1,0.5c-0.2,0.3-0.4,0.9-0.6,1.8c-0.3,1.3-0.6,2.7-1,4.2
	c-0.4,1.4-1,2.7-1.8,3.9c-0.8,1.2-1.7,2.1-2.9,2.8c-1.2,0.7-2.7,1.1-4.6,1.1c-1.3,0-2.5-0.3-3.4-0.9c-0.9-0.6-1.7-1.3-2.3-2.1
	c-0.6-0.8-1-1.6-1.3-2.4c-0.3-0.8-0.4-1.5-0.4-1.9v-2.8c0.4,0.1,0.9,0.2,1.4,0.3c0.5,0.1,0.9,0.1,1.2,0.1c1.8,0,3.4-0.5,4.8-1.6
	c1.4-1.1,2.6-2.4,3.6-3.9c1-1.6,1.7-3.3,2.2-5.1c0.5-1.8,0.8-3.5,0.8-5l-0.1-1.3c-0.1-1.1-0.3-2.1-0.8-3.2c-0.4-1-1.1-1.9-1.8-2.7
	c-0.8-0.8-1.7-1.4-2.7-1.9c-1-0.5-2.1-0.7-3.3-0.7c-2.4,0-4.4,0.8-6,2.4c-1.7,1.6-3,3.5-4.1,5.9c-1.1,2.3-1.9,4.8-2.4,7.5
	c-0.5,2.7-0.8,5.2-0.8,7.4c0,0.4,0,0.8,0,1.3c-0.3,1.4-0.6,2.8-1,4.2c-0.4,1.4-0.9,2.6-1.6,3.6c-0.4,0.5-0.9,1.1-1.6,1.8
	c-0.7,0.7-1.7,1.1-2.9,1.1c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.9-1.2-1.1-2.1c-0.2-0.9-0.4-1.8-0.4-2.9c-0.1-1.1-0.1-2.1-0.1-3.1v-5.8
	c0.1-1.2,0.1-2.1,0.2-2.8c0.1-0.7,0.1-1.4,0.2-1.9c0.1-0.5,0.1-1,0.2-1.4c0.1-0.4,0.1-0.9,0.2-1.4c0.3-1.3,0.5-2.2,0.8-2.7
	c0.3-0.5,0.4-0.9,0.3-1.1c0-0.8-0.3-1.4-0.9-1.8c-0.6-0.4-1.3-0.7-2.1-0.9c-0.8-0.2-1.5-0.3-2.2-0.3c-0.7,0-1.2,0-1.3,0
	c-0.7,0-1.2,0-1.6,0.1c-0.4,0.1-0.7,0.2-1.1,0.3c-0.3,0.2-0.5,0.4-0.5,0.6c0,0.1,0.1,0.4,0.2,0.7c0.2,0.3,0.3,0.6,0.3,0.9
	c0.1,0.5,0.2,1.1,0.2,1.6c0,0.6,0,1.1,0,1.6v10.6c0,0,0,0.1,0,0.1c-0.6,0.2-1,0.8-1,1.8c-0.1,1-0.3,2-0.6,3.1
	c-0.3,1.1-0.7,2.1-1.2,3.1s-1,1.8-1.6,2.4c-0.6,0.6-1.2,1-1.9,1c-1,0-1.6-0.7-1.9-2c-0.3-1.3-0.5-2.8-0.5-4.5c0-2.9,0.1-5.2,0.2-6.6
	c0.1-1.5,0.2-2.5,0.2-3.2c0-0.9,0-1.8-0.1-2.8c-0.1-1-0.3-1.9-0.6-2.7c-0.3-0.8-0.8-1.5-1.4-2.1c-0.6-0.5-1.5-0.8-2.6-0.8
	c-0.9,0-1.8,0.5-2.6,1.6c-0.9,1.1-1.7,2.4-2.4,3.9s-1.5,3.2-2.1,4.8c-0.6,1.7-1.2,3.1-1.6,4.3c-0.1,0.4-0.2,0.7-0.3,0.8V26.9
	c0-0.4-0.2-0.7-0.7-1c-0.4-0.3-1-0.5-1.6-0.7c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1-0.1-1.3-0.1c-0.9,0-1.3,0.4-1.3,1.2l-0.8,18.8
	c-0.3,0.3-0.5,0.8-0.5,1.5c-0.1,1-0.3,2-0.6,3.1c-0.3,1.1-0.7,2.1-1.2,3.1s-1,1.8-1.6,2.4c-0.6,0.6-1.2,1-1.9,1c-1,0-1.6-0.7-1.9-2
	c-0.3-1.3-0.5-2.8-0.5-4.5c0-2.9,0.1-5.2,0.2-6.6c0.1-1.5,0.2-2.5,0.2-3.2c0-0.9,0-1.8-0.1-2.8c-0.1-1-0.3-1.9-0.6-2.7
	c-0.3-0.8-0.8-1.5-1.4-2.1c-0.6-0.5-1.5-0.8-2.6-0.8c-0.9,0-1.8,0.5-2.6,1.6c-0.9,1.1-1.7,2.4-2.4,3.9s-1.5,3.2-2.1,4.8
	c-0.6,1.7-1.2,3.1-1.6,4.3c-0.1,0.4-0.2,0.7-0.3,0.8V26.9c0-0.4-0.2-0.7-0.7-1c-0.4-0.3-1-0.5-1.6-0.7c-0.6-0.2-1.2-0.3-1.8-0.4
	c-0.6-0.1-1-0.1-1.3-0.1c-0.9,0-1.3,0.4-1.3,1.2l-1.1,24.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.4-0.6,0.9-1,1.4c-0.3,0.5-0.7,1-1.1,1.4
	c-0.4,0.4-0.9,0.6-1.6,0.6c-0.8,0-1.4-0.4-1.9-1.3c-0.5-0.9-0.9-2-1.2-3.3c-0.3-1.3-0.5-2.8-0.7-4.4c-0.1-1.6-0.2-3.1-0.2-4.6
	c0-1.2,0-2,0-2.6c0-0.6,0.1-1.2,0.1-1.7c0-0.5,0.1-1.2,0.2-1.8c0.1-0.7,0.2-1.6,0.3-2.7c0-1.9-0.4-3.1-1.3-3.5
	c-0.9-0.4-2.3-0.6-4.2-0.6c-0.8,0-1.6,0.1-2.5,0.2c-0.9,0.1-1.5,0.4-1.6,1c0,0.7,0.1,1.3,0.4,1.8c0.3,0.5,0.4,1,0.4,1.5
	c-0.1,8.1-0.7,14-1.7,17.7c-1,3.7-2.5,5.6-4.3,5.6c-0.7,0-1.2-0.4-1.6-1.2c-0.4-0.8-0.7-1.8-0.8-3c-0.2-1.2-0.3-2.5-0.3-3.9
	c0-1.4,0-2.7,0-3.8V26c0-0.5-0.3-1-1-1.3s-1.4-0.7-2.3-0.9c-0.8-0.2-1.6-0.4-2.4-0.5C24.8,23,24.2,23,23.9,23
	c-0.5,0-0.8,0.1-0.8,0.3c0,0.2-0.1,0.5-0.2,1V38c0,0.1-0.1,0.1-0.1,0.2c-0.8,3-1.7,5.5-2.7,7.5c-1,2-2,3.7-3,5.1c-1,1.4-2,2.5-3,3.4
	c-1,0.9-1.9,1.6-2.8,2.3c-1.2-1.1-2.5-1.8-3.9-2c0.5-4.7,1.2-9.5,2-14.5c0.8-5,1.7-9.8,2.8-14.5c1-4.7,2.2-9.1,3.6-13.2
	c1.3-4.1,2.8-7.6,4.4-10.5c0.1-0.3,0-0.4-0.3-0.5c-0.3-0.1-0.7-0.2-1.3-0.2s-1.2,0-2,0.1c-0.7,0.1-1.4,0.2-2.1,0.3
	c-0.7,0.1-1.3,0.3-1.8,0.4c-0.5,0.2-0.9,0.3-1,0.4C11,3,10.3,4.3,9.6,6.4c-0.7,2.1-1.5,4.7-2.2,7.9C6.6,17.4,5.8,21,5.1,25
	c-0.7,4-1.4,8.2-2,12.6C2.5,42,1.9,46.5,1.5,51C1,55.6,0.6,60,0.3,64.2C0.1,68.4,0,72.3,0,76c0,3.7,0.2,6.8,0.6,9.5
	C0.7,86.3,0.9,87,1,87.8c0.1,0.8,0.3,1.4,0.5,2C2.2,92,3,93.5,3.9,94.4c0.9,0.9,2,1.4,3.2,1.4c0.7,0,1.4-0.5,2.3-1.5
	c0.9-1,1.7-2.4,2.5-4.1c0.8-1.7,1.5-3.8,2.1-6.1c0.6-2.4,1.1-4.8,1.4-7.5c0.3-2.6,0.3-5.3,0.1-8.2c-0.2-2.8-0.8-5.6-1.6-8.3
	c1-0.8,2.1-1.8,3.2-3.1c1.2-1.2,2.3-2.7,3.5-4.5c0.8-1.2,1.6-2.5,2.3-4c0.3,4,1.2,7.1,2.6,9.2c1.5,2.4,4.4,3.6,8.4,3.6
	c1.4,0,2.6-0.6,3.5-1.7c0.9-1.1,1.7-2.4,2.3-3.7c0.6-1.4,1-2.7,1.3-4c0.3-1.3,0.5-2.1,0.7-2.5c0.8,2.6,1.9,4.6,3.2,5.9l0.9,0.8
	c0.8,0.5,1.5,0.9,2.3,1.2c0.8,0.3,1.7,0.4,2.8,0.4c0.6,0,1.3-0.1,2.2-0.3c0.9-0.2,1.8-0.6,2.6-1.1c0.2-0.1,0.3-0.2,0.5-0.3
	c0.1,1,0.2,1.8,0.3,2.5c0.2,1,0.5,1.7,0.9,2.2c0.4,0.5,0.9,0.8,1.7,0.9c0.7,0.1,1.7,0.2,2.8,0.2c0.4,0,1-0.1,1.9-0.2
	c0.9-0.1,1.4-0.4,1.5-0.8c0.3-1.6,0.6-3.3,0.9-5c0.3-1.8,0.7-3.5,1.1-5.3c0.4-1.8,1-3.5,1.6-5.1c0.6-1.6,1.4-3.1,2.4-4.5l-0.2,7
	c0,4.1,0.4,7.2,1.2,9.3c0.8,2.1,2.6,3.1,5.5,3.1c1.9,0,3.4-0.5,4.7-1.4c1.2-0.9,2.2-2,3-3.3c0.1,1.2,0.2,2.1,0.4,2.9
	c0.2,1,0.5,1.7,0.9,2.2c0.4,0.5,0.9,0.8,1.7,0.9c0.7,0.1,1.7,0.2,2.8,0.2c0.4,0,1-0.1,1.9-0.2c0.9-0.1,1.4-0.4,1.5-0.8
	c0.3-1.6,0.6-3.3,0.9-5c0.3-1.8,0.7-3.5,1.1-5.3c0.4-1.8,1-3.5,1.6-5.1c0.6-1.6,1.4-3.1,2.4-4.5l-0.2,7c0,4.1,0.4,7.2,1.2,9.3
	c0.8,2.1,2.6,3.1,5.5,3.1c1.9,0,3.4-0.5,4.7-1.4c1.3-1,2.3-2.1,3.1-3.6c0.3-0.6,0.6-1.1,0.8-1.7c0.1,0.7,0.3,1.4,0.6,2.1
	c0.6,1.9,1.7,3.4,3.4,4.5c1.7,1.1,3.3,1.6,4.9,1.6c2,0,3.8-0.5,5.2-1.4c1.4-0.9,2.6-2.1,3.5-3.6c0.6-0.9,1.1-1.9,1.5-2.8
	c0.4,1,0.9,2,1.6,2.9c1.1,1.5,2.5,2.8,4.3,3.6c1.7,0.9,3.8,1.3,6.3,1.3c2.6,0,4.9-0.6,6.8-1.7c1.9-1.2,3.4-2.6,4.7-4.3
	c1.2-1.7,2.1-3.5,2.7-5.5c0.6-2,0.9-3.8,0.9-5.4v-0.7C161.9,43.2,161.4,42.7,160.5,42.7z M141.9,40.9c0-0.4,0.1-1.4,0.4-2.8
	c0.3-1.4,0.7-2.9,1.2-4.5c0.5-1.6,1.2-3,2.1-4.2c0.8-1.2,1.8-1.8,3-1.8c0.8,0,1.3,0.3,1.6,0.9s0.4,1.1,0.4,1.6v0.6
	c0,0.9-0.1,2-0.4,3.5c-0.3,1.4-0.7,2.8-1.2,4.1c-0.5,1.3-1.3,2.5-2.2,3.5c-0.9,1-2,1.4-3.3,1.4h-0.5c-0.6,0-0.9-0.2-1-0.5
	c-0.1-0.4-0.1-0.8-0.1-1.4V40.9z M12,73.8c-0.1,1.4-0.2,2.9-0.4,4.4c-0.2,1.6-0.5,3.1-0.9,4.6c-0.4,1.5-0.9,2.9-1.5,4.3
	c-0.6,1.3-1.3,2.4-2.1,3.4c-0.3,0.4-0.6,0.3-0.8-0.3c-0.2-0.6-0.4-1.4-0.5-2.3c-0.1-0.7-0.2-1.5-0.2-2.2c-0.1-0.8-0.1-1.5-0.1-2.3
	c-0.1-2,0-4.9,0.2-8.5c0.2-3.7,0.5-7.8,0.9-12.5c1,0.4,2.2,0.4,3.3,0.1c0.2,0,0.5-0.1,0.9-0.3c0.2,0.6,0.4,1.4,0.6,2.2
	c0.2,0.9,0.3,1.6,0.4,2.2c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0.1,0.5,0.1,1.3,0.2,2.4C12.1,71.2,12.1,72.4,12,73.8z
	 M322.8,50.8c-1.1-1-2.6-2.3-5-4.2c-1.8-1.4-3.5-2.8-5-4.2c-1.5-1.4-2.3-2.8-2.3-4c0-1.5,0.4-2.9,1.3-4.2c0.9-1.3,2.5-2,4.7-2
	c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,1,0.8c0.3,0.3,0.6,0.5,0.9,0.8c0.3,0.2,0.5,0.3,0.8,0.3c0.9,0,1.6-0.3,2.2-0.8
	c0.6-0.5,1-1.2,1.4-1.9c0.4-0.7,0.6-1.4,0.8-2.2c0.2-0.7,0.2-1.3,0.2-1.7c0-1.3-0.5-2.1-1.6-2.6c-1.1-0.4-2.2-0.7-3.3-0.7
	c-1.2,0-2.8,0.2-4.8,0.6c-2,0.4-4,1-6,1.9c-2,0.9-3.6,2.1-5.1,3.6s-2.2,3.4-2.2,5.7c0,1.3,0.3,2.5,0.8,3.7c-0.4,0.1-0.7,0.3-0.9,0.6
	c-0.3,0.4-0.5,1.1-0.7,2.1c-0.3,1.6-0.7,3.2-1.2,4.7c-0.4,1.5-1,2.9-1.5,4.1s-1.2,2.2-1.9,2.9c-0.7,0.7-1.5,1.1-2.3,1.1
	c-0.6,0-1.1-0.3-1.5-0.9s-0.8-1.3-1-2.3c-0.3-0.9-0.4-2-0.6-3.1c-0.1-1.2-0.2-2.3-0.2-3.6c0-3.2,0-6.3,0.1-9.2
	c0.1-2.9,0.1-5.5,0.2-7.7c0.1-2.2,0.2-4,0.3-5.4c0.1-1.4,0.2-2.1,0.2-2.3c0.1-0.3,0.7-0.5,1.8-0.6c1.1-0.2,2.2-0.3,3.5-0.4
	c1.3-0.1,2.5-0.2,3.6-0.3c1.2-0.1,1.9-0.1,2.1-0.1c1,0,1.6,0.1,1.8,0.3c0.2,0.2,0.4,0.3,0.5,0.3c0.2,0,0.4-0.1,0.5-0.4
	c0.1-0.3,0.2-0.5,0.2-0.6c0-0.8-0.3-1.5-1-1.9c-0.7-0.4-1.5-0.7-2.6-0.9c-1.1-0.2-2.2-0.3-3.4-0.3c-1.2,0-2.3,0-3.4,0
	c-1.1,0-1.9-0.1-2.6-0.3c-0.7-0.2-1-0.4-1-0.8l1.1-12.5c0-0.4-0.3-0.7-1-1.1c-0.7-0.3-1.5-0.6-2.4-0.8c-0.9-0.2-1.8-0.4-2.6-0.5
	c-0.8-0.1-1.4-0.2-1.7-0.2c-0.3,0-0.5,0.4-0.6,1.2c-0.2,0.8-0.3,1.7-0.3,2.9c-0.1,1.2-0.1,2.4-0.1,3.8c0,1.3-0.1,2.6-0.1,3.8
	c0,1.2-0.1,2.2-0.2,2.9c-0.1,0.8-0.3,1.2-0.5,1.2c-3.3,0-5.7,0.1-7.4,0.2c-1.7,0.1-2.5,0.6-2.5,1.4c0,1,0.5,1.6,1.5,2.1
	c1,0.4,2.2,0.7,3.5,0.9c1.3,0.2,2.4,0.4,3.5,0.6c1,0.2,1.5,0.5,1.5,1.1c-0.1,2.9-0.3,6-0.4,9.4c-0.1,3.4-0.2,6.7-0.3,10v0.9
	c0,0.4,0,0.8,0,1.3c-0.1,0.3-0.3,0.8-0.4,1.5c-0.3,1.3-0.6,2.7-1,4.2c-0.4,1.4-1,2.7-1.8,3.9c-0.8,1.2-1.7,2.1-2.9,2.8
	c-1.2,0.7-2.7,1.1-4.6,1.1c-1.3,0-2.5-0.3-3.4-0.9c-0.9-0.6-1.7-1.3-2.3-2.1c-0.6-0.8-1-1.6-1.3-2.4c-0.3-0.8-0.4-1.5-0.4-1.9v-2.8
	c0.4,0.1,0.9,0.2,1.4,0.3c0.5,0.1,0.9,0.1,1.2,0.1c1.8,0,3.4-0.5,4.8-1.6c1.4-1.1,2.6-2.4,3.6-3.9c1-1.6,1.7-3.3,2.2-5.1
	c0.5-1.8,0.8-3.5,0.8-5l-0.1-1.3c-0.1-1.1-0.3-2.1-0.8-3.2c-0.4-1-1.1-1.9-1.8-2.7c-0.8-0.8-1.7-1.4-2.7-1.9c-1-0.5-2.1-0.7-3.3-0.7
	c-2.3,0-4.3,0.7-5.9,2.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0.1-0.6,0.4-1.3,0.9c-0.7,0.5-1.5,1.1-2.5,1.9c-1,0.8-2.1,1.6-3.4,2.6
	c-1.3,1-2.6,2-4.1,3.2c0.1-1,0.1-2.1,0.1-3.1c0-1-0.1-2-0.2-2.9c-0.1-1-0.4-2-0.9-2.9c-0.5-0.9-1-1.7-1.6-2.4
	c-0.6-0.7-1.3-1.2-2-1.6c-0.7-0.4-1.4-0.6-2.1-0.6c-1,0-2,0.3-3.1,0.9c-1,0.6-2.1,1.3-3.1,2.2c-1,0.9-2,1.9-2.9,3.1
	c-0.9,1.2-1.8,2.3-2.7,3.6c0.5-2.3,1.1-4.3,1.6-6.1c0.5-1.8,1-3.2,1.5-4.2c0.1-0.3-0.2-0.5-0.9-0.5c-0.7-0.1-1.6,0-2.6,0.1
	c-1,0.1-2,0.3-2.9,0.4c-1,0.2-1.6,0.4-2,0.6c-0.5,0.3-0.9,1.2-1.4,2.8c-0.5,1.6-1,3.6-1.4,6.1c-0.5,2.5-0.9,5.2-1.3,8.3
	c-0.4,3.1-0.8,6.3-1.1,9.6s-0.6,6.7-0.8,10c-0.2,3.3-0.4,6.5-0.4,9.4c-0.1,2.9-0.1,5.6,0,7.9c0.1,2.3,0.3,4.1,0.6,5.4
	c0.4,1.4,0.9,2.6,1.6,3.5c0.6,0.9,1.3,1.6,1.9,2c0.6,0.5,1.2,0.7,1.7,0.8c0.5,0.1,0.9,0,1.1-0.3c0.4-0.5,0.5-0.9,0.2-1.2
	c-0.2-0.4-0.5-0.7-0.8-1.1l-0.5-0.8l0.1,0.1c-0.3-1.3-0.5-3.2-0.5-5.7c0-2.6,0.2-5.5,0.4-8.8c0.3-3.3,0.7-6.8,1.2-10.6
	c0.5-3.8,1.1-7.6,1.7-11.4c0.2-0.9,0.6-2,1.2-3.3c0.6-1.3,1.3-2.7,2-4.1c0.8-1.4,1.6-2.9,2.6-4.3s1.9-2.7,2.8-3.8
	c0.9-1.1,1.8-2,2.7-2.7c0.9-0.7,1.7-1.1,2.5-1.1c0.4,0.1,0.6,0.5,0.7,1.4c0.1,0.8,0.1,1.9,0,3.2c-0.1,1.3-0.3,2.8-0.6,4.5
	c-0.3,1.7-0.7,3.5-1.1,5.3c-1.8,1.5-3.6,3-5.4,4.5c-1.7,1.5-3.3,2.9-4.8,4.2c-0.7,0.7-1.2,1.5-1.6,2.2c-0.4,0.8-0.7,1.5-0.9,2.2
	c-0.2,0.7-0.3,1.4-0.3,1.9c0,0.6,0,1,0.1,1.3c0.1,1,0.4,1.9,0.9,2.6c0.5,0.8,1,1.4,1.6,1.9c0.6,0.5,1.2,0.9,1.9,1.1
	c0.7,0.3,1.2,0.4,1.8,0.4c1.6-0.1,3.2-0.8,4.7-2.1c1.5-1.3,2.9-3,4.2-5.1s2.4-4.5,3.4-7.2c1-2.7,1.8-5.4,2.4-8.2l6.2-5.2
	c0.3-0.3,0.6-0.5,1-0.8c-0.4,1.3-0.8,2.6-1,4c-0.5,2.7-0.8,5.2-0.8,7.4c0,2.3,0.2,4.5,0.7,6.6c0.5,2.1,1.3,3.9,2.4,5.4
	c1.1,1.5,2.5,2.8,4.3,3.6s3.8,1.3,6.3,1.3c2.6,0,4.9-0.6,6.8-1.7c1.9-1.2,3.4-2.6,4.7-4.3c0.9-1.2,1.6-2.5,2.1-3.8
	c0.4,1.5,0.9,2.9,1.5,4.2c0.9,1.8,1.9,3.1,3.2,4.1c1.3,1,2.8,1.5,4.5,1.5c1.8,0,3.4-0.7,4.7-2.2s2.5-3.2,3.4-5.1
	c0.9-2,1.7-3.9,2.2-6c0.5-2,0.9-3.6,1.1-4.8c0-0.1,0.1-0.2,0.1-0.5c0.4,0.4,0.7,0.9,1.1,1.3c1.1,1.1,2.3,2.2,3.6,3.1
	c1.3,1,2.5,1.9,3.6,2.8c1.1,0.9,2,1.8,2.8,2.6c0.7,0.8,1.1,1.7,1.1,2.6c0,0.9-0.4,1.5-1.3,1.9c-0.9,0.4-1.6,0.5-2.1,0.5
	c-1.2,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.7-1.1-2.4-1.6c-0.7-0.6-1.4-1.1-2.1-1.6c-0.7-0.5-1.4-0.8-2.1-0.8c-0.5,0-1,0.1-1.6,0.4
	c-0.5,0.3-0.8,0.8-0.8,1.5c0,1.2,0.4,2.1,1.3,2.9c0.9,0.8,1.9,1.4,3.2,1.8c1.2,0.4,2.5,0.7,3.8,0.9c1.3,0.2,2.4,0.3,3.3,0.3
	c0.6,0,1.5,0,2.8-0.1c1.3-0.1,2.6-0.3,3.9-0.7c1.3-0.4,2.5-1,3.5-1.8c1-0.8,1.5-2,1.5-3.6C325.1,53.5,324.3,52.2,322.8,50.8z
	 M238.8,54.5c-0.8,1.7-1.6,2.8-2.3,3.2c-0.5,0.3-1,0.3-1.3,0s-0.5-0.7-0.6-1.2c-0.1-0.5,0-1,0.1-1.5c0.2-0.5,0.5-1,0.9-1.4
	c0.6-0.6,1.4-1.4,2.4-2.3c1-0.9,2.1-1.9,3.3-3C240.4,50.6,239.6,52.7,238.8,54.5z M269.3,29.4c0.8-1.2,1.8-1.8,3-1.8
	c0.8,0,1.3,0.3,1.6,0.9s0.4,1.1,0.4,1.6v0.6c0,0.9-0.1,2-0.4,3.5c-0.3,1.4-0.7,2.8-1.2,4.1c-0.5,1.3-1.3,2.5-2.2,3.5s-2,1.4-3.3,1.4
	h-0.5c-0.6,0-0.9-0.2-1-0.5c-0.1-0.4-0.1-0.8-0.1-1.4v-0.3c0-0.4,0.1-1.4,0.4-2.8c0.3-1.4,0.7-2.9,1.2-4.5
	C267.8,32,268.5,30.6,269.3,29.4z"/>
    </g>
  </svg>
);

export const LogoIcon = ({ fill1, fill2, height }) => (
  <svg
    viewBox="0 0 388.3 95.8"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height={height}
  >
    <g>
    <path class="st0" fill={fill1} d="M189.9,18.3c0,0.4-0.2,0.8-0.7,1.1c-0.4,0.3-0.9,0.5-1.5,0.6c-0.5,0.1-1.1,0.2-1.6,0.3c-0.5,0.1-0.8,0.1-1,0.1
	c-1.3,0-2.7-0.4-4-1.2c-1.3-0.8-2.5-2-3.6-3.6c-0.1-0.2-0.3-0.4-0.6-0.8c-0.3-0.3-0.4-0.5-0.4-0.7c0-0.3,0.2-0.6,0.7-0.9
	c0.5-0.3,1-0.5,1.6-0.8c0.6-0.3,1.2-0.4,1.7-0.6c0.5-0.1,0.9-0.2,1.1-0.2c1.3,0,2.5,0.3,3.8,1s2.3,1.8,3,3.6c0.1,0.2,0.4,0.5,0.7,1
	C189.7,17.8,189.9,18.1,189.9,18.3z M245.7,54.8c0,1.5-0.5,2.7-1.5,3.6c-1,0.8-2.2,1.4-3.5,1.8c-1.3,0.4-2.6,0.6-3.9,0.7
	c-1.3,0.1-2.3,0.1-2.8,0.1c-0.9,0-2-0.1-3.3-0.3c-1.3-0.2-2.6-0.5-3.8-0.9c-1.2-0.4-2.3-1-3.2-1.8c-0.9-0.8-1.3-1.7-1.3-2.9
	c0-0.7,0.3-1.2,0.8-1.5c0.5-0.3,1.1-0.4,1.6-0.4c0.7,0,1.4,0.3,2.1,0.8c0.7,0.5,1.4,1.1,2.1,1.6c0.7,0.6,1.6,1.1,2.4,1.6
	c0.9,0.5,1.9,0.8,3.1,0.8c0.5,0,1.2-0.2,2.1-0.5c0.9-0.4,1.3-1,1.3-1.9c0-0.9-0.4-1.8-1.1-2.6c-0.7-0.8-1.7-1.7-2.8-2.6
	c-1.1-0.9-2.3-1.8-3.6-2.8c-1.3-1-2.5-2-3.6-3.1c-1.1-1.1-2-2.4-2.8-3.7c-0.7-1.3-1.1-2.8-1.1-4.4c0-2.2,0.7-4.1,2.2-5.7
	s3.1-2.8,5.1-3.6c2-0.9,4-1.5,6-1.9c2-0.4,3.6-0.6,4.8-0.6c1.1,0,2.2,0.2,3.3,0.7c1.1,0.4,1.6,1.3,1.6,2.6c0,0.4-0.1,0.9-0.2,1.7
	c-0.2,0.7-0.4,1.5-0.8,2.2c-0.4,0.7-0.8,1.3-1.4,1.9c-0.6,0.5-1.3,0.8-2.2,0.8c-0.3,0-0.5-0.1-0.8-0.3c-0.3-0.2-0.5-0.5-0.9-0.8
	c-0.3-0.3-0.7-0.5-1-0.8c-0.4-0.2-0.8-0.3-1.2-0.3c-2.2,0-3.8,0.7-4.7,2c-0.9,1.3-1.3,2.7-1.3,4.2c0,1.3,0.8,2.6,2.3,4
	c1.5,1.4,3.2,2.8,5,4.2c1.8,1.4,3.5,2.8,5,4.2C244.9,52.2,245.7,53.5,245.7,54.8z M247.8,41.4c0.1-3.3,0.2-6.6,0.3-10
	c0.1-3.4,0.3-6.5,0.4-9.4c0-0.5-0.5-0.9-1.5-1.1c-1-0.2-2.2-0.4-3.5-0.6c-1.3-0.2-2.4-0.5-3.5-0.9c-1-0.4-1.5-1.1-1.5-2.1
	c0-0.8,0.8-1.3,2.5-1.4c1.7-0.1,4.1-0.2,7.4-0.2c0.3,0,0.4-0.4,0.5-1.2c0.1-0.8,0.2-1.7,0.2-2.9c0-1.2,0.1-2.4,0.1-3.8
	c0-1.3,0.1-2.6,0.1-3.8c0.1-1.2,0.2-2.2,0.3-2.9c0.2-0.8,0.4-1.2,0.6-1.2c0.3,0,0.9,0.1,1.7,0.2c0.8,0.1,1.7,0.3,2.6,0.5
	c0.9,0.2,1.7,0.5,2.4,0.8c0.7,0.3,1,0.7,1,1.1l-1.1,12.5c0,0.4,0.3,0.7,1,0.8c0.7,0.2,1.5,0.3,2.6,0.3c1.1,0,2.2,0,3.4,0
	c1.2,0,2.3,0.1,3.4,0.3c1.1,0.2,1.9,0.5,2.6,0.9c0.7,0.4,1,1,1,1.9c0,0.1-0.1,0.3-0.2,0.6c-0.1,0.3-0.3,0.4-0.5,0.4
	c-0.1,0-0.3-0.1-0.5-0.3c-0.2-0.2-0.8-0.3-1.8-0.3c-0.3,0-1,0-2.1,0.1c-1.2,0.1-2.4,0.2-3.6,0.3c-1.3,0.1-2.4,0.3-3.5,0.4
	c-1.1,0.2-1.6,0.4-1.8,0.6c-0.1,0.1-0.1,0.9-0.2,2.3c-0.1,1.4-0.2,3.2-0.3,5.4c-0.1,2.2-0.2,4.8-0.2,7.7c-0.1,2.9-0.1,6-0.1,9.2
	c0,1.2,0.1,2.4,0.2,3.6c0.1,1.2,0.3,2.2,0.6,3.1c0.3,0.9,0.6,1.7,1,2.3c0.4,0.6,0.9,0.9,1.5,0.9c0.8,0,1.6-0.4,2.3-1.1
	c0.7-0.7,1.3-1.7,1.9-2.9c0.6-1.2,1.1-2.6,1.5-4.1c0.4-1.5,0.8-3.1,1.2-4.7c0.2-1,0.4-1.6,0.7-2.1c0.3-0.4,0.6-0.6,1.2-0.6
	c0.4,0,0.8,0.1,1.2,0.4c0.4,0.3,0.5,0.9,0.5,2c0,0.6,0,1.1-0.1,1.2c-0.2,1.2-0.6,2.7-1.1,4.8c-0.5,2-1.3,4-2.2,6
	c-0.9,2-2.1,3.7-3.4,5.1c-1.3,1.5-2.9,2.2-4.7,2.2c-1.7,0-3.2-0.5-4.5-1.5c-1.3-1-2.4-2.4-3.2-4.1c-0.9-1.8-1.5-3.8-2-6.2
	c-0.4-2.4-0.7-5-0.7-7.7V41.4z M223.7,42.7c-0.4,0-0.8,0.2-1,0.5c-0.2,0.3-0.4,0.9-0.6,1.8c-0.3,1.3-0.6,2.7-1,4.2
	c-0.4,1.4-1,2.7-1.8,3.9c-0.8,1.2-1.7,2.1-2.9,2.8c-1.2,0.7-2.7,1.1-4.6,1.1c-1.3,0-2.5-0.3-3.4-0.9c-0.9-0.6-1.7-1.3-2.3-2.1
	c-0.6-0.8-1-1.6-1.3-2.4c-0.3-0.8-0.4-1.5-0.4-1.9v-2.8c0.4,0.1,0.9,0.2,1.4,0.3c0.5,0.1,0.9,0.1,1.2,0.1c1.8,0,3.4-0.5,4.8-1.6
	c1.4-1.1,2.6-2.4,3.6-3.9c1-1.6,1.7-3.3,2.2-5.1c0.5-1.8,0.8-3.5,0.8-5l-0.1-1.3c-0.1-1.1-0.3-2.1-0.8-3.2c-0.4-1-1.1-1.9-1.8-2.7
	c-0.8-0.8-1.7-1.4-2.7-1.9c-1-0.5-2.1-0.7-3.3-0.7c-2.4,0-4.4,0.8-6,2.4c-1.7,1.6-3,3.5-4.1,5.9c-1.1,2.3-1.9,4.8-2.4,7.5
	c-0.5,2.7-0.8,5.2-0.8,7.4c0,0.4,0,0.8,0,1.3c-0.3,1.4-0.6,2.8-1,4.2c-0.4,1.4-0.9,2.6-1.6,3.6c-0.4,0.5-0.9,1.1-1.6,1.8
	c-0.7,0.7-1.7,1.1-2.9,1.1c-0.8,0-1.5-0.3-2-0.8c-0.5-0.5-0.9-1.2-1.1-2.1c-0.2-0.9-0.4-1.8-0.4-2.9c-0.1-1.1-0.1-2.1-0.1-3.1v-5.8
	c0.1-1.2,0.1-2.1,0.2-2.8c0.1-0.7,0.1-1.4,0.2-1.9c0.1-0.5,0.1-1,0.2-1.4c0.1-0.4,0.1-0.9,0.2-1.4c0.3-1.3,0.5-2.2,0.8-2.7
	c0.3-0.5,0.4-0.9,0.3-1.1c0-0.8-0.3-1.4-0.9-1.8c-0.6-0.4-1.3-0.7-2.1-0.9c-0.8-0.2-1.5-0.3-2.2-0.3c-0.7,0-1.2,0-1.3,0
	c-0.7,0-1.2,0-1.6,0.1c-0.4,0.1-0.7,0.2-1.1,0.3c-0.3,0.2-0.5,0.4-0.5,0.6c0,0.1,0.1,0.4,0.2,0.7c0.2,0.3,0.3,0.6,0.3,0.9
	c0.1,0.5,0.2,1.1,0.2,1.6c0,0.6,0,1.1,0,1.6v10.6c0,0,0,0.1,0,0.1c-0.6,0.2-1,0.8-1,1.8c-0.1,1-0.3,2-0.6,3.1
	c-0.3,1.1-0.7,2.1-1.2,3.1s-1,1.8-1.6,2.4c-0.6,0.6-1.2,1-1.9,1c-1,0-1.6-0.7-1.9-2c-0.3-1.3-0.5-2.8-0.5-4.5c0-2.9,0.1-5.2,0.2-6.6
	c0.1-1.5,0.2-2.5,0.2-3.2c0-0.9,0-1.8-0.1-2.8c-0.1-1-0.3-1.9-0.6-2.7c-0.3-0.8-0.8-1.5-1.4-2.1c-0.6-0.5-1.5-0.8-2.6-0.8
	c-0.9,0-1.8,0.5-2.6,1.6c-0.9,1.1-1.7,2.4-2.4,3.9s-1.5,3.2-2.1,4.8c-0.6,1.7-1.2,3.1-1.6,4.3c-0.1,0.4-0.2,0.7-0.3,0.8V26.9
	c0-0.4-0.2-0.7-0.7-1c-0.4-0.3-1-0.5-1.6-0.7c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1-0.1-1.3-0.1c-0.9,0-1.3,0.4-1.3,1.2L149,44.6
	c-0.3,0.3-0.5,0.8-0.5,1.5c-0.1,1-0.3,2-0.6,3.1c-0.3,1.1-0.7,2.1-1.2,3.1s-1,1.8-1.6,2.4c-0.6,0.6-1.2,1-1.9,1c-1,0-1.6-0.7-1.9-2
	c-0.3-1.3-0.5-2.8-0.5-4.5c0-2.9,0.1-5.2,0.2-6.6c0.1-1.5,0.2-2.5,0.2-3.2c0-0.9,0-1.8-0.1-2.8c-0.1-1-0.3-1.9-0.6-2.7
	c-0.3-0.8-0.8-1.5-1.4-2.1c-0.6-0.5-1.5-0.8-2.6-0.8c-0.9,0-1.8,0.5-2.6,1.6c-0.9,1.1-1.7,2.4-2.4,3.9s-1.5,3.2-2.1,4.8
	c-0.6,1.7-1.2,3.1-1.6,4.3c-0.1,0.4-0.2,0.7-0.3,0.8V26.9c0-0.4-0.2-0.7-0.7-1c-0.4-0.3-1-0.5-1.6-0.7c-0.6-0.2-1.2-0.3-1.8-0.4
	c-0.6-0.1-1-0.1-1.3-0.1c-0.9,0-1.3,0.4-1.3,1.2l-1.1,24.1c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.4-0.6,0.9-1,1.4c-0.3,0.5-0.7,1-1.1,1.4
	c-0.4,0.4-0.9,0.6-1.6,0.6c-0.8,0-1.4-0.4-1.9-1.3c-0.5-0.9-0.9-2-1.2-3.3c-0.3-1.3-0.5-2.8-0.7-4.4c-0.1-1.6-0.2-3.1-0.2-4.6
	c0-1.2,0-2,0-2.6c0-0.6,0.1-1.2,0.1-1.7c0-0.5,0.1-1.2,0.2-1.8c0.1-0.7,0.2-1.6,0.3-2.7c0-1.9-0.4-3.1-1.3-3.5
	c-0.9-0.4-2.3-0.6-4.2-0.6c-0.8,0-1.6,0.1-2.5,0.2c-0.9,0.1-1.5,0.4-1.6,1c0,0.7,0.1,1.3,0.4,1.8c0.3,0.5,0.4,1,0.4,1.5
	c-0.1,8.1-0.7,14-1.7,17.7c-1,3.7-2.5,5.6-4.3,5.6c-0.7,0-1.2-0.4-1.6-1.2c-0.4-0.8-0.7-1.8-0.8-3c-0.2-1.2-0.3-2.5-0.3-3.9
	c0-1.4,0-2.7,0-3.8V26c0-0.5-0.3-1-1-1.3s-1.4-0.7-2.3-0.9c-0.8-0.2-1.6-0.4-2.4-0.5C88,23,87.5,23,87.1,23c-0.5,0-0.8,0.1-0.8,0.3
	c0,0.2-0.1,0.5-0.2,1V38c0,0.1-0.1,0.1-0.1,0.2c-0.8,3-1.7,5.5-2.7,7.5c-1,2-2,3.7-3,5.1c-1,1.4-2,2.5-3,3.4c-1,0.9-1.9,1.6-2.8,2.3
	c-1.2-1.1-2.5-1.8-3.9-2c0.5-4.7,1.2-9.5,2-14.5c0.8-5,1.7-9.8,2.8-14.5c1-4.7,2.2-9.1,3.6-13.2c1.3-4.1,2.8-7.6,4.4-10.5
	c0.1-0.3,0-0.4-0.3-0.5c-0.3-0.1-0.7-0.2-1.3-0.2s-1.2,0-2,0.1c-0.7,0.1-1.4,0.2-2.1,0.3C77.1,1.7,76.5,1.8,76,2
	c-0.5,0.2-0.9,0.3-1,0.4c-0.7,0.5-1.4,1.9-2.1,3.9c-0.7,2.1-1.5,4.7-2.2,7.9c-0.8,3.2-1.5,6.8-2.2,10.8c-0.7,4-1.4,8.2-2,12.6
	c-0.6,4.4-1.2,8.9-1.6,13.4c-0.5,4.6-0.9,8.9-1.1,13.1c-0.3,4.2-0.4,8.1-0.3,11.8c0,3.7,0.2,6.8,0.6,9.5c0.1,0.8,0.3,1.6,0.4,2.4
	c0.1,0.8,0.3,1.4,0.5,2c0.7,2.1,1.5,3.7,2.5,4.6c0.9,0.9,2,1.4,3.2,1.4c0.7,0,1.4-0.5,2.3-1.5c0.9-1,1.7-2.4,2.5-4.1
	c0.8-1.7,1.5-3.8,2.1-6.1c0.6-2.4,1.1-4.8,1.4-7.5c0.3-2.6,0.3-5.3,0.1-8.2c-0.2-2.8-0.8-5.6-1.6-8.3c1-0.8,2.1-1.8,3.2-3.1
	c1.2-1.2,2.3-2.7,3.5-4.5c0.8-1.2,1.6-2.5,2.3-4c0.3,4,1.2,7.1,2.6,9.2c1.5,2.4,4.4,3.6,8.4,3.6c1.4,0,2.6-0.6,3.5-1.7
	c0.9-1.1,1.7-2.4,2.3-3.7c0.6-1.4,1-2.7,1.3-4c0.3-1.3,0.5-2.1,0.7-2.5c0.8,2.6,1.9,4.6,3.2,5.9l0.9,0.8c0.8,0.5,1.5,0.9,2.3,1.2
	c0.8,0.3,1.7,0.4,2.8,0.4c0.6,0,1.3-0.1,2.2-0.3c0.9-0.2,1.8-0.6,2.6-1.1c0.2-0.1,0.3-0.2,0.5-0.3c0.1,1,0.2,1.8,0.3,2.5
	c0.2,1,0.5,1.7,0.9,2.2c0.4,0.5,0.9,0.8,1.7,0.9c0.7,0.1,1.7,0.2,2.8,0.2c0.4,0,1-0.1,1.9-0.2c0.9-0.1,1.4-0.4,1.5-0.8
	c0.3-1.6,0.6-3.3,0.9-5c0.3-1.8,0.7-3.5,1.1-5.3c0.4-1.8,1-3.5,1.6-5.1c0.6-1.6,1.4-3.1,2.4-4.5l-0.2,7c0,4.1,0.4,7.2,1.2,9.3
	c0.8,2.1,2.6,3.1,5.5,3.1c1.9,0,3.4-0.5,4.7-1.4c1.2-0.9,2.2-2,3-3.3c0.1,1.2,0.2,2.1,0.4,2.9c0.2,1,0.5,1.7,0.9,2.2
	c0.4,0.5,0.9,0.8,1.7,0.9c0.7,0.1,1.7,0.2,2.8,0.2c0.4,0,1-0.1,1.9-0.2c0.9-0.1,1.4-0.4,1.5-0.8c0.3-1.6,0.6-3.3,0.9-5
	c0.3-1.8,0.7-3.5,1.1-5.3c0.4-1.8,1-3.5,1.6-5.1c0.6-1.6,1.4-3.1,2.4-4.5l-0.2,7c0,4.1,0.4,7.2,1.2,9.3c0.8,2.1,2.6,3.1,5.5,3.1
	c1.9,0,3.4-0.5,4.7-1.4c1.3-1,2.3-2.1,3.1-3.6c0.3-0.6,0.6-1.1,0.8-1.7c0.1,0.7,0.3,1.4,0.6,2.1c0.6,1.9,1.7,3.4,3.4,4.5
	c1.7,1.1,3.3,1.6,4.9,1.6c2,0,3.8-0.5,5.2-1.4c1.4-0.9,2.6-2.1,3.5-3.6c0.6-0.9,1.1-1.9,1.5-2.8c0.4,1,0.9,2,1.6,2.9
	c1.1,1.5,2.5,2.8,4.3,3.6c1.7,0.9,3.8,1.3,6.3,1.3c2.6,0,4.9-0.6,6.8-1.7c1.9-1.2,3.4-2.6,4.7-4.3c1.2-1.7,2.1-3.5,2.7-5.5
	c0.6-2,0.9-3.8,0.9-5.4v-0.7C225.1,43.2,224.7,42.7,223.7,42.7z M205.2,40.9c0-0.4,0.1-1.4,0.4-2.8c0.3-1.4,0.7-2.9,1.2-4.5
	c0.5-1.6,1.2-3,2.1-4.2c0.8-1.2,1.8-1.8,3-1.8c0.8,0,1.3,0.3,1.6,0.9s0.4,1.1,0.4,1.6v0.6c0,0.9-0.1,2-0.4,3.5
	c-0.3,1.4-0.7,2.8-1.2,4.1c-0.5,1.3-1.3,2.5-2.2,3.5c-0.9,1-2,1.4-3.3,1.4h-0.5c-0.6,0-0.9-0.2-1-0.5c-0.1-0.4-0.1-0.8-0.1-1.4V40.9
	z M75.2,73.8c-0.1,1.4-0.2,2.9-0.4,4.4c-0.2,1.6-0.5,3.1-0.9,4.6c-0.4,1.5-0.9,2.9-1.5,4.3c-0.6,1.3-1.3,2.4-2.1,3.4
	c-0.3,0.4-0.6,0.3-0.8-0.3c-0.2-0.6-0.4-1.4-0.5-2.3c-0.1-0.7-0.2-1.5-0.2-2.2c-0.1-0.8-0.1-1.5-0.1-2.3c-0.1-2,0-4.9,0.2-8.5
	c0.2-3.7,0.5-7.8,0.9-12.5c1,0.4,2.2,0.4,3.3,0.1c0.2,0,0.5-0.1,0.9-0.3c0.2,0.6,0.4,1.4,0.6,2.2c0.2,0.9,0.3,1.6,0.4,2.2
	c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0.1,0.5,0.1,1.3,0.2,2.4C75.3,71.2,75.3,72.4,75.2,73.8z M386,50.8
	c-1.1-1-2.6-2.3-5-4.2c-1.8-1.4-3.5-2.8-5-4.2c-1.5-1.4-2.3-2.8-2.3-4c0-1.5,0.4-2.9,1.3-4.2c0.9-1.3,2.5-2,4.7-2
	c0.4,0,0.8,0.1,1.2,0.3c0.4,0.2,0.7,0.5,1,0.8c0.3,0.3,0.6,0.5,0.9,0.8c0.3,0.2,0.5,0.3,0.8,0.3c0.9,0,1.6-0.3,2.2-0.8
	c0.6-0.5,1-1.2,1.4-1.9c0.4-0.7,0.6-1.4,0.8-2.2c0.2-0.7,0.2-1.3,0.2-1.7c0-1.3-0.5-2.1-1.6-2.6c-1.1-0.4-2.2-0.7-3.3-0.7
	c-1.2,0-2.8,0.2-4.8,0.6c-2,0.4-4,1-6,1.9c-2,0.9-3.6,2.1-5.1,3.6s-2.2,3.4-2.2,5.7c0,1.3,0.3,2.5,0.8,3.7c-0.4,0.1-0.7,0.3-0.9,0.6
	c-0.3,0.4-0.5,1.1-0.7,2.1c-0.3,1.6-0.7,3.2-1.2,4.7c-0.4,1.5-1,2.9-1.5,4.1s-1.2,2.2-1.9,2.9c-0.7,0.7-1.5,1.1-2.3,1.1
	c-0.6,0-1.1-0.3-1.5-0.9s-0.8-1.3-1-2.3c-0.3-0.9-0.4-2-0.6-3.1c-0.1-1.2-0.2-2.3-0.2-3.6c0-3.2,0-6.3,0.1-9.2
	c0.1-2.9,0.1-5.5,0.2-7.7c0.1-2.2,0.2-4,0.3-5.4c0.1-1.4,0.2-2.1,0.2-2.3c0.1-0.3,0.7-0.5,1.8-0.6c1.1-0.2,2.2-0.3,3.5-0.4
	c1.3-0.1,2.5-0.2,3.6-0.3c1.2-0.1,1.9-0.1,2.1-0.1c1,0,1.6,0.1,1.8,0.3c0.2,0.2,0.4,0.3,0.5,0.3c0.2,0,0.4-0.1,0.5-0.4
	c0.1-0.3,0.2-0.5,0.2-0.6c0-0.8-0.3-1.5-1-1.9c-0.7-0.4-1.5-0.7-2.6-0.9c-1.1-0.2-2.2-0.3-3.4-0.3c-1.2,0-2.3,0-3.4,0
	c-1.1,0-1.9-0.1-2.6-0.3c-0.7-0.2-1-0.4-1-0.8l1.1-12.5c0-0.4-0.3-0.7-1-1.1c-0.7-0.3-1.5-0.6-2.4-0.8c-0.9-0.2-1.8-0.4-2.6-0.5
	c-0.8-0.1-1.4-0.2-1.7-0.2c-0.3,0-0.5,0.4-0.6,1.2c-0.2,0.8-0.3,1.7-0.3,2.9c-0.1,1.2-0.1,2.4-0.1,3.8c0,1.3-0.1,2.6-0.1,3.8
	c0,1.2-0.1,2.2-0.2,2.9c-0.1,0.8-0.3,1.2-0.5,1.2c-3.3,0-5.7,0.1-7.4,0.2c-1.7,0.1-2.5,0.6-2.5,1.4c0,1,0.5,1.6,1.5,2.1
	c1,0.4,2.2,0.7,3.5,0.9c1.3,0.2,2.4,0.4,3.5,0.6c1,0.2,1.5,0.5,1.5,1.1c-0.1,2.9-0.3,6-0.4,9.4c-0.1,3.4-0.2,6.7-0.3,10v0.9
	c0,0.4,0,0.8,0,1.3c-0.1,0.3-0.3,0.8-0.4,1.5c-0.3,1.3-0.6,2.7-1,4.2c-0.4,1.4-1,2.7-1.8,3.9c-0.8,1.2-1.7,2.1-2.9,2.8
	c-1.2,0.7-2.7,1.1-4.6,1.1c-1.3,0-2.5-0.3-3.4-0.9c-0.9-0.6-1.7-1.3-2.3-2.1c-0.6-0.8-1-1.6-1.3-2.4c-0.3-0.8-0.4-1.5-0.4-1.9v-2.8
	c0.4,0.1,0.9,0.2,1.4,0.3c0.5,0.1,0.9,0.1,1.2,0.1c1.8,0,3.4-0.5,4.8-1.6c1.4-1.1,2.6-2.4,3.6-3.9c1-1.6,1.7-3.3,2.2-5.1
	c0.5-1.8,0.8-3.5,0.8-5l-0.1-1.3c-0.1-1.1-0.3-2.1-0.8-3.2c-0.4-1-1.1-1.9-1.8-2.7c-0.8-0.8-1.7-1.4-2.7-1.9c-1-0.5-2.1-0.7-3.3-0.7
	c-2.3,0-4.3,0.7-5.9,2.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0.1-0.6,0.4-1.3,0.9c-0.7,0.5-1.5,1.1-2.5,1.9c-1,0.8-2.1,1.6-3.4,2.6
	c-1.3,1-2.6,2-4.1,3.2c0.1-1,0.1-2.1,0.1-3.1c0-1-0.1-2-0.2-2.9c-0.1-1-0.4-2-0.9-2.9c-0.5-0.9-1-1.7-1.6-2.4
	c-0.6-0.7-1.3-1.2-2-1.6c-0.7-0.4-1.4-0.6-2.1-0.6c-1,0-2,0.3-3.1,0.9c-1,0.6-2.1,1.3-3.1,2.2c-1,0.9-2,1.9-2.9,3.1
	c-0.9,1.2-1.8,2.3-2.7,3.6c0.5-2.3,1.1-4.3,1.6-6.1c0.5-1.8,1-3.2,1.5-4.2c0.1-0.3-0.2-0.5-0.9-0.5c-0.7-0.1-1.6,0-2.6,0.1
	c-1,0.1-2,0.3-2.9,0.4c-1,0.2-1.6,0.4-2,0.6c-0.5,0.3-0.9,1.2-1.4,2.8c-0.5,1.6-1,3.6-1.4,6.1c-0.5,2.5-0.9,5.2-1.3,8.3
	c-0.4,3.1-0.8,6.3-1.1,9.6s-0.6,6.7-0.8,10c-0.2,3.3-0.4,6.5-0.4,9.4c-0.1,2.9-0.1,5.6,0,7.9c0.1,2.3,0.3,4.1,0.6,5.4
	c0.4,1.4,0.9,2.6,1.6,3.5c0.6,0.9,1.3,1.6,1.9,2c0.6,0.5,1.2,0.7,1.7,0.8c0.5,0.1,0.9,0,1.1-0.3c0.4-0.5,0.5-0.9,0.2-1.2
	c-0.2-0.4-0.5-0.7-0.8-1.1l-0.5-0.8l0.1,0.1c-0.3-1.3-0.5-3.2-0.5-5.7c0-2.6,0.2-5.5,0.4-8.8c0.3-3.3,0.7-6.8,1.2-10.6
	c0.5-3.8,1.1-7.6,1.7-11.4c0.2-0.9,0.6-2,1.2-3.3c0.6-1.3,1.3-2.7,2-4.1c0.8-1.4,1.6-2.9,2.6-4.3s1.9-2.7,2.8-3.8
	c0.9-1.1,1.8-2,2.7-2.7c0.9-0.7,1.7-1.1,2.5-1.1c0.4,0.1,0.6,0.5,0.7,1.4c0.1,0.8,0.1,1.9,0,3.2c-0.1,1.3-0.3,2.8-0.6,4.5
	c-0.3,1.7-0.7,3.5-1.1,5.3c-1.8,1.5-3.6,3-5.4,4.5c-1.7,1.5-3.3,2.9-4.8,4.2c-0.7,0.7-1.2,1.5-1.6,2.2c-0.4,0.8-0.7,1.5-0.9,2.2
	c-0.2,0.7-0.3,1.4-0.3,1.9c0,0.6,0,1,0.1,1.3c0.1,1,0.4,1.9,0.9,2.6c0.5,0.8,1,1.4,1.6,1.9c0.6,0.5,1.2,0.9,1.9,1.1
	c0.7,0.3,1.2,0.4,1.8,0.4c1.6-0.1,3.2-0.8,4.7-2.1c1.5-1.3,2.9-3,4.2-5.1s2.4-4.5,3.4-7.2c1-2.7,1.8-5.4,2.4-8.2l6.2-5.2
	c0.3-0.3,0.6-0.5,1-0.8c-0.4,1.3-0.8,2.6-1,4c-0.5,2.7-0.8,5.2-0.8,7.4c0,2.3,0.2,4.5,0.7,6.6c0.5,2.1,1.3,3.9,2.4,5.4
	c1.1,1.5,2.5,2.8,4.3,3.6s3.8,1.3,6.3,1.3c2.6,0,4.9-0.6,6.8-1.7c1.9-1.2,3.4-2.6,4.7-4.3c0.9-1.2,1.6-2.5,2.1-3.8
	c0.4,1.5,0.9,2.9,1.5,4.2c0.9,1.8,1.9,3.1,3.2,4.1c1.3,1,2.8,1.5,4.5,1.5c1.8,0,3.4-0.7,4.7-2.2s2.5-3.2,3.4-5.1
	c0.9-2,1.7-3.9,2.2-6c0.5-2,0.9-3.6,1.1-4.8c0-0.1,0.1-0.2,0.1-0.5c0.4,0.4,0.7,0.9,1.1,1.3c1.1,1.1,2.3,2.2,3.6,3.1
	c1.3,1,2.5,1.9,3.6,2.8c1.1,0.9,2,1.8,2.8,2.6c0.7,0.8,1.1,1.7,1.1,2.6c0,0.9-0.4,1.5-1.3,1.9c-0.9,0.4-1.6,0.5-2.1,0.5
	c-1.2,0-2.2-0.3-3.1-0.8c-0.9-0.5-1.7-1.1-2.4-1.6c-0.7-0.6-1.4-1.1-2.1-1.6c-0.7-0.5-1.4-0.8-2.1-0.8c-0.5,0-1,0.1-1.6,0.4
	c-0.5,0.3-0.8,0.8-0.8,1.5c0,1.2,0.4,2.1,1.3,2.9c0.9,0.8,1.9,1.4,3.2,1.8c1.2,0.4,2.5,0.7,3.8,0.9c1.3,0.2,2.4,0.3,3.3,0.3
	c0.6,0,1.5,0,2.8-0.1c1.3-0.1,2.6-0.3,3.9-0.7c1.3-0.4,2.5-1,3.5-1.8c1-0.8,1.5-2,1.5-3.6C388.3,53.5,387.5,52.2,386,50.8z
	 M302.1,54.5c-0.8,1.7-1.6,2.8-2.3,3.2c-0.5,0.3-1,0.3-1.3,0s-0.5-0.7-0.6-1.2c-0.1-0.5,0-1,0.1-1.5c0.2-0.5,0.5-1,0.9-1.4
	c0.6-0.6,1.4-1.4,2.4-2.3c1-0.9,2.1-1.9,3.3-3C303.7,50.6,302.9,52.7,302.1,54.5z M332.5,29.4c0.8-1.2,1.8-1.8,3-1.8
	c0.8,0,1.3,0.3,1.6,0.9s0.4,1.1,0.4,1.6v0.6c0,0.9-0.1,2-0.4,3.5c-0.3,1.4-0.7,2.8-1.2,4.1c-0.5,1.3-1.3,2.5-2.2,3.5s-2,1.4-3.3,1.4
	H330c-0.6,0-0.9-0.2-1-0.5c-0.1-0.4-0.1-0.8-0.1-1.4v-0.3c0-0.4,0.1-1.4,0.4-2.8c0.3-1.4,0.7-2.9,1.2-4.5
	C331,32,331.7,30.6,332.5,29.4z"/>
<path class="st1" fill={fill2} d="M12.9,28.2c-0.4-1.4-0.6-2.9-0.6-4.5c0-1.8,0.2-3.2,0.6-4.2c0.6-2,1.6-2.7,1.6-2.7c0.7-1.1,3.9-2.3,5.9-0.1
	c2,2.1,5.1,6.8,4.9,11.1c-0.1,4.3-3.6,10.1-7.1,8.3C15.8,34.7,13.9,31.7,12.9,28.2z M32.6,37.1c2.9,0.8,5.8-2.8,7.5-6.5
	c0.8-1.7,1.4-3.4,1.6-4.7c0.4-2.3,0-5.7-1.6-7.7c-1.2-1.6-3.2-2.2-6.2-0.5c0,0-4.8,3.1-5.5,7.7C27.8,30.1,28.4,35.9,32.6,37.1z
	 M44.5,35.5c0,0-2.6,1.8-4.4,4.5c-0.8,1.3-1.5,2.7-1.6,4.3c-0.3,3,0.2,5.4,1.6,6.8c0.8,0.8,1.9,1.2,3.3,1.2c3.9,0,8.8-5.6,8.8-10.8
	C52.2,36.3,49.4,32.3,44.5,35.5z M12.9,46.3c0.6-1.2,0.7-3,0.4-5.5c-0.1-0.5-0.2-1.1-0.4-1.6C11.6,34.8,7.8,32,7.8,32
	c-7.7-3.5-8.8,5.7-7.2,9.7c1.6,4,4.7,7.6,8.4,6.9C11,48.3,12.3,47.6,12.9,46.3z M40.1,56.4C37.5,51,33,45.9,31,44.1
	c-2.4-2.1-6.7-2.9-8.7-0.5c0,0-5.3,3.9-9.4,8.9c-1.6,1.9-2.9,4-3.8,6c-2.6,6.4,0.4,9.5,3.8,9.7c0.6,0,1.1,0,1.7-0.1
	c3.9-0.8,9.9-4.7,16.3-2.1c3.6,1.4,7.2,1.3,9.2-0.2c1.5-1.2,2.2-3.2,1.3-6C41.1,58.6,40.6,57.5,40.1,56.4z"/>
    </g>
  </svg>
);

export const Quote = ({ fill1, height }) => (
  <svg
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height={height}
  >
    <g>
    <path fill={fill1} d="M4.49 7.529a5.3 5.3 0 011.174-.131c3.128 0 5.663 2.716 5.663 6.065 0 3.35-2.535 6.066-5.663 6.066S0 16.814 0 13.463c0-.098.002-.197.007-.295H0C0 8.113 3.84 4 8.56 4v2.036c-1.531 0-2.943.558-4.07 1.493zm12.674 0c.378-.086.77-.131 1.172-.131 3.128 0 5.664 2.716 5.664 6.065 0 3.35-2.536 6.066-5.664 6.066-3.128 0-5.663-2.715-5.663-6.066 0-.098.002-.197.007-.295h-.007c0-5.055 3.84-9.168 8.56-9.168v2.036c-1.532 0-2.943.558-4.07 1.493z" clip-rule="evenodd"></path>
    </g>
  </svg>
);

export const Check = ({ fill1, height }) => (
  <svg
    viewBox="0 0 16 16"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height={height}
  >
    <g>
    <path fill={fill1} d="M6.533 12.04l-3.6-3.6a.333.333 0 010-.473L4.34 6.553a.333.333 0 01.473 0l2.094 2.114L11.08 3.9a.333.333 0 01.473 0l1.507 1.333a.333.333 0 010 .474L7.5 12a.667.667 0 01-.967.04z" clip-rule="evenodd"></path>
    </g>
  </svg>
);

export const Play = ({ fill1, height }) => (
  <svg
    viewBox="0 0 32 32"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height={height}
  >
    <g>
    <path fill={fill1} fill-rule="evenodd" d="M8 6.422v19.156c.076.95.659 1.785 1.522 2.182a2.616 2.616 0 002.64-.267l13.8-9.518a2.397 2.397 0 000-3.95l-13.8-9.518a2.616 2.616 0 00-2.64-.267A2.634 2.634 0 008 6.422z" clip-rule="evenodd"></path>
    </g>
  </svg>
);

export const BitcoinIcon = ({ height, fill1, fill2, fill3 }) => (
  <svg
  viewBox="0 0 2499.6 2499.9"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height={height}
  >
    <g>
    <path fill={fill1} d="M1249.7,0C559.4,0-0.2,559.6,0,1249.9c-0.1,690.3,559.5,1250,1249.5,1250c690.5-0.1,1250.1-559.7,1250.1-1249.9l0,0
		C2499.6,559.7,1940,0,1249.7,0z M1795.8,1485.8c-20.6,223.2-187.1,283.1-424.7,296.7v234.4h-141.3l0.1-231.3
		c-36.7,0-74.2-0.3-112.9-1.1v232.4H975.8l-0.1-234.9c-33-0.2-66.7-0.8-100.9-0.8h-184l28.1-168.8c0,0,104.5,1.6,102.7,0
		c40-0.1,50.6-28.8,53.1-46.8l0.1-370.7h14.9c-5.8-0.8-10.9-1-14.9-1V929.3c-5.4-28.7-24-61.9-81.3-61.9
		c1.8-1.9-102.6,0.1-102.6,0.1v-151l194.9,0.1v0.7c29.3,0,59.4-0.6,90-1.2l0.1-232.1h141.3v227.6c37.7-0.8,75.7-1.5,112.8-1.5V484
		h141.3v232.3c182.6,16,327.1,72.1,343.2,239.5l0,0c12,122.5-39.3,196-121.4,237.6C1728,1225.8,1812.5,1306.1,1795.8,1485.8z"/>
	<path fill={fill2} d="M1120.3,1302.3v311.3c95.9,0,396.4,19.9,396.4-155.6C1516.7,1275,1216.2,1302.5,1120.3,1302.3z"/>
	<path fill={fill3} d="M1450.9,1018.9c0.1-166.5-250.7-141.1-330.6-141.1V1160C1200.1,1159.9,1450.9,1178.6,1450.9,1018.9z"/>
    </g>
  </svg>
);

export const Bitcoin2Icon = ({ height, fill1, fill2, fill3 }) => (
	<svg
	viewBox="0 0 2499.6 2499.9"
	  preserveAspectRatio="xMidYMid meet"
	  focusable="false"
	  height={height}
	>
	  <g>
	  <path fill={fill1} d="M1249.7,0C559.4,0-0.2,559.6,0,1249.9c-0.1,690.3,559.5,1250,1249.5,1250c690.5-0.1,1250.1-559.7,1250.1-1249.9l0,0
	C2499.6,559.7,1940,0,1249.7,0z"/>
		<path fill={fill2} class="st0" d="M1795.8,1485.8c-20.6,223.2-187.1,283.1-424.7,296.7v234.4h-141.3l0.1-231.3c-36.7,0-74.2-0.3-112.9-1.1v232.4
	H975.8l-0.1-234.9c-33-0.2-66.7-0.8-100.9-0.8h-184l28.1-168.8c0,0,104.5,1.6,102.7,0c40-0.1,50.6-28.8,53.1-46.8l0.1-370.7h14.9
	c-5.8-0.8-10.9-1-14.9-1V929.3c-5.4-28.7-24-61.9-81.3-61.9c1.8-1.9-102.6,0.1-102.6,0.1v-151l194.9,0.1v0.7c29.3,0,59.4-0.6,90-1.2
	l0.1-232.1h141.3v227.6c37.7-0.8,75.7-1.5,112.8-1.5V484h141.3v232.3c182.6,16,327.1,72.1,343.2,239.5l0,0
	c12,122.5-39.3,196-121.4,237.6C1728,1225.8,1812.5,1306.1,1795.8,1485.8z"/>
		<path fill={fill3} class="st1" d="M1516.7,1458c0,175.5-300.5,155.6-396.4,155.6v-311.3C1216.2,1302.5,1516.7,1275,1516.7,1458z M1450.9,1018.9
	c0.1-166.5-250.7-141.1-330.6-141.1V1160C1200.1,1159.9,1450.9,1178.6,1450.9,1018.9z"/>
	  </g>
	</svg>
  );

export const Nodes = ({ height, fill1, fill2 }) => (
  <svg
    viewBox="0 0 960 912.6"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height={height}
  >
    <g>
    <path fill={fill1} class="st0" d="M526.7,252.4c-74.6,0-135.3,60.8-135.3,135.3c0,74.6,60.8,135.3,135.3,135.3c74.6,0,135.3-60.7,135.3-135.3
	C662,313.2,601.3,252.4,526.7,252.4z M526.7,278.1c60.7,0,109.7,49,109.7,109.7c0,60.7-49,109.7-109.7,109.7
	c-60.7,0-109.7-49-109.7-109.7C417,327,466,278.1,526.7,278.1z M956.2,88.9c0,48.9-40,88.9-88.9,88.9c-49,0-88.9-40-88.9-88.9
	C778.4,40,818.3,0,867.3,0C916.2,0,956.2,40,956.2,88.9z M257.2,112.7c0,48.9-40,88.9-88.9,88.9c-48.9,0-88.9-40-88.9-88.9
	c0-48.9,40-88.9,88.9-88.9C217.2,23.9,257.2,63.8,257.2,112.7z M177.6,535.8c0,49-39.8,88.8-88.8,88.8C39.7,624.5,0,584.7,0,535.8
	c0-49.1,39.7-88.9,88.8-88.9C137.8,446.9,177.6,486.7,177.6,535.8z M960,595.3c0,49.1-39.7,88.9-88.8,88.9
	c-49.1,0-88.8-39.8-88.8-88.9c0-49.1,39.7-88.9,88.8-88.9C920.3,506.4,960,546.2,960,595.3z M451.8,823.7c0,48.9-40,88.9-88.9,88.9
	c-48.9,0-88.9-40-88.9-88.9c0-48.9,40-88.9,88.9-88.9C411.8,734.7,451.8,774.8,451.8,823.7z"/>
<path fill={fill2} class="st1" d="M768.7,172.5l4.1,1.6l2.6,3.5l0.3,4.4l-2.2,3.9l-22.2,21.4l-1.7,1.2l-4.2,1l-4.1-1.6l-2.6-3.5l-0.3-4.4l2.2-3.7
	l22.3-21.4l1.6-1.2L768.7,172.5L768.7,172.5z M277.7,188.5l-4.3,1l-3,3.2l-0.8,4.2l1.6,4.1l1.5,1.4l14,10.3l4.1,1.5l4.2-1l3.1-3.2
	l0.8-4.3l-1.6-4.1l-1.4-1.4l-14.1-10.3L277.7,188.5z M724.2,215.3l-4.3,1l-1.6,1.2L696,238.9l-2.2,3.7l0.2,4.4l2.7,3.6l4.1,1.5
	l4.3-0.9l1.7-1.2l22.2-21.4l2.1-3.9l-0.2-4.4l-2.6-3.5L724.2,215.3L724.2,215.3z M316.6,217l-4.3,1l-3.1,3.1l-0.8,4.4l1.7,4.1
	l1.5,1.4l24.8,18.3l4.2,1.5l4.2-1l3.1-3.1l0.8-4.3l-1.6-4.1l-1.4-1.4l-24.9-18.3L316.6,217L316.6,217z M366.4,253.6l-4.3,1l-3.1,3.1
	l-0.8,4.3l1.7,4.1l1.5,1.4l24.8,18.3l4.1,1.5l4.2-1l3.1-3.1l0.8-4.3l-1.6-4.1l-1.4-1.4l-24.9-18.2L366.4,253.6L366.4,253.6z
	 M679.6,258.2l-4.3,0.9l-1.7,1.2l-22.2,21.4l-2.1,3.8l0.2,4.4l2.6,3.6l4.1,1.5l4.3-0.9l1.6-1.2l22.3-21.4l2.2-3.8l-0.2-4.4l-2.7-3.5
	L679.6,258.2L679.6,258.2z M366.4,444.6l-2,0.3l-23.5,7.6l-3.6,2.4l-1.7,4.1l0.9,4.4l2.9,3.1l4.4,1l2-0.3l23.4-7.6l3.6-2.4l1.7-4.1
	l-0.8-4.3l-3-3.1L366.4,444.6L366.4,444.6z M313.6,461.7l-2,0.3l-29.4,9.5l-3.6,2.5l-1.7,4l0.8,4.3l3,3.2l4.2,1l2-0.3l29.3-9.5
	l3.7-2.5l1.6-4l-0.7-4.3l-3-3.2L313.6,461.7L313.6,461.7z M677.2,462.8l-4.3,1.2l-2.9,3.3l-0.6,4.4l1.8,4l1.5,1.3l25.8,17.1l4.2,1.3
	l4.2-1.2l2.9-3.3l0.7-4.4l-1.8-3.9l-1.6-1.3L681.3,464L677.2,462.8z M254.9,480.8l-2,0.3l-29.5,9.5l-3.6,2.5l-1.6,4l0.7,4.4l3.1,3.2
	l4.2,1.1l2-0.4l29.3-9.5l3.7-2.5l1.7-4l-0.8-4.4l-3.1-3.1L254.9,480.8L254.9,480.8z M728.6,497l-4.1,1.2l-2.9,3.3l-0.5,4.3l1.8,4
	l1.6,1.4l25.7,17l4.2,1.4l4.2-1.2l2.9-3.3l0.6-4.4l-1.8-3.9l-1.5-1.3l-25.8-17.1L728.6,497L728.6,497z M468.8,532.9l-4.4,0.3
	l-3.5,2.7l-1,1.8l-11.3,28.7l-0.4,4.4l2.1,3.8l3.8,2.2l4.4-0.3l3.5-2.7l1-1.8l11.4-28.7l0.3-4.4l-2-3.8L468.8,532.9L468.8,532.9z
	 M446.2,590.4l-4.4,0.3l-3.5,2.7l-1,1.8L426,623.9l-0.4,4.4l2,3.9l3.8,2.1l4.4-0.3l3.5-2.6l1-1.8l11.3-28.7l0.4-4.4l-2-3.8
	L446.2,590.4L446.2,590.4z M423.6,647.9l-4.4,0.2l-3.5,2.7l-1,1.8l-11.3,28.7l-0.4,4.4l2,3.9l3.9,2.2l4.4-0.3l3.5-2.7l1-1.8
	l11.4-28.8l0.3-4.4l-2-3.8L423.6,647.9L423.6,647.9z M588.2,411.8c-2.2,23.3-19.6,29.6-44.4,31v24.5H529l0-24.2
	c-3.8,0-7.8,0-11.8-0.1v24.3h-14.8l0-24.5c-3.4,0-7-0.1-10.5-0.1h-19.2l2.9-17.6c0,0,10.9,0.2,10.7,0c4.2,0,5.3-3,5.5-4.9l0-38.7
	h1.6c-0.6-0.1-1.1-0.1-1.6-0.1v-27.7c-0.6-3-2.5-6.5-8.5-6.5c0.2-0.2-10.7,0-10.7,0v-15.8l20.4,0v0.1c3.1,0,6.2-0.1,9.4-0.1l0-24.3
	h14.8v23.8c3.9-0.1,7.9-0.2,11.8-0.2v-23.6h14.8v24.3c19.1,1.7,34.2,7.5,35.9,25v0c1.3,12.8-4.1,20.5-12.7,24.8
	C581.1,384.6,589.9,393,588.2,411.8z M517.6,377.8c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0
	c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0
	c1.3,0,2.9,0,4.6,0c0,0,0,0,0,0c11,0,28.4-1.2,28.4-14.8c0-13.4-15.5-14.9-26.4-14.9c0,0,0,0,0,0c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0
	c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0c-1.7,0-3.2,0.1-4.6,0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.4,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.3,0-0.4,0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.4,0V377.8z
	 M559,408.9c0-19.1-31.4-16.2-41.4-16.3v32.5C527.6,425.2,559,427.2,559,408.9z"/>
    </g>
  </svg>
);

export const Paws = ({ height }) => (
  <svg
    viewBox="0 0 944.2 513.2"
    preserveAspectRatio="xMidYMid meet"
    focusable="false"
    height={height}
    fill="#fff"
  >
    <g>
    <path d="M839,168.1c-0.9,0.3-1.9,0.4-2.8,0.4c-1.9,0-3.9-0.6-5.8-1.6c-3.9-2.2-6.7-6.5-7.9-11.9c-2.3-10,0.1-17.8,5.8-19.5
	c5.8-1.7,12.5,3.6,16.5,12.9c2.2,5.3,2.4,10.3,0.6,14.2C844,165.4,841.8,167.3,839,168.1z M848.8,139.1c2.3,2.1,5.1,3.2,7.9,3.2
	c0.3,0,0.6,0,0.9,0c3.2-0.3,6-1.9,8.1-4.5c2.9-3.7,3.8-9,2.8-15c-1.9-10.7-7.8-17.8-14.3-17.3c-6.5,0.5-10.8,8.3-10.8,19.4
	C843.4,130.8,845.3,135.9,848.8,139.1z M918,135.5c-5.8-1.7-12.5,3.6-16.5,12.9c-2.2,5.3-2.4,10.3-0.6,14.2c1.3,2.7,3.6,4.7,6.4,5.5
	c0.9,0.3,1.9,0.4,2.8,0.4c1.9,0,3.9-0.6,5.8-1.6c3.9-2.2,6.7-6.5,7.9-11.9C926.1,145.1,923.7,137.2,918,135.5z M888.7,142.3
	c0.3,0,0.6,0,0.9,0c2.8,0,5.6-1.1,7.9-3.2c3.5-3.2,5.5-8.3,5.5-14.2c0-11-4.4-18.8-10.8-19.4c-6.5-0.5-12.4,6.6-14.3,17.3
	c-1.1,6-0.1,11.4,2.8,15C882.6,140.4,885.5,142,888.7,142.3z M897.6,171.1c-7.7-5.6-10.8-14.1-10.8-14.2l0,0
	c-4.3-10.4-12.1-10.9-13.6-10.8c-1.5,0-9.3,0.4-13.6,10.8c0,0.1-3.7,9.2-10.8,14.2c-5.8,4-10.8,11.7-7.7,21.1c0.1,0.3,1.1,3.5,4,6.3
	c5,4.7,11.6,4.2,16.3,3l0,0c0,0,4.6-1.4,8.6-2.4c2.2-0.5,4.5-0.5,6.7,0c4,1,8.5,2.4,8.6,2.4l0,0c2.3,0.6,4.3,0.9,6.1,0.9
	c4.9,0,8.2-1.9,10.2-3.8c2.9-2.7,3.9-6,4-6.3C909.2,180.7,900.4,173.2,897.6,171.1z M821.5,211c1.5,2.8,2.5,4.6,3.1,5.7
	c-0.9,8.8-7.4,70.5-11.8,142.8h6.1c4.6-74.9,11.3-138.3,11.8-143c0.1-0.7,0-1.4-0.3-2.1c-0.1-0.1-0.1-0.2-0.2-0.3
	c-1.2-2-22.2-37.5-21.8-70.6c0.1-8.8,2.5-15,6.9-18c4.1-2.8,9.4-2.4,12.9-1.1c1.7,0.6,3.6,0.4,5.1-0.8c1.4-1.1,2.2-2.9,2-4.7
	c-2.6-22.9,10.4-28.2,14.5-29.3c9.1-2.4,16,2.2,19.4,7.3c1,1.4,2.6,2.3,4.3,2.3c1.7,0,3.3-0.8,4.3-2.2c3.3-4.7,9.4-9.7,19.5-7.4
	c2.9,0.7,17.1,5.2,14.4,29.3c-0.2,1.8,0.5,3.6,2,4.7c1.4,1.1,3.3,1.4,5.1,0.8c3.5-1.3,8.8-1.7,12.9,1.1c4.4,3,6.7,9.2,6.9,18
	c0.4,33.1-20.6,68.6-21.8,70.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.6-0.5,1.3-0.3,2c0.4,4.6,6.5,68,10.6,143h6.1
	c-4-72.4-9.8-134.1-10.6-142.8c0.7-1.1,1.6-2.9,3.1-5.7c2-3.7,4.9-9.7,7.9-16.9c5.3-12.8,11.5-31.9,11.2-50.6
	c-0.2-13.7-5.3-20-9.5-22.9c-5.5-3.8-12.2-3.7-17.1-2.2c2-21.7-9-32.2-19.2-34.6c-13.5-3.1-21.6,4.5-25,8.8
	c-4.8-6.5-13.7-11.9-25.3-8.8c-3.1,0.8-9,3.1-13.5,9.3c-4.6,6.3-6.5,14.8-5.5,25.2c-4.9-1.5-11.6-1.6-17.1,2.2
	c-4.2,2.9-9.3,9.2-9.5,22.9c-0.2,18.6,6,37.8,11.2,50.6C816.5,201.3,819.5,207.3,821.5,211z M821.5,211c1.5,2.8,2.5,4.6,3.1,5.7
	c-0.9,8.8-7.4,70.5-11.8,142.8h6.1c4.6-74.9,11.3-138.3,11.8-143c0.1-0.7,0-1.4-0.3-2.1c-0.1-0.1-0.1-0.2-0.2-0.3
	c-1.2-2-22.2-37.5-21.8-70.6c0.1-8.8,2.5-15,6.9-18c4.1-2.8,9.4-2.4,12.9-1.1c1.7,0.6,3.6,0.4,5.1-0.8c1.4-1.1,2.2-2.9,2-4.7
	c-2.6-22.9,10.4-28.2,14.5-29.3c9.1-2.4,16,2.2,19.4,7.3c1,1.4,2.6,2.3,4.3,2.3c1.7,0,3.3-0.8,4.3-2.2c3.3-4.7,9.4-9.7,19.5-7.4
	c2.9,0.7,17.1,5.2,14.4,29.3c-0.2,1.8,0.5,3.6,2,4.7c1.4,1.1,3.3,1.4,5.1,0.8c3.5-1.3,8.8-1.7,12.9,1.1c4.4,3,6.7,9.2,6.9,18
	c0.4,33.1-20.6,68.6-21.8,70.6c-0.1,0.1-0.1,0.2-0.2,0.3c-0.4,0.6-0.5,1.3-0.3,2c0.4,4.6,6.5,68,10.6,143h6.1
	c-4-72.4-9.8-134.1-10.6-142.8c0.7-1.1,1.6-2.9,3.1-5.7c2-3.7,4.9-9.7,7.9-16.9c5.3-12.8,11.5-31.9,11.2-50.6
	c-0.2-13.7-5.3-20-9.5-22.9c-5.5-3.8-12.2-3.7-17.1-2.2c2-21.7-9-32.2-19.2-34.6c-13.5-3.1-21.6,4.5-25,8.8
	c-4.8-6.5-13.7-11.9-25.3-8.8c-3.1,0.8-9,3.1-13.5,9.3c-4.6,6.3-6.5,14.8-5.5,25.2c-4.9-1.5-11.6-1.6-17.1,2.2
	c-4.2,2.9-9.3,9.2-9.5,22.9c-0.2,18.6,6,37.8,11.2,50.6C816.5,201.3,819.5,207.3,821.5,211z M726,128c2-3.7,4.9-9.7,7.9-16.9
	c5.3-12.8,11.5-31.9,11.2-50.6c-0.2-13.7-5.3-20-9.5-22.9c-5.5-3.8-12.2-3.7-17.1-2.2c2-21.7-9-32.2-19.2-34.6
	c-13.5-3.1-21.6,4.5-25,8.8c-4.8-6.5-13.7-11.9-25.3-8.8c-3.1,0.8-9,3.1-13.5,9.3c-4.6,6.3-6.5,14.8-5.5,25.2
	c-4.9-1.5-11.6-1.6-17.1,2.2c-4.2,2.9-9.3,9.2-9.5,22.9c-0.2,18.6,6,37.8,11.2,50.6c3,7.2,5.9,13.2,7.9,16.9
	c1.5,2.8,2.5,4.6,3.1,5.7c-1.3,12-13,123.8-15.5,225.8h126.7c-2.5-102.1-12.9-213.8-14-225.8C723.5,132.6,724.5,130.8,726,128z
	 M679.7,39.9c1.8-9.8,6.9-16.5,12.6-16.5c0.2,0,0.4,0,0.6,0c5.9,0.5,9.9,7.9,9.9,18.4c0,5.7-1.8,10.5-5.1,13.5
	c-2.3,2.1-5.2,3.2-8.1,2.9c-2.9-0.2-5.5-1.7-7.4-4.1C679.6,50.7,678.7,45.7,679.7,39.9z M655.2,23.5c5.9-0.5,11.4,6.3,13.2,16.4
	c1,5.7,0.1,10.8-2.6,14.2c-1.9,2.4-4.5,3.9-7.4,4.1c-2.8,0.2-5.7-0.8-8.1-2.9c-3.3-3-5.1-7.8-5.1-13.5
	C645.3,31.4,649.3,24,655.2,23.5z M631.8,83.1c-3.6-2.1-6.3-6.1-7.4-11.2c-2.1-9.4-0.1-16.8,5.1-18.4c0.6-0.2,1.3-0.3,1.9-0.3
	c4.8,0,10,4.9,13.3,12.6c2.1,5,2.3,9.7,0.6,13.3v0c-1.2,2.5-3.2,4.3-5.8,5C637.1,84.9,634.3,84.5,631.8,83.1z M705.3,109
	c0,0.1-1,3.2-3.7,5.8c-3.7,3.5-9,4.4-15.3,2.7c0,0-4.6-1.4-8.6-2.4c-1.2-0.3-2.4-0.4-3.6-0.4c-1.2,0-2.4,0.1-3.6,0.4
	c-4,1-8.6,2.4-8.6,2.4c-4.4,1.2-10.6,1.7-15.3-2.7c-2.7-2.6-3.7-5.7-3.7-5.9c-2.9-8.9,1.9-16.1,7.4-20c7.4-5.2,11.1-14.6,11.2-14.7
	c4.1-9.9,11.3-10.3,12.7-10.2c1.4,0,8.6,0.3,12.7,10.2c0,0.1,3.3,8.9,11.2,14.7C700.6,90.9,708.9,98,705.3,109z M716.3,83.1
	c-2.5,1.5-5.3,1.9-7.8,1.1c-2.5-0.7-4.6-2.5-5.8-5c-1.7-3.6-1.5-8.3,0.6-13.3c3.3-7.8,8.5-12.6,13.3-12.6c0.7,0,1.3,0.1,1.9,0.3
	c5.2,1.5,7.3,8.9,5.1,18.4C722.6,77,720,81,716.3,83.1z M210.3,114.5c-5.3-12.8-11.5-31.9-11.2-50.6c0.2-13.7,5.3-20,9.5-22.9
	c5.5-3.8,12.2-3.7,17.1-2.2c-1-10.4,0.9-18.9,5.5-25.2c4.5-6.2,10.4-8.5,13.5-9.3c11.6-3.1,20.5,2.4,25.3,8.8
	c3.4-4.4,11.6-11.9,25-8.8c10.2,2.4,21.2,12.8,19.2,34.6c4.9-1.5,11.6-1.6,17.1,2.2c4.2,2.9,9.3,9.2,9.5,22.9
	c0.2,18.6-6,37.8-11.2,50.6c-3,7.2-5.9,13.2-7.9,16.9c-1.5,2.8-2.5,4.6-3.1,5.7c1.1,11.8,11.3,121.2,13.9,222.4h-6.1
	c-2.7-103.9-13.4-216.4-13.9-222.6c-0.1-0.7,0-1.4,0.3-2c0.1-0.1,0.1-0.2,0.2-0.3c1.2-2,22.2-37.5,21.8-70.6c-0.1-8.8-2.5-15-6.9-18
	c-4.1-2.8-9.4-2.4-12.9-1.1c-1.7,0.6-3.6,0.4-5.1-0.8c-1.4-1.1-2.2-2.9-2-4.7c2.8-24.1-11.5-28.6-14.4-29.3
	c-10-2.3-16.1,2.8-19.5,7.4c-1,1.4-2.6,2.2-4.3,2.2c-1.7,0-3.3-0.9-4.3-2.3c-3.4-5.1-10.3-9.7-19.4-7.3c-4.1,1.1-17.1,6.4-14.5,29.3
	c0.2,1.8-0.5,3.6-2,4.7c-1.4,1.1-3.3,1.4-5.1,0.8c-3.5-1.3-8.8-1.7-12.9,1.1c-4.4,3-6.7,9.2-6.9,18c-0.4,33.1,20.6,68.6,21.8,70.6
	c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.6,0.5,1.4,0.3,2.1c-0.7,6.2-12.7,118.7-15.4,222.5H206c2.6-101.1,14.1-210.5,15.4-222.4
	c-0.7-1.1-1.6-2.9-3.1-5.7C216.3,127.7,213.3,121.7,210.3,114.5z M210.3,114.5c-5.3-12.8-11.5-31.9-11.2-50.6
	c0.2-13.7,5.3-20,9.5-22.9c5.5-3.8,12.2-3.7,17.1-2.2c-1-10.4,0.9-18.9,5.5-25.2c4.5-6.2,10.4-8.5,13.5-9.3
	c11.6-3.1,20.5,2.4,25.3,8.8c3.4-4.4,11.6-11.9,25-8.8c10.2,2.4,21.2,12.8,19.2,34.6c4.9-1.5,11.6-1.6,17.1,2.2
	c4.2,2.9,9.3,9.2,9.5,22.9c0.2,18.6-6,37.8-11.2,50.6c-3,7.2-5.9,13.2-7.9,16.9c-1.5,2.8-2.5,4.6-3.1,5.7
	c1.1,11.8,11.3,121.2,13.9,222.4h-6.1c-2.7-103.9-13.4-216.4-13.9-222.6c-0.1-0.7,0-1.4,0.3-2c0.1-0.1,0.1-0.2,0.2-0.3
	c1.2-2,22.2-37.5,21.8-70.6c-0.1-8.8-2.5-15-6.9-18c-4.1-2.8-9.4-2.4-12.9-1.1c-1.7,0.6-3.6,0.4-5.1-0.8c-1.4-1.1-2.2-2.9-2-4.7
	c2.8-24.1-11.5-28.6-14.4-29.3c-10-2.3-16.1,2.8-19.5,7.4c-1,1.4-2.6,2.2-4.3,2.2c-1.7,0-3.3-0.9-4.3-2.3
	c-3.4-5.1-10.3-9.7-19.4-7.3c-4.1,1.1-17.1,6.4-14.5,29.3c0.2,1.8-0.5,3.6-2,4.7c-1.4,1.1-3.3,1.4-5.1,0.8
	c-3.5-1.3-8.8-1.7-12.9,1.1c-4.4,3-6.7,9.2-6.9,18c-0.4,33.1,20.6,68.6,21.8,70.6c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.6,0.5,1.4,0.3,2.1
	c-0.7,6.2-12.7,118.7-15.4,222.5H206c2.6-101.1,14.1-210.5,15.4-222.4c-0.7-1.1-1.6-2.9-3.1-5.7
	C216.3,127.7,213.3,121.7,210.3,114.5z M210.3,114.5c-5.3-12.8-11.5-31.9-11.2-50.6c0.2-13.7,5.3-20,9.5-22.9
	c5.5-3.8,12.2-3.7,17.1-2.2c-1-10.4,0.9-18.9,5.5-25.2c4.5-6.2,10.4-8.5,13.5-9.3c11.6-3.1,20.5,2.4,25.3,8.8
	c3.4-4.4,11.6-11.9,25-8.8c10.2,2.4,21.2,12.8,19.2,34.6c4.9-1.5,11.6-1.6,17.1,2.2c4.2,2.9,9.3,9.2,9.5,22.9
	c0.2,18.6-6,37.8-11.2,50.6c-3,7.2-5.9,13.2-7.9,16.9c-1.5,2.8-2.5,4.6-3.1,5.7c1.1,11.8,11.3,121.2,13.9,222.4h-6.1
	c-2.7-103.9-13.4-216.4-13.9-222.6c-0.1-0.7,0-1.4,0.3-2c0.1-0.1,0.1-0.2,0.2-0.3c1.2-2,22.2-37.5,21.8-70.6c-0.1-8.8-2.5-15-6.9-18
	c-4.1-2.8-9.4-2.4-12.9-1.1c-1.7,0.6-3.6,0.4-5.1-0.8c-1.4-1.1-2.2-2.9-2-4.7c2.8-24.1-11.5-28.6-14.4-29.3
	c-10-2.3-16.1,2.8-19.5,7.4c-1,1.4-2.6,2.2-4.3,2.2c-1.7,0-3.3-0.9-4.3-2.3c-3.4-5.1-10.3-9.7-19.4-7.3c-4.1,1.1-17.1,6.4-14.5,29.3
	c0.2,1.8-0.5,3.6-2,4.7c-1.4,1.1-3.3,1.4-5.1,0.8c-3.5-1.3-8.8-1.7-12.9,1.1c-4.4,3-6.7,9.2-6.9,18c-0.4,33.1,20.6,68.6,21.8,70.6
	c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0.6,0.5,1.4,0.3,2.1c-0.7,6.2-12.7,118.7-15.4,222.5H206c2.6-101.1,14.1-210.5,15.4-222.4
	c-0.7-1.1-1.6-2.9-3.1-5.7C216.3,127.7,213.3,121.7,210.3,114.5z M36.7,118.1c-0.9,0.3-1.9,0.4-2.8,0.4c-1.9,0-3.9-0.6-5.8-1.6
	c-3.9-2.2-6.7-6.5-7.9-11.9c-2.3-10,0.1-17.8,5.8-19.5c5.8-1.7,12.5,3.6,16.5,12.9c2.2,5.3,2.4,10.3,0.6,14.2
	C41.8,115.4,39.5,117.3,36.7,118.1z M46.5,89.1c2.3,2.1,5.1,3.2,7.9,3.2c0.3,0,0.6,0,0.9,0c3.2-0.3,6-1.9,8.1-4.5
	c2.9-3.7,3.8-9,2.8-15C64.3,62,58.4,54.9,51.9,55.5c-6.5,0.5-10.8,8.3-10.8,19.4C41.1,80.8,43,85.9,46.5,89.1z M115.7,85.5
	c-5.8-1.7-12.5,3.6-16.5,12.9c-2.2,5.3-2.4,10.3-0.6,14.2c1.3,2.7,3.6,4.7,6.4,5.5c0.9,0.3,1.9,0.4,2.8,0.4c1.9,0,3.9-0.6,5.8-1.6
	c3.9-2.2,6.7-6.5,7.9-11.9C123.8,95.1,121.5,87.2,115.7,85.5z M86.4,92.3c0.3,0,0.6,0,0.9,0c2.8,0,5.6-1.1,7.9-3.2
	c3.5-3.2,5.5-8.3,5.5-14.2c0-11-4.4-18.8-10.8-19.4c-6.5-0.5-12.4,6.6-14.3,17.3c-1.1,6-0.1,11.4,2.8,15
	C80.4,90.4,83.2,92,86.4,92.3z M95.3,121.2c-7.7-5.6-10.8-14.1-10.8-14.2l0,0C80.2,96.5,72.4,96,70.9,96.1c-1.5,0-9.3,0.4-13.6,10.8
	c0,0.1-3.7,9.2-10.8,14.2c-5.8,4-10.8,11.7-7.7,21.1c0.1,0.3,1.1,3.5,4,6.3c5,4.7,11.6,4.2,16.3,3l0,0c0,0,4.6-1.4,8.6-2.4
	c2.2-0.5,4.5-0.5,6.7,0c4,1,8.5,2.4,8.6,2.4l0,0c2.3,0.6,4.3,0.9,6.1,0.9c4.9,0,8.2-1.9,10.2-3.8c2.9-2.7,3.9-6,4-6.3
	C106.9,130.7,98.1,123.2,95.3,121.2z M19.2,161c1.5,2.8,2.5,4.6,3.1,5.7c-1.2,10.8-10.6,101-14.4,192.8H14
	c3.9-94.5,13.8-187.3,14.4-193c0.1-0.7,0-1.4-0.3-2.1c-0.1-0.1-0.1-0.2-0.2-0.3c-1.2-2-22.2-37.5-21.8-70.6c0.1-8.8,2.5-15,6.9-18
	c4.1-2.8,9.4-2.4,12.9-1.1c1.7,0.6,3.6,0.4,5.1-0.8c1.4-1.1,2.2-2.9,2-4.7c-2.6-22.9,10.4-28.2,14.5-29.3c9.1-2.4,16,2.2,19.4,7.3
	c1,1.4,2.6,2.3,4.3,2.3c1.7,0,3.3-0.8,4.3-2.2c3.3-4.7,9.4-9.7,19.5-7.4c2.9,0.7,17.1,5.2,14.4,29.3c-0.2,1.8,0.5,3.6,2,4.7
	c1.4,1.1,3.3,1.4,5.1,0.8c3.5-1.3,8.8-1.7,12.9,1.1c4.4,3,6.7,9.2,6.9,18c0.4,33.1-20.6,68.6-21.8,70.6c-0.1,0.1-0.1,0.2-0.2,0.3
	c-0.4,0.6-0.5,1.4-0.3,2c0.5,5.6,9.3,98.4,13,193h6.1c-3.5-91.9-11.9-182.1-12.9-192.8c0.7-1.1,1.6-2.9,3.1-5.7
	c2-3.7,4.9-9.7,7.9-16.9c5.3-12.8,11.5-31.9,11.2-50.6c-0.2-13.7-5.3-20-9.5-22.9c-5.5-3.8-12.2-3.7-17.1-2.2
	c2-21.7-9-32.2-19.2-34.5c-13.5-3.1-21.6,4.5-25,8.8c-4.8-6.5-13.7-11.9-25.3-8.8c-3.1,0.8-9,3.1-13.5,9.3
	c-4.6,6.3-6.5,14.8-5.5,25.2c-4.9-1.5-11.6-1.6-17.1,2.2C5.3,73.5,0.2,79.8,0,93.5c-0.2,18.6,6,37.8,11.2,50.6
	C14.2,151.3,17.2,157.3,19.2,161z M19.2,161c1.5,2.8,2.5,4.6,3.1,5.7c-1.2,10.8-10.6,101-14.4,192.8H14
	c3.9-94.5,13.8-187.3,14.4-193c0.1-0.7,0-1.4-0.3-2.1c-0.1-0.1-0.1-0.2-0.2-0.3c-1.2-2-22.2-37.5-21.8-70.6c0.1-8.8,2.5-15,6.9-18
	c4.1-2.8,9.4-2.4,12.9-1.1c1.7,0.6,3.6,0.4,5.1-0.8c1.4-1.1,2.2-2.9,2-4.7c-2.6-22.9,10.4-28.2,14.5-29.3c9.1-2.4,16,2.2,19.4,7.3
	c1,1.4,2.6,2.3,4.3,2.3c1.7,0,3.3-0.8,4.3-2.2c3.3-4.7,9.4-9.7,19.5-7.4c2.9,0.7,17.1,5.2,14.4,29.3c-0.2,1.8,0.5,3.6,2,4.7
	c1.4,1.1,3.3,1.4,5.1,0.8c3.5-1.3,8.8-1.7,12.9,1.1c4.4,3,6.7,9.2,6.9,18c0.4,33.1-20.6,68.6-21.8,70.6c-0.1,0.1-0.1,0.2-0.2,0.3
	c-0.4,0.6-0.5,1.4-0.3,2c0.5,5.6,9.3,98.4,13,193h6.1c-3.5-91.9-11.9-182.1-12.9-192.8c0.7-1.1,1.6-2.9,3.1-5.7
	c2-3.7,4.9-9.7,7.9-16.9c5.3-12.8,11.5-31.9,11.2-50.6c-0.2-13.7-5.3-20-9.5-22.9c-5.5-3.8-12.2-3.7-17.1-2.2
	c2-21.7-9-32.2-19.2-34.5c-13.5-3.1-21.6,4.5-25,8.8c-4.8-6.5-13.7-11.9-25.3-8.8c-3.1,0.8-9,3.1-13.5,9.3
	c-4.6,6.3-6.5,14.8-5.5,25.2c-4.9-1.5-11.6-1.6-17.1,2.2C5.3,73.5,0.2,79.8,0,93.5c-0.2,18.6,6,37.8,11.2,50.6
	C14.2,151.3,17.2,157.3,19.2,161z M85.6,227.9c9.9,1.3,22.1-5.8,20.9-16.9c-1.1-10.6-9.9-14-19.9-15.5c-8.6-0.9-16.2,2.4-17.6,9.7
	C66.8,215.9,75,226.5,85.6,227.9z M41.5,242.5c-0.3-6,0.9-10.8,3.6-16.1c4.2-8.2,7.2-15.9,3.1-24.8c-4.2-9.1-12.8-14.3-21.5-18.6
	c-2,20.2-5.4,57-8.4,100c9.7-6.1,17.6-15.6,21.5-25.9C41.7,252.3,41.8,247.6,41.5,242.5z M118.7,227.6
	c-15.6,3.5-31.9,11.9-40.9,25.1c-10.6,15.6,1.3,32.9,14.8,41.6c8.8,5.7,20.4,11.1,31.3,11.1C122.3,276.7,120.4,249.9,118.7,227.6z
	 M76.7,307.5c-0.6-7.1-2.3-14.9-8.5-19.2c-3.8-2.7-9.3-3.6-13.5-1.9c-4.1,1.7-7.9,3.9-11.3,6.7c-8.4,6.9-13.9,18.4-10.8,29.2
	c2,7,7,12.7,12.9,16.7c5.4,3.7,14.7,9,21.1,4.8c11.2-7.4,11.1-24.3,10.2-36.1C76.7,307.7,76.7,307.6,76.7,307.5z M216.4,258.1
	c-0.1,2-0.3,4-0.4,6.1c35.7,1.5,71.3-1.2,106.2-8c-0.1-2.1-0.2-4.1-0.4-6.1C287.2,256.8,251.8,259.5,216.4,258.1z M217.9,272.1
	c-0.8-0.1-1.6-0.2-2.4-0.3c-0.4,6.7-0.8,13.4-1.2,20.3c0.5,0.1,1,0.1,1.5,0.2c16.6,1.6,33.3,2.4,50,2.4c19.5,0,38.9-1.1,58.3-3.3
	c-0.3-6.8-0.7-13.6-1.1-20.3C288.2,275.1,252.9,275.5,217.9,272.1z M213.9,301.2c-0.2,5-0.5,10.1-0.7,15.2c18,1,36.1,1.5,54.2,1.5
	c19.3,0,38.7-0.6,57.9-1.7c-0.2-5.1-0.4-10.1-0.7-15.2C287.8,303.1,250.7,303.2,213.9,301.2z M219.6,213.8c-0.4,4.9-0.8,10-1.2,15.2
	c31.1,5.4,62.5,8.1,94,8.1c2.9,0,5.8,0,8.6-0.1c-0.3-5.2-0.7-10.2-1-15.2C286.4,222.3,252.8,219.6,219.6,213.8z M231.7,192.7
	c-3.4-0.2-6.8-0.6-10.2-1.1c-0.3,3.3-0.6,6.6-0.8,10.1c3.4,0.5,6.9,0.8,10.4,1.1c4.7,0.3,9.4,0.5,14.1,0.5c12.4,0,24.8-1,36.8-1.9
	c12.2-1,24.4-2,36.6-3c-0.3-3.5-0.5-6.8-0.8-10.1c-12.2,1-24.4,2-36.6,3C264.8,192.5,248.1,193.9,231.7,192.7z M223.8,165.1
	c-0.4,4.7-0.9,9.8-1.4,15.1c14.8,1.1,29.5,1.7,44.3,1.7c16.8,0,33.5-0.7,50.2-2.2c-0.4-5.4-0.8-10.5-1.2-15.1
	C285.2,167.3,254.4,167.5,223.8,165.1z M224.6,152.9c29.9-2.5,59.9-4.8,89.8-6.9c-0.2-2.2-0.4-4.2-0.5-6.1
	c-29.5,2-59.2,4.3-88.6,6.8C225,148.7,224.8,150.7,224.6,152.9z M325.5,316.8c-37.2,5.5-75,8.5-112.7,9c-0.1,2-0.1,4.1-0.2,6.1
	c37.9-0.4,75.8-3.4,113.2-8.9C325.6,320.9,325.5,318.8,325.5,316.8z M3.7,418.3c1-0.9,2.2-1.4,3.5-1.4c1.2,0,2.3,0.4,3.4,1.3
	c1.1,0.8,1.8,2.1,2.3,3.9v1.3c2.1-1.9,4.6-3.2,7.3-4.1c2.7-0.9,5.4-1.3,8-1.3c3.6,0,7,0.8,10.1,2.4c3.1,1.6,5.8,3.7,8.1,6.4
	c2.3,2.7,4.1,5.8,5.4,9.4c1.3,3.6,1.9,7.4,1.9,11.5c0,4.5-0.7,8.5-2.2,12.3c-1.4,3.7-3.6,6.9-6.3,9.6c-2.8,2.7-6.2,4.8-10.4,6.3
	c-4.1,1.5-8.9,2.3-14.4,2.3c-1.3,0-2.6,0-3.8-0.1c-1.3-0.1-2.5-0.2-3.8-0.4v29.4c-0.1,2.1-0.7,3.7-1.9,4.8c-1.2,1.1-2.4,1.6-3.8,1.6
	c-1.3,0-2.5-0.5-3.6-1.5c-1.1-1-1.8-2.5-2.1-4.5v-84.8C1.9,420.6,2.6,419.2,3.7,418.3z M14.2,438.2c-0.2,0.3-0.4,0.5-0.7,0.8
	c-0.3,0.2-0.5,0.4-0.7,0.6v26.3c1.3,0.3,2.6,0.5,3.8,0.7c1.3,0.2,2.5,0.3,3.8,0.3c3.2,0,6.1-0.4,8.8-1.3c2.7-0.8,5-2,7-3.6
	c2-1.6,3.6-3.5,4.7-5.8c1.1-2.3,1.7-5,1.7-7.9c0-2.2-0.3-4.5-1-6.8c-0.7-2.3-1.7-4.3-2.9-6.2c-1.3-1.9-2.7-3.4-4.4-4.5
	c-1.7-1.2-3.5-1.7-5.6-1.7c-1.6,0-3.1,0.3-4.5,0.8c-1.4,0.6-2.7,1.3-3.9,2.2c-1.2,0.9-2.3,1.9-3.3,2.9C16,436,15.1,437.1,14.2,438.2
	z M122.1,472.7c0,0.1,0,0.1-0.1,0.1c0.6,1.3,0.7,2.5,0.4,3.6c-0.3,1.1-0.8,2-1.6,2.7c-0.8,0.7-1.8,1.1-3.1,1.3
	c-1.3,0.2-2.6-0.1-4.1-0.8c-0.8-0.8-1.6-1.7-2.4-2.7c-0.7-1-1.4-2-1.9-3c-1.9,1.6-3.9,3-6,4.4c-2.1,1.3-4.3,2.1-6.4,2.2
	c-3.7,0.2-7.4,0.2-11,0.1c-3.6-0.1-7.1-1.3-10.3-3.6c-2.7-1.9-4.8-4.3-6.2-7c-1.4-2.7-2.4-5.5-2.8-8.6c-0.4-3-0.4-6.1,0.1-9.3
	c0.5-3.2,1.3-6.2,2.4-9c0.5-1.3,1.1-2.6,1.8-3.8c0.7-1.2,1.5-2.5,2.2-3.8c1-1.9,2.2-3.6,3.5-5.2c1.3-1.6,2.8-3,4.5-4.2
	c1.2-0.8,2.4-1.7,3.6-2.5c1.2-0.8,2.3-1.6,3.6-2.4c0.8-0.6,2.1-1.1,3.8-1.7c1.7-0.6,3.6-1.1,5.6-1.5c2-0.4,4-0.6,6-0.6
	c2,0,3.7,0.3,5.1,0.8c1.3-0.2,2.6,0.1,3.8,1c1.3,0.8,2.2,2.2,2.9,4c0,6.3,0,12.7-0.1,19.1c-0.1,6.4,0.3,12.8,1.3,19.1
	c0.1,0.8,0.4,1.8,1,3c0.6,1.2,1.3,2.3,1.9,3.4c0.7,1.1,1.3,2.1,1.9,3.1C121.9,471.8,122.1,472.4,122.1,472.7z M104.5,463.1
	c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c-1.2-5.6-1.8-11.3-1.7-17.3c0-6,0.1-11.5,0.2-16.5c-0.8,0-1.8,0.1-2.8,0.3
	c-1,0.2-2,0.4-3,0.8c-1,0.3-1.9,0.6-2.6,0.9c-0.8,0.3-1.4,0.5-1.7,0.7c-2.8,1.3-5.2,3-7.1,5.1c-1.9,2.1-3.6,4.4-4.8,7
	c-1.3,2.6-2.2,5.2-2.9,8.1c-0.7,2.8-1,5.7-1,8.6c0.2,0.7,0.4,1.5,0.7,2.2c0.3,0.7,0.5,1.5,0.7,2.2c0.5,0.5,1,1,1.5,1.5
	c0.5,0.6,1,1.1,1.6,1.5c0.8,0.4,1.7,0.7,2.5,1c0.8,0.3,1.7,0.6,2.5,0.8c1.6,0,3.1,0,4.6,0.1c1.5,0.1,3-0.1,4.5-0.6
	c1.4-0.9,2.8-1.9,4.1-2.8C101.8,465.3,103.2,464.2,104.5,463.1z M205.5,420.4c0.9-1.6,2-2.6,3.3-3.1c1.3-0.5,2.5-0.6,3.6-0.3
	c1.2,0.3,2.1,1.1,2.9,2.2c0.8,1.2,1.1,2.6,1,4.4c-2.7,9-5.5,18-8.3,27.1c-2.8,9-6.3,17.8-10.4,26.4c-1.4,1.7-2.9,2.6-4.6,2.6
	c-1.7,0.1-3.3-0.7-4.9-2.2c-2.6-5.2-5-10.4-7.2-15.7c-2.2-5.3-4.4-10.6-6.5-16c-0.4-0.7-0.7-1.5-1-2.3c-0.3-0.8-0.6-1.6-0.9-2.4
	c-1.2,3-2.4,5.9-3.5,8.7c-1.1,2.8-2.2,5.7-3.3,8.6s-2.3,5.9-3.5,9c-1.2,3.1-2.5,6.4-3.9,9.9c-1.7,2-3.5,2.9-5.6,2.6
	c-2-0.3-3.6-1.6-4.7-3.9c-2.1-5.1-4-10.2-5.6-15.4c-1.6-5.2-3.2-10.4-4.8-15.7c-1.1-3.5-2.1-7-3-10.5c-0.9-3.5-1.6-7-2.2-10.5
	c-0.3-2,0-3.7,1-4.9c0.9-1.3,2-2,3.3-2.3c1.3-0.3,2.6,0,4,0.8c1.3,0.8,2.3,2.2,2.9,4.1c1,6.2,2.4,12.4,4.1,18.7
	c1.7,6.2,3.6,12.4,5.8,18.5c1.2-3.1,2.3-5.9,3.4-8.6c1.1-2.7,2.1-5.4,3.2-8.1c1.1-2.7,2.2-5.6,3.5-8.6c1.3-3.1,2.6-6.5,4.1-10.3
	c0.7-1.3,1.6-2.2,2.7-2.8c1.1-0.6,2.1-0.8,3.2-0.6c1.1,0.1,2.1,0.6,3,1.4c0.9,0.8,1.6,1.9,2.1,3.4c0.8,3,1.8,5.9,3,8.7
	c1.2,2.8,2.3,5.6,3.4,8.4c1.2,3.2,2.4,6.3,3.7,9.4c1.3,3.1,2.5,6.2,3.8,9.3c2.6-6.5,4.9-13.1,6.9-19.8
	C201.5,433.7,203.5,427.1,205.5,420.4z M289.5,472c1.7,1.2,2.6,2.6,2.9,4.1c0.3,1.5,0.1,2.9-0.6,4c-0.7,1.1-1.8,1.9-3.2,2.4
	c-1.4,0.5-3.1,0.2-4.9-0.7c-0.8-0.6-1.7-1.1-2.5-1.7c-0.8-0.6-1.7-1.1-2.6-1.7c-0.8-0.7-1.4-1.4-1.7-2.1c-0.3-0.6-0.5-1.6-0.7-2.8
	v-77.3c0.4-1.8,1.1-3.1,2.2-4c1.1-0.9,2.2-1.4,3.3-1.5c1.2,0,2.3,0.3,3.4,1.2c1.1,0.8,1.9,2.1,2.5,3.9v74.9c0.3,0.2,0.6,0.4,0.9,0.6
	C288.8,471.6,289.1,471.8,289.5,472z M306.3,398.5c0-2,0.6-3.8,1.9-5.2c1.3-1.4,2.9-2.2,4.7-2.2c1.9,0,3.5,0.7,4.8,2.2
	c1.3,1.4,2,3.2,2,5.2c0,1.9-0.7,3.6-2,5.1c-1.3,1.4-2.9,2.2-4.8,2.2c-1.9,0-3.4-0.7-4.7-2.2C307,402.2,306.3,400.5,306.3,398.5z
	 M319.1,474.3c-0.1,2.1-0.7,3.7-1.9,4.8c-1.2,1.1-2.4,1.6-3.8,1.6c-1.3,0-2.5-0.5-3.6-1.5s-1.8-2.5-2.1-4.5
	c-0.1-8.7-0.2-17.6-0.2-26.7c0-9.1-0.1-18-0.2-26.7c0.5-1.9,1.2-3.2,2.2-4.2c1-0.9,2.2-1.4,3.5-1.4c1.2,0,2.3,0.4,3.4,1.3
	c1.1,0.8,1.8,2.1,2.3,3.9c0.1,8.7,0.2,17.6,0.2,26.7C319,456.6,319,465.5,319.1,474.3z M373.6,420.4c0.9-1.6,2-2.6,3.3-3.1
	c1.3-0.5,2.5-0.6,3.6-0.3c1.2,0.3,2.1,1.1,2.9,2.2c0.8,1.2,1.1,2.6,1,4.4c-1.3,5-2.8,9.9-4.5,14.7c-1.7,4.8-3.4,9.6-5.1,14.4
	c-1.6,4.5-3.1,8.8-4.7,13.2c-1.5,4.3-3.2,8.6-5.1,12.9c-1.7,2-3.5,2.9-5.6,2.6c-2-0.3-3.6-1.6-4.7-3.9c-3.3-8.8-6.8-17.5-10.2-26.2
	c-3.5-8.6-7.4-17.1-11.6-25.5c-0.9-1.9-1.2-3.5-0.7-4.9c0.5-1.4,1.3-2.5,2.4-3.2s2.4-0.9,4-0.6c1.5,0.3,2.9,1.3,4.1,2.9
	c3.2,6.5,6.3,13.1,9.2,19.8c2.9,6.7,5.7,13.5,8.4,20.3c0.6-1.8,1.3-3.5,1.9-5.3c0.6-1.8,1.2-3.5,1.9-5.3c1.7-4.8,3.3-9.6,5-14.4
	C370.6,430.3,372.2,425.4,373.6,420.4z M442.2,462.4c1.9-0.7,3.6-0.8,5-0.3c1.4,0.6,2.4,1.4,3,2.6c0.6,1.2,0.7,2.6,0.3,4
	c-0.4,1.5-1.5,2.7-3.3,3.8c-3.1,1.8-6.4,3.4-9.9,4.9c-3.5,1.5-7.1,2.5-10.7,3.1c-3.6,0.6-7.2,0.5-10.7-0.1c-3.5-0.6-6.9-2-10.2-4.2
	c-3.9-2.7-6.9-6.3-8.9-10.9c-2-4.6-3.1-9.4-3.2-14.3c-0.1-5,0.8-9.7,2.7-14.3c1.9-4.6,4.9-8.3,9-11.1c4.3-2.9,8.9-5,13.9-6.5
	c5-1.4,10.1-1.6,15.2-0.5c2.5,0.6,4.8,1.7,6.8,3.5c2,1.8,3.7,3.9,4.9,6.3c1.3,2.4,1.9,4.9,2,7.5c0.1,2.6-0.6,5.1-1.9,7.4
	c-1.6,2.6-3.9,4.8-6.8,6.6c-3,1.8-6.2,3.3-9.7,4.6c-3.5,1.3-7,2.3-10.4,3.1c-3.5,0.8-6.5,1.6-9.1,2.4c-1.1,0.3-2.2,0.3-3.2,0.1
	c1.4,3,3.5,5.4,6.2,7.3c2.7,1.9,6.1,2.7,10.2,2.4c3.2-0.2,6.5-1.1,9.8-2.7C436.5,465.6,439.5,464,442.2,462.4z M415.1,432.8
	c-3.4,1.8-6,4.2-7.7,7.4c-1.7,3.2-2.5,6.6-2.5,10.1c0.6-0.5,1.3-0.8,2.1-1.1c1.7-0.5,3.4-1,5.1-1.5c1.7-0.5,3.5-1,5.2-1.5
	c1.1-0.3,2.6-0.7,4.5-1.3c1.9-0.6,3.9-1.2,5.9-1.9c2-0.7,3.9-1.6,5.6-2.6c1.7-1,2.8-2.1,3.3-3.3c0.2-3.1-0.6-5.2-2.2-6.3
	c-1.7-1.1-3.7-1.7-6-1.7c-2.3,0-4.7,0.4-7.2,1.2C418.7,431.3,416.6,432,415.1,432.8z M460.9,468.5c-0.8-1.7-1.1-3.2-0.8-4.5
	c0.3-1.3,0.9-2.4,1.7-3.2c0.9-0.8,2-1.2,3.4-1.2c1.4,0,2.8,0.5,4.3,1.5c2.1,2.7,4.7,4.8,7.6,6.3c2.9,1.5,6.1,2.4,9.4,2.6l2.9-0.8
	c0.7-0.6,1.3-0.9,1.5-1.1c0.3-0.2,0.5-0.3,0.6-0.5c0.1-0.1,0.3-0.3,0.6-0.6c0.2-0.3,0.6-0.8,1.2-1.5c0.2-0.4,0.3-0.7,0.4-1.1
	c0.1-0.4,0.2-0.8,0.4-1.3c0-0.5,0-0.9,0.1-1.3c0-0.4,0.1-0.8,0.1-1.3l-0.8-2.5c-0.3-0.4-0.6-0.7-0.9-1.1c-0.3-0.4-0.6-0.7-0.9-1.1
	c-2.3-1.5-4.6-2.6-7-3.2c-2.3-0.6-4.6-1.3-6.8-2c-2.2-0.7-4.3-1.6-6.4-2.7c-2.1-1.1-4.1-2.9-5.9-5.4c-1.8-2.2-2.7-4.8-2.9-7.7
	c-0.1-2.9,0.3-5.7,1.4-8.4c1.1-2.7,2.6-5.1,4.7-7.2c2.1-2.1,4.5-3.4,7.2-4c3.9-0.9,7.9-1,11.9-0.3c4,0.7,7.8,2,11.3,3.9
	c1.7,1.2,2.6,2.6,2.9,4.1c0.3,1.5,0.1,2.9-0.6,4c-0.7,1.1-1.8,1.9-3.2,2.4c-1.4,0.5-3.1,0.2-4.9-0.7c-2.2-1.2-4.6-2-7-2.4
	c-2.5-0.4-4.9-0.4-7.4,0c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.2-0.9,0.4c-1,1.1-1.8,2.2-2.2,3.3c-0.5,1.1-0.7,2.4-0.6,4
	c0.1,0.4,0.3,0.9,0.7,1.7c0.3,0.4,0.6,0.7,0.8,1c0.3,0.3,0.6,0.6,0.8,1c2.2,1.2,4.7,2.2,7.3,2.9c2.6,0.7,5.2,1.5,7.7,2.4
	c2.5,0.9,4.8,2,7,3.5c2.1,1.4,3.8,3.6,5,6.3c2.2,5.3,2.6,10.2,1,14.6c-1.5,4.5-4.6,8.2-9.3,11.3c-1.2,0.7-2.6,1.4-4,1.9
	c-1.5,0.5-2.9,0.8-4.2,0.8c-5,0-9.9-1.1-14.8-3.4C467.7,475.8,463.8,472.6,460.9,468.5z M600.8,439c-0.1-0.3-0.1-0.6-0.1-1.1v-1.5
	c-1.1-2.9-2.9-5-5.3-6.3c-2.4-1.3-5.1-2-7.9-2.1c-2,0.7-4,1.6-5.8,2.9l-2.9,3.8c-0.3,0.4-0.6,0.7-0.8,0.9c-0.3,0.2-0.6,0.4-0.8,0.6
	v38.3c-0.1,2.1-0.7,3.7-1.9,4.8c-1.2,1.1-2.4,1.6-3.8,1.6c-1.3,0-2.5-0.5-3.6-1.5s-1.8-2.5-2.1-4.5v-53.9c0.5-1.9,1.2-3.2,2.2-4.1
	c1-0.9,2.2-1.3,3.5-1.3c1.1,0,2.2,0.4,3.3,1.2c1.1,0.8,1.8,2,2.3,3.7c1.5-1,3.3-2,5.4-2.9c2.1-0.9,4.3-1.3,6.6-1.3
	c3.3,0,6.8,0.9,10.2,2.6c3.5,1.7,6.3,3.9,8.4,6.5c1-1,2.1-2.1,3.3-3.1c1.2-1.1,2.4-2,3.7-2.9c1.3-0.9,2.6-1.6,4-2.2
	c1.4-0.6,2.8-0.9,4.2-0.9c2.9,0,5.7,0.5,8.5,1.5c2.8,1,5.3,2.3,7.4,4c2.2,1.7,3.9,3.7,5.3,6c1.3,2.3,2,4.7,2,7.2
	c0,0.2,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0.1,0.3V474c-0.1,2.1-0.7,3.7-1.9,4.8
	c-1.2,1.1-2.4,1.6-3.8,1.6c-1.3,0-2.5-0.5-3.6-1.5s-1.8-2.5-2.1-4.5v-38.1c-1-3.2-2.6-5.5-4.7-6.6c-2.1-1.2-4.3-1.7-6.4-1.7
	c-2.4,0-4.6,0.6-6.5,1.8c-1.9,1.2-3.6,3.4-4.9,6.7v37.5c-0.1,2.1-0.7,3.7-1.9,4.8c-1.2,1.1-2.4,1.6-3.8,1.6c-1.3,0-2.5-0.5-3.6-1.5
	c-1.1-1-1.8-2.5-2.1-4.5v-34.7C600.6,439.3,600.7,439.1,600.8,439z M714.8,472.7c0,0.1,0,0.1-0.1,0.1c0.6,1.3,0.7,2.5,0.4,3.6
	c-0.3,1.1-0.8,2-1.6,2.7c-0.8,0.7-1.8,1.1-3.1,1.3c-1.3,0.2-2.6-0.1-4.1-0.8c-0.8-0.8-1.6-1.7-2.4-2.7c-0.7-1-1.4-2-1.9-3
	c-1.9,1.6-3.9,3-6,4.4c-2.1,1.3-4.3,2.1-6.4,2.2c-3.7,0.2-7.4,0.2-11,0.1c-3.6-0.1-7.1-1.3-10.3-3.6c-2.7-1.9-4.8-4.3-6.2-7
	c-1.4-2.7-2.4-5.5-2.8-8.6c-0.4-3-0.4-6.1,0.1-9.3c0.5-3.2,1.3-6.2,2.4-9c0.5-1.3,1.1-2.6,1.8-3.8c0.7-1.2,1.5-2.5,2.2-3.8
	c1-1.9,2.2-3.6,3.5-5.2c1.3-1.6,2.8-3,4.5-4.2c1.2-0.8,2.4-1.7,3.6-2.5c1.2-0.8,2.3-1.6,3.6-2.4c0.8-0.6,2.1-1.1,3.8-1.7
	c1.7-0.6,3.6-1.1,5.6-1.5c2-0.4,4-0.6,6-0.6c2,0,3.7,0.3,5.1,0.8c1.3-0.2,2.6,0.1,3.8,1c1.3,0.8,2.2,2.2,2.9,4
	c0,6.3,0,12.7-0.1,19.1c-0.1,6.4,0.3,12.8,1.3,19.1c0.1,0.8,0.4,1.8,1,3c0.6,1.2,1.3,2.3,1.9,3.4c0.7,1.1,1.3,2.1,1.9,3.1
	C714.5,471.8,714.8,472.4,714.8,472.7z M697.1,463.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c-1.2-5.6-1.8-11.3-1.7-17.3
	c0-6,0.1-11.5,0.2-16.5c-0.8,0-1.8,0.1-2.8,0.3c-1,0.2-2,0.4-3,0.8c-1,0.3-1.9,0.6-2.6,0.9c-0.8,0.3-1.4,0.5-1.7,0.7
	c-2.8,1.3-5.2,3-7.1,5.1c-1.9,2.1-3.6,4.4-4.8,7c-1.3,2.6-2.2,5.2-2.9,8.1c-0.7,2.8-1,5.7-1,8.6c0.2,0.7,0.4,1.5,0.7,2.2
	c0.3,0.7,0.5,1.5,0.7,2.2c0.5,0.5,1,1,1.5,1.5c0.5,0.6,1,1.1,1.6,1.5c0.8,0.4,1.7,0.7,2.5,1c0.8,0.3,1.7,0.6,2.5,0.8
	c1.6,0,3.1,0,4.6,0.1c1.5,0.1,3-0.1,4.5-0.6c1.4-0.9,2.8-1.9,4.1-2.8C694.5,465.3,695.8,464.2,697.1,463.1z M722.5,427.6
	c-1-1.1-1.5-2.3-1.5-3.6c0-1.3,0.5-2.6,1.6-3.8c1.1-1.3,2.7-1.9,4.8-1.9h7.8v-22c0.5-1.9,1.2-3.2,2.2-4.2c1-0.9,2.2-1.4,3.5-1.4
	c1.2,0,2.3,0.4,3.4,1.3c1.1,0.8,1.8,2.1,2.3,3.9v22.4h13.4c1.8,0.5,3.1,1.2,3.9,2.3c0.8,1.1,1.3,2.2,1.3,3.4c0,1.3-0.5,2.5-1.4,3.5
	c-0.9,1-2.3,1.8-4.2,2.2h-12.9c-0.1,7.1-0.1,14.5,0,22.1c0.1,7.6,0.1,14.9,0.1,22.1c0,2.1-0.6,3.7-1.9,4.8c-1.3,1.1-2.5,1.6-3.8,1.6
	c-1.3,0-2.5-0.5-3.6-1.5s-1.8-2.5-2.1-4.5c0-7.2,0-14.7-0.1-22.3c0-7.6-0.1-15-0.1-22.3h-8.2C725,429.4,723.5,428.7,722.5,427.6z
	 M776.4,427.6c-1-1.1-1.5-2.3-1.5-3.6c0-1.3,0.5-2.6,1.6-3.8c1.1-1.3,2.7-1.9,4.8-1.9h7.8v-22c0.5-1.9,1.2-3.2,2.2-4.2
	c1-0.9,2.2-1.4,3.5-1.4c1.2,0,2.3,0.4,3.4,1.3c1.1,0.8,1.8,2.1,2.3,3.9v22.4h13.4c1.8,0.5,3.1,1.2,3.9,2.3c0.8,1.1,1.3,2.2,1.3,3.4
	c0,1.3-0.5,2.5-1.4,3.5c-0.9,1-2.3,1.8-4.2,2.2h-12.9c-0.1,7.1-0.1,14.5,0,22.1c0.1,7.6,0.1,14.9,0.1,22.1c0,2.1-0.6,3.7-1.9,4.8
	c-1.3,1.1-2.5,1.6-3.8,1.6c-1.3,0-2.5-0.5-3.6-1.5c-1.1-1-1.8-2.5-2.1-4.5c0-7.2,0-14.7-0.1-22.3c0-7.6-0.1-15-0.1-22.3h-8.2
	C778.9,429.4,777.4,428.7,776.4,427.6z M870.9,462.4c1.9-0.7,3.6-0.8,5-0.3c1.4,0.6,2.4,1.4,3,2.6c0.6,1.2,0.7,2.6,0.3,4
	c-0.4,1.5-1.5,2.7-3.3,3.8c-3.1,1.8-6.4,3.4-9.9,4.9c-3.5,1.5-7.1,2.5-10.7,3.1c-3.6,0.6-7.2,0.5-10.7-0.1c-3.5-0.6-6.9-2-10.2-4.2
	c-3.9-2.7-6.9-6.3-8.9-10.9c-2-4.6-3.1-9.4-3.2-14.3c-0.1-5,0.8-9.7,2.7-14.3c1.9-4.6,4.9-8.3,9-11.1c4.3-2.9,8.9-5,13.9-6.5
	c5-1.4,10.1-1.6,15.2-0.5c2.5,0.6,4.8,1.7,6.8,3.5c2,1.8,3.7,3.9,4.9,6.3c1.3,2.4,1.9,4.9,2,7.5c0.1,2.6-0.6,5.1-1.9,7.4
	c-1.6,2.6-3.9,4.8-6.8,6.6c-3,1.8-6.2,3.3-9.7,4.6c-3.5,1.3-7,2.3-10.4,3.1c-3.5,0.8-6.5,1.6-9.1,2.4c-1.1,0.3-2.2,0.3-3.2,0.1
	c1.4,3,3.5,5.4,6.2,7.3c2.7,1.9,6.1,2.7,10.2,2.4c3.2-0.2,6.5-1.1,9.8-2.7C865.2,465.6,868.2,464,870.9,462.4z M843.7,432.8
	c-3.4,1.8-6,4.2-7.7,7.4s-2.5,6.6-2.5,10.1c0.6-0.5,1.3-0.8,2.1-1.1c1.7-0.5,3.4-1,5.1-1.5c1.7-0.5,3.5-1,5.2-1.5
	c1.1-0.3,2.6-0.7,4.5-1.3c1.9-0.6,3.9-1.2,5.9-1.9c2-0.7,3.9-1.6,5.6-2.6s2.8-2.1,3.3-3.3c0.2-3.1-0.6-5.2-2.2-6.3
	c-1.7-1.1-3.7-1.7-6-1.7c-2.3,0-4.7,0.4-7.2,1.2C847.4,431.3,845.3,432,843.7,432.8z M895.7,430.3c-1.4-0.7-2.4-1.6-3.1-2.6
	c-0.7-1-1-2-1-3.1c0-1.4,0.5-2.6,1.5-3.7c1-1.1,2.4-1.6,4.2-1.6c1.3,0,2.9,0.5,4.9,1.4c1.9,0.9,3.5,1.9,4.6,3.1
	c2.9-2.4,5.8-4.4,8.9-6c3.1-1.6,6.2-2.4,9.3-2.4c0.9,0,2.3,0.2,4,0.7c1.8,0.5,3.5,1.2,5.2,2.1c1.7,0.9,3.2,2.1,4.5,3.6
	c1.3,1.5,1.9,3.2,1.9,5.2c0,1.6-0.5,2.9-1.5,3.8c-1,1-2.3,1.5-3.8,1.5c-1,0-1.9-0.3-2.7-0.9c-0.8-0.6-1.6-1.3-2.4-1.9
	c-0.8-0.7-1.6-1.3-2.5-1.9c-0.9-0.6-1.9-0.8-3-0.8c-1.3,0-2.7,0.4-4.2,1.2c-1.5,0.8-3.1,1.7-4.6,2.9c-1.5,1.1-3,2.3-4.4,3.6
	c-1.4,1.3-2.6,2.5-3.6,3.5v37c-0.1,2.1-0.7,3.7-1.9,4.8c-1.2,1.1-2.4,1.6-3.8,1.6c-1.3,0-2.5-0.5-3.6-1.5c-1.1-1-1.8-2.5-2.1-4.5
	v-38c0-0.2,0-0.4-0.1-0.6c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.4,0.1-0.6v-4C896.3,430.5,895.9,430.4,895.7,430.3
	z M545.3,107.8C543.8,77,524,43.5,473,41.5c-1.3-0.1-16.3-1.1-32.8,5c-15.8,5.8-35.9,19.4-42.2,51c0,0,0,0.1,0,0.1
	c-0.1,0.8-3,20.6,8.3,31.3c3.7,3.6,8.5,5.7,14.3,6.3c-1,9.6-12.8,121.1-15.5,224h6.1c2.7-105,15-218.8,15.5-223.9c0.1,0,0.2,0,0.3,0
	c0.7,0,1.4-0.1,2.1-0.2c0.2,0,0.3,0,0.5-0.1c0.8-0.1,1.7-0.2,2.5-0.3c2.1,3.8,7.4,11.1,16.9,13.2c0,0,0,0,0,0
	c1.4,0.3,2.8,0.5,4.4,0.5c0,0,0.1,0,0.2,0c0.3,0,0.9,0.1,1.7,0.1c0.8,0,1.7,0,2.8-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0
	c0.4,0,0.9-0.1,1.3-0.2c0,0,0,0,0,0c3.4-0.5,7.7-1.7,11.6-4.5c0,0,0,0,0.1,0c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.3-0.2,0.4-0.3
	c0.2-0.1,0.3-0.2,0.5-0.4c0.2-0.2,0.5-0.4,0.7-0.7c0,0,0.1,0,0.1-0.1c0.5-0.5,1.1-1,1.6-1.6c3.1,3.5,9.1,8.3,18.9,8.6
	c0.2,0,0.4,0,0.6,0c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c0.7,0,1.4,0,2.2-0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.6,0,1.6-0.1,3-0.3
	c0,0,0.1,0,0.1,0c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.4-0.1,0.6-0.1c0.1,0,0.2-0.1,0.4-0.1c0.2-0.1,0.4-0.1,0.6-0.2c0.1,0,0.1,0,0.2-0.1
	c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0,0.1,0,0.2-0.1c0.3-0.1,0.7-0.3,1-0.4c0,0,0,0,0,0c2.2-1.1,4.7-2.7,7.1-5.3
	c2.6,28.8,11.2,127.2,13.5,217.7h6.1c-1.4-55.3-5.1-116-8.1-155.1c-1.7-22.8-3.3-38.8-4.4-50.8c-0.6-6.6-1-11.7-1.4-15.3
	c-0.1-0.8-0.1-1.4-0.2-2.1c4.7,1.6,11.3,2,17.4-2.8l0.1-0.1C544.4,126.5,545.7,115.7,545.3,107.8z M532.2,128.3l-0.2,0.1
	c-4.9,3.9-10,2.6-12.6,1.5c1.8-6.6,1.2-14.2,0.1-19.9c-0.3-1.6-1.9-2.7-3.6-2.4c-1.6,0.3-2.7,1.9-2.4,3.6c1,5.2,2.1,15-1.7,21.3
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c-1,1.8-2.1,3.3-3.2,4.5c0,0,0,0,0,0c-0.2,0.2-0.4,0.4-0.5,0.6c0,0,0,0-0.1,0.1
	c-0.2,0.2-0.3,0.3-0.5,0.5c0,0-0.1,0-0.1,0.1c-0.2,0.1-0.3,0.3-0.5,0.4c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.3,0.3-0.5,0.4
	c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0.1-0.1,0.1
	c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.1-0.4,0.2
	c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0.1
	c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2,0.1
	c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0c-0.9,0.1-1.4,0.1-1.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l-0.2,0l-0.2,0
	c-0.6,0.1-1.2,0.1-1.8,0.1c-0.1,0-0.3,0-0.4,0c-0.6,0-1.1,0-1.7,0c-0.1,0-0.2,0-0.3,0c-0.5,0-1-0.1-1.4-0.1c-0.1,0-0.2,0-0.3,0
	c-0.5-0.1-1-0.2-1.4-0.3c-0.1,0-0.2,0-0.3-0.1c-0.5-0.1-0.9-0.2-1.3-0.4c0,0,0,0-0.1,0c-0.4-0.1-0.8-0.3-1.2-0.4
	c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.2-0.8-0.3-1.1-0.5c-0.1,0-0.1-0.1-0.2-0.1c-0.3-0.2-0.6-0.3-0.9-0.5c-0.1,0-0.1-0.1-0.2-0.1
	c-0.3-0.2-0.6-0.4-0.9-0.6c-0.1,0-0.2-0.1-0.2-0.2c-0.3-0.2-0.6-0.4-0.8-0.6c0,0,0,0,0,0c-0.3-0.2-0.5-0.4-0.7-0.6
	c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.6c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.6-0.6c0,0,0,0-0.1-0.1
	c-0.2-0.2-0.4-0.4-0.5-0.6c0,0,0-0.1-0.1-0.1c-0.3-0.4-0.6-0.8-0.8-1.1c0,0,0,0,0,0c6.3-14,2.1-32.1,1.9-32.9
	c-0.4-1.6-2-2.6-3.7-2.2c-1.6,0.4-2.6,2-2.2,3.7c0,0.2,4.2,17.9-2,30l0,0c-6.2,10.2-18.3,9.1-18.8,9.1c-0.1,0-0.2,0-0.2,0
	c-0.5,0-1-0.1-1.5-0.1c-0.1,0-0.2,0-0.3,0c-0.5-0.1-1-0.1-1.4-0.2c-0.1,0-0.1,0-0.2,0c-0.4-0.1-0.8-0.2-1.2-0.3
	c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.1-0.8-0.3-1.2-0.4c-0.1,0-0.2-0.1-0.2-0.1c-0.4-0.1-0.7-0.3-1-0.5c-0.1,0-0.1-0.1-0.2-0.1
	c-0.4-0.2-0.7-0.4-1.1-0.6c-0.1,0-0.2-0.1-0.2-0.1c-0.3-0.2-0.6-0.4-0.9-0.6c0,0-0.1,0-0.1-0.1c-0.3-0.2-0.6-0.5-0.9-0.7
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.3-0.2-0.6-0.5-0.8-0.7c0,0,0,0-0.1-0.1c-0.3-0.2-0.5-0.5-0.7-0.7c-0.1-0.1-0.1-0.1-0.2-0.2
	c-0.2-0.2-0.5-0.5-0.7-0.7c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.4-0.5-0.7c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.4-0.5-0.5-0.7
	c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.3-0.4-0.4-0.6c0,0,0-0.1-0.1-0.1c-0.1-0.2-0.3-0.4-0.4-0.6c0,0-0.1-0.1-0.1-0.1
	c-0.1-0.2-0.2-0.4-0.3-0.6c0,0,0,0,0,0c0,0,0-0.1,0-0.1c-6.7-17.4,1.9-34.2,2-34.5c0.8-1.5,0.2-3.3-1.3-4.1
	c-1.5-0.8-3.3-0.2-4.1,1.3c-0.4,0.7-8.4,16.4-3.9,34.5c-0.5,0.1-1.1,0.1-1.6,0.2c-0.3,0-0.5,0-0.8,0.1c-0.4,0-0.8,0.1-1.3,0.1
	c-0.3,0-0.5,0-0.8,0c-0.6,0-1.1,0-1.7,0c-5.7-0.1-10.3-1.7-13.5-4.8c-8.7-8.3-6.6-24.9-6.5-26c4.6-23,17.5-38.6,38.2-46.3
	c15.7-5.8,30.3-4.7,30.5-4.7c0,0,0.1,0,0.1,0c23.7,0.9,41.5,8.8,53.1,23.4c11.2,14.1,13.1,30.6,13.4,37.1
	C539.6,114.6,538.6,123.3,532.2,128.3z M242.9,68.7c-3.4-8.2-9.1-13.3-14.3-13.3c-0.7,0-1.5,0.1-2.2,0.3c-5.8,1.7-8.1,9.5-5.8,19.5
	c1.2,5.4,4,9.6,7.9,11.9c1.9,1.1,3.8,1.6,5.8,1.6c1,0,1.9-0.1,2.8-0.4c2.8-0.8,5.1-2.8,6.4-5.5C245.4,79,245.2,74,242.9,68.7z
	 M238,80.2c-0.4,0.8-1.2,1.9-2.6,2.3c-0.4,0.1-0.7,0.2-1.1,0.2c-0.9,0-1.8-0.3-2.7-0.8c-2.4-1.4-4.2-4.2-5-7.9
	c-1.6-7.3-0.1-11.9,1.6-12.4c0.1,0,0.3-0.1,0.5-0.1c1.8,0,5.7,2.6,8.7,9.5C238.9,74.6,239.1,78,238,80.2z M266.6,43
	c-1.8-10.3-7.4-17.3-13.6-17.3c-0.2,0-0.5,0-0.7,0c-6.5,0.5-10.8,8.3-10.8,19.4c0,6,1.9,11,5.5,14.2c2.3,2.1,5.1,3.2,7.9,3.2h0
	c0.3,0,0.6,0,0.9,0c3.2-0.3,6-1.9,8.1-4.5C266.7,54.3,267.7,49,266.6,43z M259.1,54.3c-1,1.3-2.3,2-3.8,2.2c-0.1,0-0.3,0-0.4,0
	c-1.3,0-2.7-0.6-3.8-1.6c-2.2-2-3.5-5.5-3.5-9.7c0-8,2.7-13.1,5.3-13.3c0.1,0,0.1,0,0.2,0c2.2,0,6.1,3.9,7.6,12.3
	C261.4,48.3,260.8,52,259.1,54.3z M316.1,55.7c-0.7-0.2-1.4-0.3-2.2-0.3c-5.2,0-10.8,5.1-14.3,13.3c-2.2,5.3-2.4,10.3-0.6,14.2
	c1.3,2.7,3.6,4.7,6.4,5.5c0.9,0.3,1.9,0.4,2.8,0.4c1.9,0,3.9-0.6,5.8-1.6c3.9-2.2,6.7-6.5,7.9-11.9
	C324.2,65.3,321.9,57.4,316.1,55.7z M316.1,74c-0.8,3.7-2.6,6.6-5,7.9c-0.9,0.5-1.9,0.8-2.7,0.8c-0.4,0-0.8-0.1-1.1-0.2
	c-1.4-0.4-2.2-1.5-2.6-2.3c-1.1-2.2-0.8-5.6,0.7-9.2c2.9-7,6.9-9.5,8.7-9.5c0.2,0,0.3,0,0.5,0.1C316.2,62.1,317.7,66.7,316.1,74z
	 M290.3,25.7c-0.2,0-0.5,0-0.7,0c-6.2,0-11.7,7-13.6,17.3c-1.1,6-0.1,11.4,2.8,15c2,2.6,4.9,4.2,8.1,4.5c0.3,0,0.6,0,0.9,0
	c2.8,0,5.6-1.1,7.9-3.2c3.5-3.2,5.5-8.3,5.5-14.2C301.1,34,296.7,26.3,290.3,25.7z M291.5,54.8c-1.2,1.1-2.5,1.6-3.8,1.6
	c-0.1,0-0.3,0-0.4,0c-1.5-0.1-2.8-0.9-3.8-2.2c-1.8-2.3-2.3-6-1.6-10.2c1.5-8.4,5.4-12.3,7.6-12.3c0.1,0,0.1,0,0.2,0
	c2.5,0.2,5.3,5.4,5.3,13.3C295,49.3,293.8,52.8,291.5,54.8z M295.8,91.4c-7.7-5.6-10.8-14.1-10.8-14.2l0,0
	c-4.2-10.2-11.7-10.8-13.5-10.8c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-1.8,0-9.3,0.6-13.5,10.8c0,0.1-3.7,9.2-10.8,14.2
	c-5.8,4-10.8,11.7-7.7,21.1c0.1,0.3,1.1,3.5,4,6.3c3.1,2.9,6.7,3.8,10.2,3.8c2.2,0,4.3-0.4,6.1-0.9l0,0c0,0,4.6-1.4,8.6-2.4
	c1.1-0.3,2.2-0.4,3.3-0.4c1.1,0,2.2,0.1,3.3,0.4c4,1,8.5,2.4,8.6,2.4l0,0c2.3,0.6,4.3,0.9,6.1,0.9c0,0,0,0,0,0
	c4.9,0,8.2-1.9,10.2-3.8c2.9-2.7,3.9-6,4-6.3C307.3,100.9,298.5,93.4,295.8,91.4z M297.7,110.5c0,0.1,0,0.1-0.1,0.2
	c0,0-0.6,2-2.3,3.6c-1.5,1.4-3.6,2.2-6,2.2c-1.3,0-2.8-0.2-4.4-0.6c-0.8-0.2-5-1.5-8.8-2.5c-1.6-0.4-3.2-0.6-4.8-0.6
	c-1.6,0-3.2,0.2-4.8,0.6c-3.8,0.9-8,2.2-8.8,2.5c-1.6,0.4-3.1,0.6-4.4,0.6c-2.5,0-4.5-0.7-6-2.2c-1.7-1.6-2.3-3.6-2.3-3.6
	c0-0.1,0-0.1-0.1-0.2c-2.6-7.8,3.5-12.9,5.5-14.2c8.3-5.9,12.6-16,12.9-16.9c2.8-6.7,7.1-7.1,7.9-7.1c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0h0c0.8,0,5.1,0.3,7.8,7c0.5,1.4,4.2,10.6,12.9,16.9c0.9,0.6,3.1,2.4,4.6,5.1C298.5,104.3,298.8,107.3,297.7,110.5z"
	/>
    </g>
  </svg>
);

export const Pill = ({ height, fill1, fill2 }) => (
	<svg
		viewBox="0 0 366.9 137.6"
	  preserveAspectRatio="xMidYMid meet"
	  focusable="false"
	  height={height}
	>
	  <g>
	  <path fill={fill1} d="M366.9,68.8c0-18.9-7.6-36-19.9-48.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-9.7-9.6-22.3-16.3-36.4-18.8
	c-3.9-0.7-7.9-1.1-12.1-1.1L68.8,0C30.8,0,0,30.8,0,68.8c0,19,7.7,36.2,20.2,48.7s29.7,20.2,48.7,20.2h114.7h114.7
	C336.1,137.6,366.9,106.8,366.9,68.8z"/>
	<path fill={fill2} class="st0" d="M68.8,121.6c-14.1,0-27.3-5.5-37.3-15.5S16,82.9,16,68.8C16,39.7,39.7,16,68.8,16l114.7,0l0,105.5L68.8,121.6z"
	/>
	  </g>
	</svg>
  );

