import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Bitcoin2Icon } from "../components/Icons";

// components
import BitcoinVideo from "../components/BitcoinVideo";
import NoResults from "../components/NoResults";
import Skeleton from "../skeletons/ChannelSkeleton";

// reducers and utils
import { addChannel, removeChannel } from "../reducers/user";
import {
  getProfile,
  clearProfile,
  subscribeFromProfile,
  unsubscribeFromProfile,
} from "../reducers/profile";
import { client, addChannelLocalSt, removeChannelLocalSt } from "../utils";


const Wrapper = styled.div`
  // background: ${(props) => props.theme.bg};
  min-height: 100vh;
  padding-bottom: 7rem;
  padding-top: 1rem;

  .cover {
    height: 170px;
  }

  .cover img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .header-tabs {
    padding: 1.2rem 0rem;
    background: ${(props) => props.theme.bg};
  }

  .header {
    width: 80%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
  }

  .tabs,
  .tab {
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 80%;
  }

  ul {
    list-style: none;
    display: flex;
    align-items: center;
  }

  li {
    margin-right: 2rem;
    text-transform: uppercase;
    letter-spacing: 1.1px;
  }

  li:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 860px) {
    .header,
    .tabs,
    .tab {
      width: 90%;
    }
  }

  @media screen and (max-width: 470px) {
    .header,
    .tabs {
      width: 90%;
    }
  }

`;

const Channel = () => {
  const { userId } = useParams();

  const dispatch = useDispatch();
  const { id: loggedInUserId } = useSelector((state) => state.user.data);
  const { isFetching, data: profile } = useSelector((state) => state.profile);
  const profileId = userId || loggedInUserId;

  useEffect(() => {
    dispatch(getProfile(profileId));

    return () => {
      dispatch(clearProfile());
    };
  }, [dispatch, profileId]);

  if (!isFetching && !profile) {
    return (
      <NoResults
        title="Page not found"
        text="The page you are looking for is not found or it may have been removed"
      />
    );
  }

  if (isFetching) {
    return <Skeleton />;
  }

  return (
    <Wrapper >
      <div className="header-tabs">
        <div className="header">
          <div className="flex-column">
            <div>
            <Bitcoin2Icon fill1="#ef853b" fill2="#fff" fill3="#ef853b" height="40" /><h2>Bitcoin Income</h2>
            </div>
            <h3>{profile.username}</h3>
          </div>
        </div>
      </div>

      <div className="tab">
        <BitcoinVideo />
      </div>
      
    </Wrapper>
  );
};

export default Channel;
