import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Grid, Row, Col } from 'react-flexbox-grid'
import Title from "./TitleCenterDark";
import Subtitle from "./SubtitleDark";
import Description from "./DescriptionDark";
import { Paw } from "../components/Icons";

const Wrapper = styled.div`
    padding: 10% 0;
    background: ${(props) => props.theme.primaryColor};
    text-align: center;
    position: relative;
    overflow: hidden;
`


function NoAds() {
  return (
    <Wrapper >
        <Grid>
                <Row center="xs">
                    <Col xs={12} md={6} >
                        <Subtitle>Enjoy really funny videos</Subtitle>
                        <Title>No ads ever!</Title>
                        <Description>We feature premium content without the circus show ads you see on other sites.
                        The app features a pet lover feed and a popular video section.</Description>
                    </Col>
                </Row>
        </Grid>
    </Wrapper>
  );
}

export default NoAds;